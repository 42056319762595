<template lang="es">
    <div :style="myEstilo">
    <!-- <div> -->
        <!-- <p>{{elemento.angle}}</p> -->
        <!-- {{elemento}} -->
       <slot></slot>
    </div>
</template>
<script>
export default {
    props:{
        elemento:Object
    },
    data() {
        return {
            position:'absolute'
        }
    },
    computed: {
        width(){
            let ancho=100*this.elemento.w/this.elemento.component_props.customizables.initial_screen_size.width
            return `${ancho}%`
        },
        height(){
            let alto=100*this.elemento.h/this.elemento.component_props.customizables.initial_screen_size.height
            return `${alto}%`
        },
        top(){
            let tp = 100*(this.elemento.y-this.elemento.h/2)/this.elemento.component_props.customizables.initial_screen_size.height;
            return `${tp}%`
        },
        left(){
            let lf = 100*(this.elemento.x-this.elemento.w/2)/this.elemento.component_props.customizables.initial_screen_size.width
            return `${lf}%`
        },
        grados(){
            return parseFloat(this.elemento.angle)
        },
        myEstilo(){
            return {
                width: this.width,
                height:this.height,
                transform:`rotate(${this.grados}deg)`,
                position:this.position,
                top:this.top,
                left:this.left,
                zIndex: this.elemento.z

            }
        },
        estilo(){
            let item = this.elemento
            item.porcentaje_x = 100*(item.x-item.w/2)/item.component_props.customizables.initial_screen_size.width
          item.porcentaje_y = 100*(item.y-item.h/2)/item.component_props.customizables.initial_screen_size.height
          item.porcentaje_ancho = item.w/item.component_props.customizables.initial_screen_size.width
          item.porcentaje_alto= item.h/item.component_props.customizables.initial_screen_size.height
          item.style_width=item.porcentaje_ancho
          return "{left: '500px'}"
        
        //    return JSON.stringify({
        //     'width': item.porcentaje_ancho+'%',
        //     'height': item.porcentaje_alto+'%', 
        //     'position': 'absolute', 
        //     'top':item.porcentaje_y+'%',
        //     'left':item.porcentaje_x+'%'})
        }
    },
}
</script>
<style lang="">
    
</style>
<template lang="">
  <div>
     <!-- <h4 class="text-center mt-3">Variable:</h4> -->
     <v-text-field
        prefix="Dirección:"
        class="ml-3 mr-3"
        v-model="variable"
        v-on:focusout="readData"
        :label="component_selected.component_props.customizables.variable? component_selected.component_props.customizables.variable : 'Ej.: D2500...'"
        single-line
        @keyup.enter="readData"
        @click="changeEditing(true)"
        @blur="changeEditing(false)"
      />
      <koiote-decimal-select></koiote-decimal-select>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: "OmronVariableSelection",
  data() {
    return {
      variable: null,
    };
  },
  components:{
    KoioteDecimalSelect: () => import('@/components/custom/KoioteDecimalSelect.vue'),
  },
  computed:{  
    ...mapState(['component_selected','component_selected_original','editing']),
    ...mapGetters("nodered", ["getConnections", "existsInNodered", "getTopic"]),

  },
  methods: {
    ...mapActions("nodered", ["changeTopic"]),
    ...mapActions(['addModifiedDraggable','changeEditing']),
    readData() {
      // al cambiar la variable cambiara el topic del draggable
      //if variable and plc are not null read
      if (this.variable != null && this.component_selected.component_props.customizables.plc != undefined) {
        this.component_selected.component_props.customizables.variable = this.variable;
        let variable = this.$splitVariable(this.variable)
        this.changeTopic({ plc: this.component_selected.component_props.customizables.plc, variable: variable.numbers });
        this.component_selected.component_props.customizables.topic = this.getTopic;
      }
      else {
        this.component_selected.component_props.customizables.variable = "";
        this.component_selected.component_props.customizables.topic = "";
        console.log("no se lee nada");
      }
      // this.changeEditing(false)
    }
  },
  created(){
    if(this.component_selected.component_props.customizables.variable != "")
        this.variable = this.component_selected.component_props.customizables.variable
  },
  watch: {
    component_selected() {
        if ( this.component_selected.component_props.customizables.plc != undefined && this.component_selected.component_props.customizables.variable != null) {
          this.variable = this.component_selected.component_props.customizables.variable;
        }
        else {
          this.variable = null;
        }
    },
    'component_selected.component_props.customizables.plc':function(){
      if(this.component_selected.component_props.customizables.plc != this.component_selected_original.component_props.customizables.plc){
        let plcType = this.getConnections.find(e => e.id == this.component_selected.component_props.customizables.plc).type
        if(plcType != null && plcType == "FINS Connection"){
          this.readData()
          console.log("Ha cambiado el plcccccccccccccccccc");
        }
      }
    },
    // variable() {
    //   console.log("La variable: "+ this.variable);
    //   if (this.variable != this.component_selected.component_props.customizables.variable) {
        
    //   }
    // }
  }
}
</script>
<style lang="">
  
</style>
<template lang="es">
  <div>
    <div class="ml-3">
      Valor actual: {{component_selected.component_props.customizables.value}}
    </div>
 
    <v-text-field
      prefix="Valor Máximo:"
      class="ml-3 mr-3"
      v-model.number="component_selected.component_props.customizables.valorMaximo"
      single-line
      type="number"
      @click="changeEditing(true)"
      @blur="changeEditing(false)"
      @keypress="isNumber($event)"
      :min="component_selected.component_props.customizables.value ? component_selected.component_props.customizables.value:0"
      />
    
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  name:"KoioteTankCanvasProperties",
  components:{
      
  },
  computed:{
    ...mapState(['component_selected']),
  },
  methods: {
    ...mapActions(['changeEditing']),
    isNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
        if(keyCode !== 46){
          $event.preventDefault();
          return false
        }
      }
    },
  },
  data() {
      return {
       
      }
  },
 
}

</script>
<style lang="">

</style>
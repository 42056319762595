import KoioteTank from './KoioteTank.vue'
import KoioteTankProperties from './KoioteTankProperties.vue'
import KoioteTankProperty from './default.json'

export default {
    install(Vue,options) {
        Vue.component(KoioteTank.name, KoioteTank)
        Vue.component(KoioteTankProperties.name, KoioteTankProperties)
        Vue.prototype.$TankProperties = KoioteTankProperty


        Vue.mixin({
            mounted() {
            
            }
          });
       }
  };
  

<template lang="">
    <div>
    <v-navigation-drawer app>
      <v-list-item>
          <v-list-item-content>
            <!-- PAGINAS DEL SCADA -->
            <v-row>
                <v-col>
                    <v-list-item-title class="text-h6"> Páginas
                    </v-list-item-title>
                </v-col>
                <!-- AÑADIR PAGINA -->
                <v-col class='text-center'>
                    <v-btn fab text x-small @click="createPage">
                        <v-icon>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
                     <!--    LISTA DE PAGINAS -->
    <v-list-item :style="`height: ${full_height*0.4}px; overflow-y: scroll;`">
        <created-group-pages :pages_list="pages"/>
    </v-list-item>
    <v-divider></v-divider>
            <!-- HERRAMIENTAS PARA AÑADIR A LA PAGINA -->
    <v-list-item>
        <v-list-item-content>
            <v-list-item-title class="text-h6"> Herramientas </v-list-item-title>
            <!-- <v-list-item-subtitle> Navigation drawers</v-list-item-subtitle> -->
        </v-list-item-content>
    </v-list-item>
    <v-divider class="mb-2"></v-divider>
    <!-- LISTA DE HERRAMIENTAS -->
        <div :style="`height: ${full_height*0.4}px; overflow-y: scroll;`">
        <v-list-item id="tools_list_group" :style="`display:inline-block;`">
            <!-- <v-navigation-drawer permanent disable-resize-watcher :style="'position: absolute;'"> -->
                <!-- <v-list-item-group id="tools_list_group"> -->
                    <!-- <v-list-item  v-for="(item, index) in tools" :key="index"> -->
                        <v-tooltip right close-on-content-click v-for="(item, index) in tools" :key="index" >
                            <template  v-slot:activator="{ on, attrs }">
                                <v-btn class="mb-3"
                                    :outlined="outlined_item(item)"
                                    @click="crear(item)"
                                    v-bind="attrs"
                                    v-on="on">
                                    <img class="tool" :src="require(`@/${item.root}/${item.toolbar.path}`)"/>
                                </v-btn>
                            </template>
                            <span>{{item.toolbar.name}}</span>
                        </v-tooltip>
                        
                <!-- </v-list-item> -->
            <!-- </v-list-item-group> -->
        <!-- </v-navigation-drawer> -->
    </v-list-item>
</div>
        <v-divider></v-divider>


    </v-navigation-drawer>
    </div>


</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import CreatedGroupPages from '@/components/pages/CreatedGroupPages.vue'
export default {
    data() {
        return {
            width: 0,
            height: 0,
            top: 0,
            left: 0,
            selectedIndex:null

        }
    },

    props:{
        tools:Array
    },
    components:{
        CreatedGroupPages

    },

    computed: {
        ...mapState(['component_selected','tool', 'full_height', 'pages']),
        ...mapGetters(['getSelectedPage','getIsPlayMode'])
    },


    methods:{
        ...mapActions(['setTool','addPage']),

        resize(newRect) {
            this.width = newRect.width;
            this.height = newRect.height;
            this.top = newRect.top;
            this.left = newRect.left;
        },
        crear(item){
            // console.log(item)
            const objeto = {...item}
            // console.log("EL OBJETO");
            // console.log(objeto)
            this.setTool(objeto)
        },

        //chequea si la herramienta elegida es
        outlined_item(item){
            if(Object.keys(this.tool).length != 0 && Object.keys(this.getSelectedPage).length != 0){
                return (item.toolbar.name==this.tool.toolbar.name)
            }else return false;

        },
        async createPage(){
            console.log("NUEVA PAGINA")
            let newPageName = `Página ${this.pages.length + 1}`
            let newPage = {
                // 'id':this.pages[0] ? this.pages[this.pages.length-1].id + 1 : 0,
                'image':null,
                'name':newPageName,
                'is_main_page':false,
                'z_index':10
            }
            this.addPage(newPage)
            try{
                //await this.postPage(newPage)               // falta todo lo del back
                console.log("Pagina creada")
            }catch(e){
                console.log(e);
            }

        }
    }

}
</script>
<style>
    .tool{
        height: 32px;
        width: 32px;
        display: block;
        resize: both;
    }
    #tools_list_group {
    column-count: 2;
    vertical-align: top;
    }
</style>
<template lang="es">
     <div :class="myProps.customizables.connected? 'conectado completo':'desconectado completo'">
        <p>
            {{myProps.component_name}}
            <!-- {{myProps.customizables.connectionID}} -->
        </p>
        <p>
           <v-icon style="vertical-align: middle" v-if="myProps.customizables.connected" large>mdi-access-point</v-icon>
           <v-icon style="vertical-align: middle" v-else large>mdi-access-point-off</v-icon>
        </p>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: "KoioteIbhLinkConnection",
    props: {
        myProps: Object
    },
    computed: {
        ...mapState(['list_components']),
        ...mapState('nodered', ['flows'])
    },
    created() {
        if(this.myProps.customizables.connected){
            this.doSubscribe('HornoEncendido')
        }
        if (typeof this.myProps.customizables.connectionID != "number") {
            this.$emit('anadirConexion',this.myProps)
            console.log("NO TENGO ID");
        }
        if (!this.getConnections.find(e => this.myProps.customizables.connectionID == e.id)) {
            this.addConnection({
                id: this.myProps.customizables.connectionID,   
                ip: this.myProps.customizables.ip,
                variables: [{name:'Estado', topic: 'HornoEncendido'}, // si el horno esta encendido sera un 1 si no un 0
                            {name:'Programa', topic:'HornoPrograma'}, // un objeto con el numero de programa y un array de los puntos del grafico
                            {name:'Temperaturas', topic:'HornoTemperatura'}], // la temperatura del horno cada segundo si esta encendido
                name: this.myProps.component_name,
                type: "IBHLink Connection"
            })
        }
    },
    methods: {
        ...mapActions('nodered', ['addConnection','doSubscribe']), 
    }

}
</script>
<style>
.completo {
    height: 100%;

}

.desconectado {
    text-align: center;
    border: dotted 2px black;
}

.conectado {
    text-align: center;
    border: dotted 2px black;
    background-color: #4CAF5050;
}
</style>
<template>
  <v-app>
    <!-- <navigation-bar /> -->
    <v-main>
       <!-- Menú superior -->
      <navigation-bar></navigation-bar>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import NavigationBar from "@/components/NavigationBar.vue";
import { ScatterController } from 'chart.js';
export default {
  name: "App",
  components: {
    NavigationBar,
  },
  methods:{
    ...mapActions(['setIsAdmin','setIsPlayMode','setRol']),
    ...mapActions("nodered", ["createConnection"]),

  },
  mounted(){
    if(JSON.parse(localStorage.getItem("user"))){

      this.setIsAdmin(JSON.parse(localStorage.getItem("user")).role_name == 'admin')

      //Guardamos el rol
      if(JSON.parse(localStorage.getItem("user")).role_name == 'admin') this.setRol(1)
      if(JSON.parse(localStorage.getItem("user")).role_name == 'avanzado') this.setRol(2)
      if(JSON.parse(localStorage.getItem("user")).role_name == 'normal') this.setRol(3)

      if (!this.getIsAdmin) {
        this.setIsPlayMode(true);
      }else{
        this.setIsPlayMode(false)
      }
    }
  },
  async created() {
    await this.createConnection()
    
  },
  computed:{
    ...mapGetters(['getIsAdmin'])
  }
};
</script>

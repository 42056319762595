<template lang="es">
   <!-- <div>  -->
<!-- {{myProps.customizables.value}} -->
    <canvas :style="style"
        @mouseover="getIsPlayMode?hoverFunction('1'):null" 
        @mouseleave="getIsPlayMode?hoverFunction('0'):null" 
        @click="getIsPlayMode && myProps.customizables.navigate?changePage():null"
    v-if="myProps.customizables.images.length > 0" id="myCanvas"  ref="myCanvas"></canvas>
  <!-- </div> -->
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "KoioteImageSwitchHover",
  props: {
    myProps: Object,
  },
  computed: {
    ...mapGetters(["getIsPlayMode",'getPages']),
  },
  data() {
    return {
      terminado_de_cargar: false,
      cantidad_de_imagenes_cargadas: 0,
      images: [],
      style:{}
    }
  },
 
  mounted(){
    this.comprobarDraw();
  },
  methods: {
    ...mapActions(['setSelectedPage']),

    hoverFunction(v){
      v == '1'?this.style = {cursor:"pointer"}:this.style = {cursor:"default"}
      this.$emit('cambioValor', {id: this.myProps.id_draggable, value: v})
    },
    changePage(){
      if(this.myProps.customizables.next_page){
        let page = this.getPages.find(e => e.id == this.myProps.customizables.next_page)
        if(page)
            this.setSelectedPage(page)
        }
    },
    loadImages() {
      //console.log(`Cargando imagen ${this.cantidad_de_imagenes_cargadas}...`)
      this.terminado_de_cargar = false;
      let imageLoader = new Image();
      imageLoader.onload = () => {
        let seted_value = this.myProps.customizables.setted_values[this.cantidad_de_imagenes_cargadas]
        const dato = {
          index_objeto:this.myProps.id_draggable,
          index: this.cantidad_de_imagenes_cargadas,
          imagen: imageLoader,
          width: imageLoader.naturalWidth,
          height: imageLoader.naturalHeight,
          seted_value: parseInt(seted_value),
          los_seted_values:this.myProps.customizables.setted_values
          
        }
        this.images.push ( dato)
        this.terminado_de_cargar = true;
      }

      imageLoader.src = this.myProps.customizables.images[this.cantidad_de_imagenes_cargadas];

    },

    modifyImage(index){
      let imageLoader = new Image();
      imageLoader.onload = () => {
        let seted_value = this.myProps.customizables.setted_values[index]
        const dato = {
          index_objeto:this.myProps.id_draggable,
          index: index,
          imagen: imageLoader,
          width: imageLoader.naturalWidth,
          height: imageLoader.naturalHeight,
          seted_value: parseInt(seted_value),
          los_seted_values:this.myProps.customizables.setted_values
        }

        this.images[index] = dato
        let current_image = this.images.find(a=>a.seted_value==this.myProps.customizables.value)
        this.draw(current_image)

      }

      imageLoader.src = this.myProps.customizables.images[index];
    },
    loadAndDraw(index){
      let imageLoader = new Image();
      imageLoader.onload = () => {
        let seted_value = this.myProps.customizables.setted_values[index]
        const dato = {
          index_objeto:this.myProps.id_draggable,
          index: this.cantidad_de_imagenes_cargadas,
          imagen: imageLoader,
          width: imageLoader.naturalWidth,
          height: imageLoader.naturalHeight,
          seted_value: parseInt(seted_value),
          los_seted_values:this.myProps.customizables.setted_values
        }
        this.images.push ( dato)
        this.terminado_de_cargar = true;
        let current_image = this.images.find(a=>a.seted_value==this.myProps.customizables.value)
        this.draw(current_image)

      }

      imageLoader.src = this.myProps.customizables.images[index];

    },
    draw(current_image){
      const canvas = this.$refs.myCanvas;
      if(canvas){
        try {
          const ctx = canvas.getContext("2d");
          canvas.width = current_image.width
          canvas.height = current_image.height
          let imageLoader = current_image.imagen;
          ctx.drawImage(imageLoader, 0, 0, canvas.width, canvas.height);
          if(this.myProps.customizables.border){

            const borderWidth = 8; // Change this value to adjust the border width
            const borderColor = this.myProps.customizables.border_color // Change this value to adjust the border color
            
            ctx.strokeStyle = borderColor;
            ctx.lineWidth = borderWidth;
            ctx.strokeRect(0, 0, canvas.width, canvas.height);
          }
          
        } catch (error) {
          let ob= this.images.filter(a=>a.index_objeto==this.myProps.id_draggable)
          console.log("El objeto roto es")
          console.log(ob)
        }
      }
    },
    comprobarDraw(){
      if(this.myProps.customizables.value === undefined)return 
      
        //comprobar si la imagen está cargada
        let imagen_localizada = this.images.filter(a=>a.seted_value==this.myProps.customizables.value)
      if(imagen_localizada.length==0){
        //La imagen no está cargada.
        //Obtener la imagen a cargar
        let index = this.myProps.customizables.setted_values.indexOf(this.myProps.customizables.value.toString())
        if(index<0) return
        this.loadAndDraw(index)
      }
      else{
        this.draw(imagen_localizada[0])
      }
      
    },
  },

  watch: {
    'myProps.customizables.border':function(){
      if(this.myProps.customizables.images.length > 0){
        this.comprobarDraw()
      } 
      
    },
    'myProps.customizables.value':function(){
      if(this.myProps.customizables.images.length > 0){
        this.comprobarDraw();
      }
    },
    'myProps.customizables.images':function(){
      if(this.myProps.customizables.images.length > 0){
        // if(this.images.length > 0){
        //   if(this.images.length == this.myProps.customizables.images.length){
        //     //miramos de una en una pa ver cual es distinta
        //     console.log("Son iguales de tamaño");
        //     for (let index = 0; index < this.myProps.customizables.images.length; index++) {
        //       if (this.myProps.customizables.images[index] != this.images[index])
        //         this.modifyImage(index)
        //     }
        //   }else if(this.myProps.customizables.images.length > this.images.length){
            // console.log("this.images es mas txikito que las props");
        //     console.log((this.myProps.customizables.images.length));
        //     console.log((this.images.length));
        //     console.log(this.images);
        //     //cargar laultima de myprops
        //   }else{
        //     console.log("Se ha borrado una imagen???");
        //   }
        // }

        //cargamos las imagenes con la actualizacion del array
        this.images = []
        this.comprobarDraw();
      }
    },
  }
};
</script>
<style>
.draggable-resize-rotate #parentContainer {
  width: 100%;
  height: 100%;
  position: relative;

}
.canvasBorder{
  border: 2px solid red;
}
#myCanvas {
  width: 100%;
  height: 100%;
  position: relative;
}

.fullimage {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: none;
  /* Ensures the image fills the container */

}

.round {
  border-radius: 50%;

}</style>
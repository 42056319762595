<template lang="es">
    <div>
        <v-text-field ref="input_editable" v-if="editing" 
        v-model="nuevoValor"
        @blur="changeEditing(false)"
        @keyup.enter="guardar"
        :suffix="sufijo"
        ></v-text-field>
        
        <span v-else @dblclick="hacer_editable(true)">{{valor_a_mostrar}}{{sufijo}}</span>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
    props:{
        valor:Number,
        propiedad:String,
        sufijo:{
            type:String,
            default:''
        }
    },

    data() {
        return {
            nuevoValor:null,
            //editing:false
        }
    },

    created() {
        this.nuevoValor=this.valor;
    },
    watch:{
        valor(){
            this.nuevoValor=this.valor;
        }
    },
    computed: {
        ...mapState(['editing']),
        valor_a_mostrar(){
            return this.nuevoValor==''?'SIN DATOS':this.nuevoValor;
        }
    },

    methods: {
        ...mapActions(['changeEditing']),
        hacer_editable(e){
            this.changeEditing(e)
            // this.editing=e;
            this.$nextTick(()=>{
                this.$refs.input_editable.focus();
            });
            
        },
        guardar(){
            const payload={'propiedad':this.propiedad,'valor':this.nuevoValor}
            this.$emit('cambio', payload)
            this.changeEditing(false)
            // this.editing=false;

        },
    },//fin de los métodos
}
</script>
<style lang="es">
    
</style>
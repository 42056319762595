import mqtt from "mqtt/dist/mqtt";

const state = () => ({
  flows:[
  ],
  connection: {  //  conexion mqtt
    protocol: process.env.VUE_APP_MQTT_PROTOCOL,
    host: process.env.VUE_APP_BACK_HOST,
    port: process.env.VUE_APP_MQTT_PORT,
    clean: true,
    connectTimeout: 30 * 1000, // ms
    reconnectPeriod: 4000, // ms
    username: null,
    password: null,
  },
  topic:"",
  subscriptions: [],
  ListOfmessages:[],

  // message:{
  //   topic:null,
  //   message:null
  // },
  client: {
    connected: false,
  },
  connections:[] // array de plcs
})
const getters = {
  getConnections(state){
    return state.connections
  },
  getNewNodeId(state){
    
    if(state.flows.length > 2){
      // state.flows.forEach(e => console.log(e.id +" es "+typeof parseInt(e.id)))
      let nodes = state.flows.filter(e => e.type != "tab" && e.type != "mqtt-broker" && /^\d+$/.test(e.id))
      console.log("Los nodos del flows: ", nodes);
      if(nodes.length > 0){
        let id = (Math.max(...nodes.map(e => parseInt(e.id)))+1)
        console.log("EL NUEVO ID:" +(Math.max(...nodes.map(e => parseInt(e.id)))))
        return id!= null && !isNaN(id)? id.toString():'2' 
      }else return "2"
    }
    else  return "2"
  },
  getTopic(state){
    return state.topic
  },
  getNewNodeY:(state) => (data) => {
    let nodes = state.flows.filter(e => e.y != null)
    let biggestY = Math.max(...nodes.map(e => e.y)) + 100
    return biggestY
  //   let existsOtherNodeInThatColumn = state.flows.filter(item => item.z == data.pageId && item.x == data.x)
  //   let nodey = 100
  //   if(existsOtherNodeInThatColumn.length > 0){
  //     nodey = Math.max(...existsOtherNodeInThatColumn.map(e => e.y)) + 100
  //   }
  //   return nodey
  },
  getPageId(state){
    let page = state.flows.find(e => e.type == "tab")
    if(page)
      return page.id
    else
      return "1"
  }
}
const mutations = {
  pushToFlows(state, node){
    state.flows.push(node)
  },
  pushToConnections(state, node){
    state.connections.push(node)
  },
  cleanConnections(state){
    state.connections = []
  },
  addSubscription(state, topic) {
    if (!state.subscriptions.includes(topic)){
      state.subscriptions.push(topic)
    }
  },

}
const actions = {
  createConnection({ state }) {
    const { protocol, host, port, endpoint, ...options } = state.connection;
    const connectUrl = `${protocol}://${host}:${port}`;
    // const connectUrl = `ws://${host}:${port}`;                           
    try {
      state.client = mqtt.connect(connectUrl, options);
    } catch (error) {
      console.log("mqtt.connect error", error);
    }
    state.client.on("connect", () => {
      console.log("conectado!!");
    });
    state.client.on("reconnect", () => {
      console.log("Reconnecting...")
    });
    state.client.on('message', (topic, message) => {      
      let selected_topic = state.ListOfmessages.find(a=>a.topic == topic)
      // console.log(state.ListOfmessages)
      // console.log(selected_topic)
      try {
        selected_topic.datos = message;
      } catch (error) {
        //selected_topic.datos = message;
      }
      
      //console.log(state.messages)

  })
    state.client.on("error", (error) => {
      console.log("Connection failed", error);
      return;
    });
  },
  clearConnections({commit}){
    commit('cleanConnections')
  },
  changeTopic({state},data){ //con el plc y la variable vemos cual es el topic del que hay que leer
    let plc = state.connections.find(e => e.id == data.plc)
    if(plc){
      if(data.variable < plc.variables[0].number){
        console.log("la variable no se puede leer")
        return
      }
      let index = plc.variables.findIndex(e => e.number == data.variable)
      if(index != -1)
       state.topic = `PLC${plc.id}_${plc.variables[index].node_number}`
      else console.log("Error no se encuentra la variable")
    }else{
      console.log("No existe ese plc :(")
    }
  },
  doSubscribe({ state, commit }, topic) {
    if (!state.client.connected) {
      console.log('Not connected')
      return
    }
    //state.topic = topic
    const qos = 2
    if (!state.subscriptions.includes(topic)) { //if its not subscribed to this topic we do it

      const mensajes = {
        topic,
        datos:[]
      }
      state.ListOfmessages.push(JSON.parse(JSON.stringify(mensajes)))
      state.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        commit('addSubscription',topic)
      });
    }
  },

  doUnSubscribe({ state, commit }) {
    if (!state.client.connected) {
      console.log('Not connected')
      return
    }
    state.client.unsubscribe(state.topic, error => {
      if (error) {
        console.log('Unsubscribe error', error)
      } else {
        console.log('Unsubscribed successfully')
        commit('deleteSubscription')
      }
    })
  },


  doPublish({ state, commit }, payload) {
    if (!state.client.connected) {
      console.log('Not connected')
      return
    }
    if( payload.topic && payload.mensaje){
      const qos = 2
      console.log("Publicando...")
      console.log(payload.topic+": "+payload.mensaje);
      state.client.publish(payload.topic, payload.mensaje, { qos }, error => {
        if (error) {
          console.log('Publish error', error)
        } else {
          console.log("Succesfully published " + payload.mensaje + " in " + payload.topic)
        }
      })
    }
  },
    
  clearFlows({state}){
    state.flows = []
  },

  async changeFlowName({ state }, page) {
    let flow = state.flows.find(e => e.type == "tab" && e.id == page.id_flow)
    flow.label = `Flow ${page.name}`
    // await this.dispatch('nodered/deploy')
    await this.dispatch('api/deployClientFlows')
  },
  destroyConnection({ state }) {
    if (state.client.connected) {
      try {
        state.client.end(false, () => {
          console.log(`Successfully disconnected!`)
        })
      } catch (error) {
        console.log('Disconnect failed', error.toString())
      }
    } else {
      console.log("Ya estaba desconectado")
    }
  },
  addNode({commit,state}, node){
    commit('pushToFlows', node)
  },
  addConnection({commit, state}, plcConnection){
    commit('pushToConnections', plcConnection)
    state.connections.sort((a,b) => a.id- b.id);

  },
  async postPage({state},name){
    let newFlow = {
      label: "Flow " + name,
      disabled: false,
      type: "tab",
      nodes: [],
      config: []
    }
    const res = await this.dispatch('api/postFlow', newFlow)

    newFlow.id = res.data.id
    this.dispatch('nodered/addNode', newFlow)
    await this.dispatch('api/deployClientFlows')

  },

  async disableNode({commit,state},node){
    node.d = true
    await this.dispatch('api/deployClientFlows')
  },
  async enableNode({commit,state},node){
    node.d = false
    await this.dispatch('api/deployClientFlows')
  },
  async modifyFlows({state},flowsData){
    state.flows = flowsData
    await this.dispatch('api/deployClientFlows')
  },
   async setFlows({commit,state, rootState}){  
    const res = await this.dispatch('api/getFlows')
    res.data.forEach(element => {
      this.dispatch('nodered/addNode',element)
    });
    // await this.dispatch('nodered/postPage', "Principal")
    // this.dispatch('nodered/addNode',{
    //     id: "mqttbrokernode",
    //     type: "mqtt-broker",
    //     name: "localbroker",
    //     // broker: state.connection.protocol + "://" + state.connection.host + ":" + state.connection.port,
    //     broker: state.connection.protocol + "://" + "sakana.koiote.es" + ":" + state.connection.port,
    //     // broker: "mosquitto_scada",
    //     port: "1883",
    //     clientid: "",
    //     autoConnect: true,
    //     setls: false,
    //     protocolVersion: "4",
    //     keepalive: "60",
    //     cleansession: true,
    //     birthTopic: "",
    //     birthQos: "0",
    //     birthPayload: "",
    //     birthMsg: {},
    //     closeTopic: "",
    //     closeQos: "0",
    //     closePayload: "",
    //     closeMsg: {},
    //     willTopic: "",
    //     willQos: "0",
    //     willPayload: "",
    //     willMsg: {},
    //     userProps: "",
    //     sessionExpiry: ""
    //   })
    // await this.dispatch('api/deployClientFlows')

  }

}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

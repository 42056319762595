import KoioteGraficoQuesito from './KoioteGraficoQuesito.vue'
import GraficaQuesito from './GraficaQuesito.vue'
import KoioteGraficoQuesitoProperties from './KoioteGraficoQuesitoProperties.vue'
import KoioteGraficoQuesito1Property from './default.json'

import { Bar } from 'vue-chartjs'

export default {
    install(Vue,options) {
      Vue.component(GraficaQuesito.name, GraficaQuesito)
        Vue.component(KoioteGraficoQuesito.name, KoioteGraficoQuesito)
        Vue.component(KoioteGraficoQuesitoProperties.name, KoioteGraficoQuesitoProperties)
        Vue.prototype.$GraficoQuesitoProperties = KoioteGraficoQuesito1Property


        Vue.mixin({
            mounted() {
             // console.log('Montadito!');
            }
          });
       }
  };
  

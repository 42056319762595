<template>
  <login-component></login-component>
</template>

<script>
  import LoginComponent from '@/components/LoginComponent.vue'

  export default {
    name: 'Login',

    components: {
      LoginComponent,
    },
  }
</script>

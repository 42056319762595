import KoioteSnap7Connection from './KoioteSnap7Connection.vue'
import KoioteSnap7ConnectionProperties from './KoioteSnap7ConnectionProperties.vue'
import KoioteSnap7Connection1Property from './default.json'

export default {
    install(Vue,options) {
        Vue.component(KoioteSnap7Connection.name, KoioteSnap7Connection)
        Vue.component(KoioteSnap7ConnectionProperties.name, KoioteSnap7ConnectionProperties)
        Vue.prototype.$Snap7ConnectionProperties = KoioteSnap7Connection1Property


        Vue.mixin({
            mounted() {
             // console.log('Montadito!');
            }
          });
       }
  };
  

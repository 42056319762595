<template>
    <div>
        <AlarmsComponent></AlarmsComponent>
    </div>
</template>
<script>
import AlarmsComponent from '../components/AlarmsComponent.vue';
export default {
    name: 'AlarmsView',
    components: {
        AlarmsComponent
    },
    
}

</script>
<template lang="es">
     <div class="conexion completo">
        <p class="texto">
            {{myProps.customizables.text}}
        </p>
    </div>
</template>
<script>
export default {
    name:"KoioteSnap7Connection",
    props:{
        myProps:Object
    },

}
</script>
<style>
    .completo{
        height:100%;
       
    }
    .conexion{
        text-align:center;
        border:dotted 2px black;
    }
    
</style>
<template lang="es">
    <div>
      <!-- <h4 class="text-center mt-3">Nombre</h4> -->
      <v-text-field
        prefix="Nombre:"
        class="ml-3 mr-3"
        v-model="component_selected.component_props.component_name"
        single-line
        :disabled = component_selected.component_props.customizables.connected
        :clearable=true
        :clear-icon-cb="component_selected.component_props.component_name == null? changeEditing(true):null"
        @click="changeEditing(true)"
        @blur="changeEditing(false)"
      />
      <div  class="text-center">
        <v-btn v-if="component_selected.component_props.customizables.connected" class="btn mt-4"
        rounded
        color="primary"
        dark
        @click="disconnect()"
        >
        DESCONECTAR
        </v-btn>
        <v-btn v-else class="btn mt-4"
        rounded
        color="primary"
        @click="connect()"
        >
         CONECTAR
        </v-btn>
      </div>
    </div>
  </template>
  <script>
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default {
    name:"KoioteIbhLinkConnectionProperties",
    data() {
        return {
          connectionNodeId:null,
        }
    },
    computed:{
      ...mapState(['component_selected']),
      ...mapState('nodered',['flows','subscriptions']),
      ...mapGetters('nodered',['getNewNodeId','getNewNodeY','getConnections']),
      pageId(){                             // esto dependera de la pagina que sea, esta es por defecto
        if(this.flows.find(e => e.type == "tab" && e.label == 'Principal'))
          return this.flows.find(e => e.type == "tab").id
        else
          return "1"
      }
    },
    methods:{
      ...mapActions(['changeEditing']),
      ...mapActions('nodered', ['addNode','doSubscribe','disableNode','enableNode','modifyFlows']),
      ...mapActions('api',['deployClientFlows']),
      disconnect(){
        // let injects = this.flows.filter(e => e.type == "inject" && e.name == `Read_${this.component_selected.component_props.customizables.connectionID}`) 
        // if(injects.length > 0){
        //   injects.forEach(element => {
        //     this.disableNode(element)
        //   })
        // }
        this.component_selected.component_props.customizables.connected = false
      },
      async connect(){
        this.component_selected.component_props.customizables.connected = true
      },
      toogle_variables(){
        this.show_variables=!this.show_variables;
      },
      leidos(r){
        this.variables=r;
      }
    
    },
  }
  </script>
  <style >
  
  .dialog{
    z-index:9000;
  }
  .v-data-table
    tbody
    tr:hover:not(.v-data-table__expanded__content) {
      background: #ffffff !important; 
  }
  </style>
<template lang="es">
  <div >
        <!-- <v-img class='selector' :src="myProps.customizables.value == 1?require('./right.png'):require('./left.png')" @click="getIsPlayMode?cambioManualDeValor():null"></v-img> -->
				<v-btn :style="`color: ${myProps.customizables.foreground_color}; background-color:${myProps.customizables.background_color};`" @touchstart="getIsPlayMode?executing = true : null" @touchend="getIsPlayMode?executing = false : null"  @mousedown="getIsPlayMode?executing = true : null" @mouseup="getIsPlayMode?executing = false : null" class="setreset" fab><v-icon large>mdi-gesture-tap-hold</v-icon></v-btn>
	 </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
export default {
	name: "KoioteMomentaneo",
	props: {
		myProps: Object,
		h: Number,
		w: Number
	},
	data() {
		return {
			executing: false,
		};
	},
	methods: {
		async  cambioManualDeValor(valor) {
			if(typeof this.myProps.customizables.plc == "number" && this.myProps.customizables.variable != null){
					let plc = this.getConnections.find(e => e.id == this.myProps.customizables.plc)
					if(plc){
						let nuevaVariable = this.$splitVariable(this.myProps.customizables.variable)
						let variable = plc.variables.find(e => e.number == nuevaVariable.numbers)
						let existsInNodered = this.flows.find(e => e.type == 'mqtt in' && e.topic == `PLC${plc.id}_write`)
						//si no existen los nodos en el cliente los añadimos
						if(!existsInNodered)
							await this.addOmronWritingNodes(plc)
						if(variable){
							this.$emit('cambioValor', { id: this.myProps.id_draggable, value: valor})
							let type = variable.type == 'REAL'?'Float':variable.type == 'ENTERO'?'Int':variable.type == 'BIT'? 'Bit':'error'
							let functionName = plc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
							console.log("VOY A MANDAR: ", valor);
							functionName = 'write'+type+'In'+functionName
							this[functionName](this.myProps.customizables, valor)
							
						}else{
							console.log("No existe esa variable en ese plc");
						}
						
					}else{
						console.log("Ese plc no esta conectado en nodered")
					}
				}else{
					console.log("El valor asignado se sale de los limites establecidos para esta variable")
				}
		},
	
	},//fin de los métodos
	computed: {
		...mapState('nodered', ['message','flows','ListOfmessages']),
		...mapState(['component_selected']),
		...mapGetters('nodered', ['getConnections']),
		...mapGetters(['getIsPlayMode']),
		bufferPos(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'BufferPos'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    dataType(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'VarType'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    myPlc(){
      if(this.myProps.customizables.topic != ""){
        return this.getConnections.find(e => e.id == this.myProps.customizables.plc)
      }
    },
		value() {
			let miMensaje = this.ListOfmessages.find(a=>a.topic==this.myProps.customizables.topic)
      if(miMensaje){
        if(this.myPlc && Object.keys(this.myPlc).length > 0 ){
          let functionName = this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
          functionName = 'readValueFrom'+functionName
          return this[functionName](miMensaje,this.myProps.customizables, this.bufferPos, this.dataType, this.myPlc)
        }
      }else{
        // console.log('No hay mensaje de ese topic :(');
      }
		},
	},
	watch: {
		async executing(){
			if(!this.executing){
				await this.cambioManualDeValor(this.myProps.customizables.reset_value)
			}
			while(this.executing){
				await this.cambioManualDeValor(this.myProps.customizables.set_value)
				await sleep(1000)
			}
		},
		value() {
			if (this.value != undefined) {
				this.$emit('cambioValor', { id: this.myProps.id_draggable, value: this.value })
			}
		},
	}
};
</script>
<style>
  .setreset{
    position: absolute;
    cursor:pointer;
    background-color: white;
    width:100%!important;
    height: 100%!important;
  }
#value1-value-label {
	margin: 0;
	padding: 0;
}


/* @media screen and (max-width: 600px) {
 .completo {
   position:relative;
   width:100%;
 }
} */

/* @media screen and (min-width: 1200px) {
 .completo {
   font-size: 6em; 
 }
} */
</style>
<template lang="es">
  <div :class="myProps.customizables.round?'completo round':'completo'">
        <v-img v-if="url != null" :class="myProps.customizables.round?'fullimage round':'fullimage completo'" :src="url"></v-img>
   {{this.myProps.customizables.images.find(e=>e.includes('null'))}}
   </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "KoioteImageSwitch",
  props: {
    myProps: Object,
  },
  methods: {
  },

  computed: {
    ...mapState("nodered", ["message",'ListOfmessages']),
    ...mapState(['component_selected']),
    ...mapGetters('nodered', ['getConnections']),
   
    url() {
      try {
        if (
          this.myProps.customizables.setted_values.includes(
            this.myProps.customizables.value.toString()
          )
        ) {
          return this.myProps.customizables.images[
            this.myProps.customizables.setted_values.indexOf(
              this.myProps.customizables.value.toString()
            )
          ];
        }
        return null
      } catch (error) {
        console.log("file reset not found");
      }
    },
    bufferPos(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'BufferPos'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    dataType(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'VarType'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    myPlc(){
      if(this.myProps.customizables.topic != ""){
        return this.getConnections.find(e => e.id == this.myProps.customizables.plc)
      }
    },
    value() {
      let miMensaje = this.ListOfmessages.find(a=>a.topic==this.myProps.customizables.topic)
      if(miMensaje){
        if(this.myPlc && Object.keys(this.myPlc).length > 0 ){
          let functionName = this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
          functionName = 'readValueFrom'+functionName
          return this[functionName](miMensaje,this.myProps.customizables, this.bufferPos, this.dataType, this.myPlc)
        }
      }else{
        // console.log('No hay mensaje de ese topic :(');
      }
    },
  },
  watch: {
    value(){
      if(this.value != undefined){
        this.$emit('cambioValor', {id: this.myProps.id_draggable, value: this.value})
      }
    }
  },
};
</script>
<style>

.fullimage{
  height:100%;
  width: 100%;
}
.round{
  border-radius: 50%;

}
</style>
<template lang="es">
    <div :style="myStyle" class="completo">
        <div v-if="getIsPlayMode"> 
					
							<koiote-editable-input  @cambio="cambioManualDeValor" propiedad="value" :valor="parseFloat(myProps.customizables.value)" :sufijo="myProps.customizables.unities"></koiote-editable-input>
        </div>
        <div v-else>
           <p :style="valueStyle" id="value1-value-label">
            {{myProps.customizables.value}}
            {{myProps.customizables.unities}}
            </p>
       </div>
   </div>
</template>
<script>
import KoioteEditableInput from "@/components/custom/KoioteEditableInput.vue";
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
	name: "KoioteOmronWrite",
	props: {
		myProps: Object,
		h: Number,
		w: Number
	},
	components: {
		KoioteEditableInput
	},
	methods: {
		async cambioManualDeValor(cambio) {
			console.log("Cambioooooooo");
			if(typeof this.myProps.customizables.plc == "number" && this.myProps.customizables.variable != null){
				if(parseFloat(cambio.valor) >= this.myProps.customizables.minValue && parseFloat(cambio.valor) <= this.myProps.customizables.maxValue){
					let plc = this.getConnections.find(e => e.id == this.myProps.customizables.plc)
					if(plc){
						let nuevaVariable = this.$splitVariable(this.myProps.customizables.variable)
						let variable = plc.variables.find(e => e.number == nuevaVariable.numbers)
						let existsInNodered = this.flows.find(e => e.type == 'mqtt in' && e.topic == `PLC${plc.id}_write`)
						//si no existen los nodos en el cliente los añadimos
						if(!existsInNodered)
							await this.addOmronWritingNodes(plc)
						if(variable && cambio.valor != null){
							this.$emit('cambioValor', { id: this.myProps.id_draggable, value: cambio.valor })
							let type = variable.type == 'REAL'?'Float':variable.type == 'ENTERO'?'Int':variable.type == 'BIT'? 'Bit':'error'
							let functionName = plc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
							functionName = 'write'+type+'In'+functionName
							this[functionName](this.myProps.customizables, cambio.valor)
							
						}else{
							console.log("No existe esa variable en ese plc");
						}
						
					}else{
						console.log("Ese plc no esta conectado en nodered")
					}
				}else{
					console.log("El valor asignado se sale de los limites establecidos para esta variable")
				}
			}else{
				console.log("No tengo plc o variable");
			}
		}
	},//fin de los métodos

	computed: {
		...mapState('nodered', ['message','flows','ListOfmessages']),
		...mapState(['component_selected']),
		...mapGetters('nodered', ['getConnections']),
		...mapGetters(['getIsPlayMode']),
		bufferPos(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'BufferPos'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    dataType(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'VarType'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    myPlc(){
      if(this.myProps.customizables.topic != ""){
        return this.getConnections.find(e => e.id == this.myProps.customizables.plc)
      }
    },
		ancho() {
			let minimo = Math.min(this.h, this.w)
			if (document.documentElement.clientWidth >= 700)
				return minimo / 2
			else
				return minimo / 4
		},
		myStyle() {
			return {
				backgroundColor: this.myProps.customizables.background_color,
				color: this.myProps.customizables.foreground_color,
				border: "1px solid " + this.myProps.customizables.border_color,
				fontSize: this.ancho + 'px'
			};
		},
		valueStyle() {
			return { // automatico desde el css para que no haya que modificar todos manualmente
				// fontSize: this.myProps.customizables.font_size + "rem",
			};
		},
		value() {
			let miMensaje = this.ListOfmessages.find(a=>a.topic==this.myProps.customizables.topic)
      if(miMensaje){
        if(this.myPlc && Object.keys(this.myPlc).length > 0 ){
          let functionName = this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
          functionName = 'readValueFrom'+functionName
          return this[functionName](miMensaje,this.myProps.customizables, this.bufferPos, this.dataType, this.myPlc)
        }
      }else{
        // console.log('No hay mensaje de ese topic :(');
      }
		},
	},
	watch: {
		value() {
			if (this.value != undefined) {
				this.$emit('cambioValor', { id: this.myProps.id_draggable, value: this.value })
			}
		},
	}
};
</script>
<style>
/* .completo {
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 backdrop-filter: blur(5px);
} */
/* .completo {
 font-size: 1.5vw;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
} */
#value1-value-label {
	margin: 0;
	padding: 0;
}


/* @media screen and (max-width: 600px) {
 .completo {
   position:relative;
   width:100%;
 }
} */

/* @media screen and (min-width: 1200px) {
 .completo {
   font-size: 6em; 
 }
} */
</style>
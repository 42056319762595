<template>
    <div>
      <v-row>
        <v-col>
          <v-btn block @click="toogle_variables"> VARIABLES ({{rows.length}}) </v-btn>
        </v-col>
        <v-col v-if="show_data">
          <input type="file" @change="handleFileUpload" />
        </v-col>
      </v-row>
    
      <table v-if="show_data">
        
        <tbody>
          <tr v-for="(row,index1) in rows" :key="index1">
            <td v-for="(cell,index2) in row" :key="index2">{{ cell }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
import * as XLSX from 'xlsx'
  
  export default {
    data() {
      return {
        headers: [],
        rows: [],
        show_data:false,

      };
    },
    methods: {
      handleFileUpload(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
  
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
          // Extract headers
          this.headers = jsonData.shift();
  
          // Set rows data
          this.rows = jsonData;
          this.$emit('variables',this.rows )
        };
  
        reader.readAsArrayBuffer(file);
      },
      toogle_variables(){
        this.show_data=!this.show_data;
      }
      
    },
  };
  </script>
  
<template>
  <v-app-bar id="v-app-1" v-if="isNotLogin()" app color="white">
    <div class="d-flex align-center">
      <router-link to="/">
        <v-img @click="tab = 'one'" alt="Vuetify Logo" 
        class="shrink mr-2" contain 
        :src="logo"
          transition="scale-transition" width="60" />
      </router-link>
      <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100"
        :src="logo_letters" 
        width="100" />
    </div>
    <div v-if="is_admin && $route.name == 'Home'">
      <v-btn v-show="!getIsPlayMode" fab ref="play" small class="ml-3 elevation-4"
        @click="hide_menus"><v-icon>mdi-play</v-icon> </v-btn>
      <v-btn v-show="getIsPlayMode" @click="show_menus" ref="stop" fab small
        class="ml-3 elevation-4"><v-icon>mdi-stop</v-icon> </v-btn>
      <v-btn v-if="!getIsPlayMode" text :disabled="modified_draggables.length == 0 && modified_pages.length == 0"
        @click="save"><v-icon>mdi-content-save</v-icon> </v-btn>
    </div>
    <div>
    </div>
    <v-spacer></v-spacer>

    <v-btn class="mt-2 mr-1" v-show="getIsPlayMode && $route.name == 'Home'" :disabled="pagina_principal"
      @click="goToBackPage"> <v-icon>mdi-keyboard-backspace</v-icon></v-btn>
    <v-btn class="mt-2 mr-1" v-show="getIsPlayMode || $route.name != 'Home'" @click="home">
      <v-icon>mdi-home</v-icon></v-btn>

    <!-- Para pantallas pequeñas -->
    <div class="pantalla_pequena d-flex d-sm-flex d-md-none d-lg-none">
      <v-menu offset-y bottom transition="slide-y-transition">
        <template v-slot:activator="{ props, on }">
          <v-btn fab text v-on="on" v-bind="props">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group class="milista" v-model="tab" color='green'>
            <v-list-item @click="$route.name != 'alarms' ? $router.push({ name: 'alarms' }).catch(() => { }) : null">
              <v-list-item-title>Alarmas</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item  @click="$route.name != 'horno' ? $router.push({ name: 'horno' }).catch(() => { }) : null">
          <v-list-item-title>Ciclos Hornos</v-list-item-title>
        </v-list-item> -->
            <v-list-item v-if="!isRolNormal"
              @click="$route.name != 'users' ? $router.push({ name: 'users' }).catch(() => { }) : null">
              <v-list-item-title>Gestión de Usuarios</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-list-item>
            <v-btn block dark color="#42A5F5" @click="logoutFromApp"> Cerrar sesión</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <!-- Para pantallas grandes-->
    <div class="d-none d-md-flex d-lg-flex d-xl-flex">

      <v-card flat>
        <!-- {{ tab }} -->
        <v-tabs optional v-model="tab" show-arrows>

          <!-- Alarmas -->
          <v-tab value="0" @click="$route.name != 'alarms' ? $router.push({ name: 'alarms' }).catch(() => { }) : null">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-alert</v-icon>
              </template>
              <span>Alarmas</span>
            </v-tooltip>
          </v-tab>

          <!-- Hornos
          <v-tab value="1" @click="$route.name != 'horno' ? $router.push({ name: 'horno' }).catch(() => { }) : null">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-gas-burner</v-icon>
              </template>
              <span>Ciclos Hornos</span>
            </v-tooltip>
          </v-tab> -->

          <!-- Gestión de Usuarios -->
          <v-tab value="1" v-if="!isRolNormal"
            @click="$route.name != 'users' ? $router.push({ name: 'users' }).catch(() => { }) : null">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-account-outline</v-icon>
              </template>
              <span>Gestión de Usuarios</span>
            </v-tooltip>
          </v-tab>
        </v-tabs>
      </v-card>
      <v-spacer></v-spacer>
      <!-- Cerrar Sesion -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="mt-1 elevation-1" v-on="on" @click="logoutFromApp" rounded> <v-icon >mdi-logout</v-icon></v-btn>
        </template>
        <span>Cerrar Sesión</span>
      </v-tooltip>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Swal from 'sweetalert2'


export default {
  name: "NavigationBar",
  data() {
    return {
      selectedIndex: null,
      tab: null,
    };
  },
  computed: {
    ...mapState(['modified_draggables', 'modified_pages']),
    ...mapGetters(["getPages", "getSelectedPage", "getLastSelectedPage", "getIsPlayMode", 'getIsAdmin', 'getMainPage','getRol']),
    
    isRolNormal(){
      return this.getRol != 'admin' && this.getRol != 'avanzado'
    },

    is_admin() {
      return this.getIsAdmin
    },
    pagina_principal() {
      return this.getSelectedPage.is_main_page
    },
    usuario(){
      return JSON.parse(localStorage.getItem("user"));
    },
    logo(){
      return (this.usuario.user_id==8)?'':require('@/assets/logo.png')
    },
    logo_letters(){
      return (this.usuario.user_id==8)?'':require('@/assets/logo-letters.png')
    }
  },
  mounted() {
  },

  methods: {
    ...mapActions(["setSelectedPage", "setIsPlayMode", "unselectPage", 'mousemove', 'deleteComponentSelected',
      'deleteModifiedDraggables', 'deleteModifiedPages', 'goToBackPage']),
    ...mapActions('api', ['putDraggable', 'putDraggableProperties', 'putPageImage', 'putPageName', 'logout']),

    // isRolNormal(){
    //   return this.getRol != 'admin' && this.getRol != 'avanzado'
    // },
    isNotLogin() {
      return this.$route.name != "Login";
    },
    logoutFromApp() {
      document.removeEventListener("mousemove", this.mousemove()) // si no, al cambiar de ruta daba errores porque no encontraba la screen
      this.logout()
      this.$router.push({ name: 'Login' }).catch(() => { })//Si hay cambios sin guardar se cancela el cambio de ruta
    },
    hide_menus() {
      // deseleccionamos si hay algun componente seleccionado para que al volver a la edicion no de problemas
      this.deleteComponentSelected()

      // seleccionamos la pagina principal para ejecutar el scada
      if (this.getPages.length > 0) {
        let mainPage = this.getPages.find(e => e.is_main_page == true)
        if (mainPage)
          this.setSelectedPage(mainPage)
        else
          this.setSelectedPage(this.getPages[0])
          // this.setSelectedPage(this.getPages[3])

      }
      this.setIsPlayMode(true)
      //this.$emit('show',false)      
    },
    show_menus() {
      // this.$emit('show',true) 
      this.setIsPlayMode(false)

    },
    async save() {
      // this.loading = true
      this.modified_draggables.forEach(async element => {
        let payload = {
          id: element.id,
          id_page: element.id_page,
          component_props: element.component_props,
          angle: element.angle,
          x: element.x,                        // hemen %etara konbertsioa egin behar da
          y: element.y,
          w: element.w,
          h: element.h,
          z: element.z
        }
      
      //Change metrics to percentages for adjusting to any screen
      // payload.x = parseFloat(element.x-this.getDimensions.x)*100/parseFloat(this.getDimensions.width)
      // payload.y = parseFloat(element.y-this.getDimensions.y) *100/parseFloat(this.getDimensions.height)
      // payload.w = parseFloat(element.w) *100/parseFloat(this.getDimensions.width)
      // payload.h = parseFloat(element.h)*100/parseFloat(this.getDimensions.height)
        
         this.putDraggable(payload)
        .catch(e => {
            if (e.response.status == 413) {
              Swal.fire({
                icon: 'error',
                title: 'Imagen demasiado pesada',
                text: 'La imagen que quiere utilizar es demasiado pesada.',
              })
              return
            }
          })
          
      })
      this.deleteModifiedDraggables()
      
      this.modified_pages.forEach(async element => {
        await this.putPageName(element)
      });
      this.deleteModifiedPages()
    },


    // atras(){
    //   const lastPage = this.getLastSelectedPage
    //   console.log(lastPage)
    //   this.setSelectedPage(lastPage)
    // },
    home() {
      this.tab = null
      this.$route.name != 'Home' ? this.$router.push({ name: 'Home' }).catch(() => { }) : null
      if (this.getPages.length > 0) {
        let mainPage = this.getPages.find((e) => e.is_main_page == true);
        if (mainPage) this.setSelectedPage(mainPage);
        else this.setSelectedPage(this.getPages[0]);
      }

    }
  },
  watch: {
    $route() {
      if (this.$route.name == 'alarms' && this.tab != 0)
        this.tab = 0
      // else if (this.$route.name == 'horno' && this.tab != 1)
      //   this.tab = 1
      else if (this.$route.name == 'users' && this.tab != 1)
        this.tab = 1
    }
  }
};
</script>
<style>
.pantalla_pequena {
  z-index: 300;
}
</style>






<template>
  <div>
    <Pie
        :options="chartOptions"
        :data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
        v-if="!loading"
    />
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs'
import chartjsPluginAnnotation from "chartjs-plugin-annotation"
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, ChartDataLabels)

export default {
  name: 'PieChart',
  components: {
    Pie, chartjsPluginAnnotation
  },
  props: {
    chartId: {
      type: String,
      default: 'pie-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    datos: {
      type: Array,
      // default: () => [40, 50, 50, 10]
      default: () => []
    },
    chartData: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: true,
    }
    
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: false,
          datalabels: {
            formatter: function(value, context) {
              //Mostramos etiqueta del porcentaje si es mayor a 0
              let porcentaje = Math.round(value/context.chart.getDatasetMeta(0).total * 100)
              if(porcentaje > 0) return porcentaje + "%" 
              else return ''
            },
            color: "#FFF",
          },
        },
      }
    }
  },
}
</script>
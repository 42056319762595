<template>
  <v-app  id="inspire">
     <v-main >
        <v-container fluid fill-height >
           <v-layout align-center justify-center>
              <v-flex xs12 sm8 md4>
                  <!-- <v-img class='logo_login' src="@/assets/sakana-transparente.png" contain></v-img> -->
                  <v-card class="elevation-12" >
                     <v-toolbar class="sakana_logo" color="white">
                       <v-toolbar-title >
                        <v-img alt="logo Sakana" width="170"  src="@/assets/sakana-transparente.png"
                              transition="scale-transition" 
                              />
                       </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="text-center" >
                        <span class="display-1 font-weight-bold">Iniciar Sesión</span>
                        <v-form class="mt-6">
                          <v-text-field prepend-inner-icon="mdi-account" name="username" label="Usuario" 
                             type="text"
                             :rules="[v => !!v || 'Rellene el usuario']"
                             v-model="userInfo.username"></v-text-field>
                          <v-text-field prepend-inner-icon="mdi-lock" id="password" name="psw" label="Contraseña"
                             type="password" v-model="userInfo.password"
                             :rules="[v => !!v || 'Rellene la contraseña']"
                             ></v-text-field>
                       </v-form>
                       <!-- {{ userInfo }} -->
                    </v-card-text>
                    <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn elevation="4" class="mb-2 mr-3" color="red" outlined @click="logInApp">Entrar</v-btn>
                    </v-card-actions>
                 </v-card>
              </v-flex>
           </v-layout>
        </v-container>
     </v-main>
     <v-dialog v-model="dialog"  :overlay="false" max-width="500px"
                 transition="dialog-transition"> 
        <v-alert 
        type="error"
     >
        <v-row
        align="center"
        no-gutters
        >
        <v-col class="grow">
           {{ error_text }}
        </v-col>
        </v-row>
        <v-row>
        <v-spacer></v-spacer>
        <v-col class="shrink">
           
           <v-btn 
              outlined
              @click="dialog = false">
                 Volver a intentar
                 </v-btn>
        </v-col>
        </v-row>
     </v-alert>
   </v-dialog> 
   <Keypress key-event="keydown" :key-code="13" @success="logInApp" /> 
  </v-app>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
     return {
        userInfo: {
           username: '',
           password: '',
        },
        dialog: false,
        error_text:""
     }
  },
  components:{
    Keypress: () => import('vue-keypress'),
  },
  methods: {
     ...mapActions('api',['login']),
     async logInApp() {
        if(this.userInfo.username != '' && this.userInfo.password != ''){
           const error = await this.login(this.userInfo)
          if(error){
             this.error_text = "Usuario o contraseña incorrectos"
             this.dialog = true
          }else{
             this.$router.push({ name: 'Home' })
          }
        }
           
     },

  }
}
</script>
<style>
</style>
<template lang="es">
    
    <div @click="executable ? changePage():null">
      <!-- {{myProps}} -->
      <!-- Si es un icono -->
    <v-card :ripple="getIsPlayMode" v-if="myProps.customizables.nav_type == 'Icono' && myProps.customizables.next_icon" 
      :class = "myProps.customizables.round ? 'navigation rounded-circle nav-image  text-center':'navigation text-center nav-image'">
      <img class="nav-image" 
          :src="require(`./assets/${myProps.customizables.next_icon}`)"
          :alt="`go to ${myProps.customizables.next_page}`">
      </img>
    </v-card>

    <!-- Si es texto  -->
    <v-btn v-else-if="myProps.customizables.nav_type == 'Texto'" :ripple="getIsPlayMode" class="navigation completo" :style="myStyle" >
      <span>{{myProps.customizables.nav_text}}</span>
    </v-btn>

    <!-- Si es una imagen -->
    <div v-else class="transparent_navigation">
        <!-- <v-img :class="myProps.customizables.round?'imagencilla round':'imagencilla'" :src="myProps.customizables.image"></v-img> -->

        <!-- <v-img  cover :src="myProps.customizables.image"></v-img> -->
        <v-btn block class="custom-btn" text  :ripple="getIsPlayMode" :style="estilo"></v-btn>
    </div>
      
      
  </div>
</template>
<script>
  import {mapActions, mapGetters, mapState} from 'vuex'
  export default {
    name:"KoioteNavigation",
    props:{
      h:Number,
      w:Number,
      myProps:Object,
      executable:{
        type:Boolean, 
        default: true
      }
    },
    methods:{
      ...mapActions(['setSelectedPage']),
      changePage(){ 
        if(this.myProps.customizables.next_page){
            let page = this.getPages.find(e => e.id == this.myProps.customizables.next_page)
            if(page)
                this.setSelectedPage(page)
        }
      }
    },
    computed:{
      ...mapGetters(['getPages','getIsPlayMode']),
     ancho(){
      let minimo = Math.min(this.h, this.w)
            if(document.documentElement.clientWidth >= 700)
                return minimo/2
            else
                return minimo/6
     },
      estilo(){
        return (this.myProps.customizables.image)? {backgroundImage: 'url(' + this.myProps.customizables.image + ')'} :''
      },
      image_style(){
        return {
          width:'100%', 
          height:'100%',
          'object-fit':'cover'
        }
      },
        myStyle()
        {
          let style = {backgroundColor:this.myProps.customizables.background_color, color:this.myProps.customizables.foreground_color}
          if(this.myProps.customizables.round){
            style.borderRadius =  '50%'
          }
          if(this.myProps.customizables.border){
            style.border = `${this.myProps.customizables.border_width}px solid ${this.myProps.customizables.border_color}`
          }
          style.fontSize = this.ancho+'px'
          return style
        }
    }
  }
</script>
<style scoped>

.custom-btn {
  flex: 1;
  width: 100%;
  height: 100% !important;
  background-size: 100% 100%;
  background-color: transparent!important;
  background-position: center; 
}
.transparent_navigation{
    position: absolute;
    cursor:pointer;
    width:100%!important;
    height: 100%!important;
}
  .navigation{
    position: absolute;
    cursor:pointer;
    background-color: white;
    width:100%!important;
    height: 100%!important;
  }
  .nav-image{
    margin-top: 4%;
    width:90%;
    height: 90%;
  }
  .nav-image:hover{
    background-color: rgb(232, 232, 232);
    transition:  0.3s ease;
    /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);  */
  }
  /* .completo{

      font-size: 1.5vw;
      height:100%;
      display:flex;
      align-items:center;
      justify-content:center;

  } */
  @media screen and (max-width: 600px) {
  .completo {
    /* Adjusted font size for smaller screens */
    
    /* font-size: 10vw;  */
    /* white-space: nowrap; Prevent line breaks */
    font-size: calc(100vw / 10); /* Adjust the division value to control the font size */
/* 
    font-size: fit-content; */
  }

}

@media screen and (min-width: 1200px) {
  .completo {
    font-size: 16px; /* Adjusted font size for larger screens */
  }
}

/* Styles for when width is greater than height */
@media (min-width: 600px) and (orientation: landscape) {
  .ejemplo{
    background-color: red;
  }
}

/* Styles for other conditions */
@media (max-width: 599px), (orientation: portrait) {
  .ejemplo{
    background-color: rgb(0, 255, 132);
  }
}
    
</style>
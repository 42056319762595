<template lang="es">
    <div>
      <!-- <h4 class="text-center mt-3">Nombre</h4> -->
      <v-text-field
        prefix="Nombre:"
        class="ml-3 mr-3"
        v-model="component_selected.component_props.component_name"
        single-line
        :disabled = component_selected.component_props.customizables.connected
        clearable
        @keyup.enter="changeEditing(false)"
        :clear-icon-cb="component_selected.component_props.component_name == null? changeEditing(true):null"
        @click="changeEditing(true)"
        @blur="changeEditing(false)"
      />
      <!-- <h4 class="text-center mt-3">IP</h4> -->
      <v-text-field
        prefix="IP:"
        class="ml-3 mr-3"
        v-model="component_selected.component_props.customizables.ip"
        single-line
        @change="modifying = true"
        :disabled = component_selected.component_props.customizables.connected
        clearable
        @keyup.enter="changeEditing(false)"
        :clear-icon-cb="component_selected.component_props.customizables.ip == null? changeEditing(true):null"
        @click="changeEditing(true)"
        @blur="changeEditing(false)"
      />
      <!-- @keypress="validateIp($event)"  -->
      <v-text-field
        prefix="Puerto:"
        class="ml-3 mr-3"
        v-model.number="component_selected.component_props.customizables.port"
        single-line
        type="number"
        @keypress="validatePort($event)" 
        :min=0
        :max=65535
        :disabled = component_selected.component_props.customizables.connected
        @keyup.enter="changeEditing(false)"
        @click="changeEditing(true)"
        @blur="changeEditing(false)"
      />
      <v-text-field
        prefix="Nº max. variables:"
        class="ml-3 mr-3"
        v-model.number="component_selected.component_props.customizables.count"
        @change="modifying = true; countChange(); "
        single-line
        type="number"
        @keypress="validateCount($event)" 
        :min=0
        :max=999
        :disabled = component_selected.component_props.customizables.connected
        @keyup.enter="changeEditing(false)"
        @click="changeEditing(true)"
        @blur="changeEditing(false)"
      />
      <div v-if="!variablesTratadas && !component_selected.component_props.customizables.connected">
        <!-- Add variables to this plc -->
        <h4 class="text-center mt-3">Añadir variables:</h4>
        <v-file-input
          class="ml-3 mr-3"
          accept=".xlsx"
          label="Seleccionar Excel"
          show-size
          v-model="file"
          @change="onFileChange()"
        >
        </v-file-input>
      </div>
      
      <!-- v-dialog for showing the -->
      <div v-else class="text-center dialog">
        <v-dialog  scrollable v-if="variablesTratadas"  v-model="dialog">
          <template v-slot:activator="{ on, attrs } ">
            <v-btn class="btn mt-4" 
              rounded
              color="black"
              dark
              v-bind="attrs"
              v-on="on"
              @click="dialog = true"
              >
              Ver variables ({{component_selected.component_props.customizables.vartable.length}})
              </v-btn>
          </template>
            <v-card  >
              <v-card-title>Variables - {{component_selected.component_props.component_name}}<v-spacer></v-spacer><v-icon @click="dialog = false">mdi-close</v-icon></v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-data-table
                :headers="headers"
               
                :items-per-page="-1"
                hide-default-footer
                disable-pagination
                :items="component_selected.component_props.customizables.vartable"
                class="elevation-1 mt-10 ml-10 mr-10 mb-10 tabla"
                >
                <template #item.full_var="{ item }">{{ item.mem_type }}{{ item.number }}{{item.bit != undefined ?'.'+item.bit:null}}</template>
              </v-data-table>
                
              </v-card-text> 
          </v-card>
        </v-dialog>
      </div>
      <div class="mt-5 text-center">
       <v-btn  v-if="component_selected.component_props.customizables.vartable.length>0 && variablesTratadas && !component_selected.component_props.customizables.connected"
         text
         @click="variablesTratadas = false; modifying = true">
         Modificar variables
       </v-btn>
       </div>
      <!-- Connect or disconnect plc (modifies client nodered) -->
      <div v-if="component_selected.component_props.customizables.connected" class="text-center">
        <v-btn class="btn mt-4"
        rounded
        color="primary"
        dark
        @click="disconnect()"
        >
       DESCONECTAR
        </v-btn>
      </div>
      <div v-else class="text-center">
        <v-btn class="btn mt-4"
        rounded
        :disabled="component_selected.component_props.customizables.ip == '' || component_selected.component_props.customizables.vartable.length == 0"
        color="primary"
        @click="connect()"
        >
         CONECTAR
        </v-btn>
      </div>
    </div>
  </template>
  <script>
import readXlsFile from "read-excel-file"
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default {
    name:"KoioteOmronConnectionProperties",
    
   
    data() {
        return {
           
            connectionNodeId:null,
            file:[],
            y:null,
            variablesArray:[],
            modifying:false,
            dialog: false,
            // variables:[{mem_type:"DM",number:999, description: "prueba", type:"ENTERO", historizar:"SI"}],
            variablesTratadas:false,
            headers: [
              {
                text: 'Variable',
                align: 'start',
                sortable: false,
                value: 'full_var',
              },
              { text: 'Descripcion', value: 'description' },
              { text: 'Tipo', value: 'type' },
              { text: 'Historizar', value: 'historizar' },
            ],
        }
    },
    created(){
      // this.component_selected.component_props = JSON.parse(JSON.stringify(this.component_selected.component_props));
      this.variablesTratadas = this.component_selected.component_props.customizables.vartable.length > 0      
    },
    computed:{
      ...mapState(['component_selected']),
      ...mapState('nodered',['flows','subscriptions']),
      ...mapGetters('nodered',['getNewNodeId','getNewNodeY','getConnections','getPageId']),
      // pageId(){                             // esto dependera de la pagina que sea, esta es por defecto
      //   if(this.flows.find(e => e.type == "tab" && e.label == 'Principal'))
      //     return this.flows.find(e => e.type == "tab").id
      //   else
      //     return "1"
      // }
    },
    watch:{
        component_selected(){
          this.component_selected.component_props = JSON.parse(JSON.stringify(this.component_selected.component_props));
          this.variablesTratadas = this.component_selected.component_props.customizables.vartable.length > 0
        },
        'component_selected.component_props.customizables.port': function(){
          if(this.component_selected.component_props.customizables.port > 65535)
            this.component_selected.component_props.customizables.port = 65535
        },
    },
   
    methods:{
      ...mapActions(['changeEditing']),
      ...mapActions('nodered', ['addNode','disableNode','enableNode','modifyFlows']),
      ...mapActions('api',['deployClientFlows']),
      closeDialog(){
        console.log("Closissiinig");
        this.dialog = false
      },
      checkIP(){
        if (!(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi).test(this.component_selected.component_props.customizables.ip))
          return false
        else return true
      },

      validatePort($event){
        if(this.isNumber($event)){
          if((this.component_selected.component_props.customizables.port * 10 + parseInt($event.key)) > 65535)
            $event.preventDefault()
        }
      },
      validateCount($event){
        if(this.isNumber($event)){
          if((this.component_selected.component_props.customizables.count * 10 + parseInt($event.key)) > 999)
            $event.preventDefault()
        }
      },
      countChange(){
        console.log("Ha cambiado el count!!!!!!");
        if(this.component_selected.component_props.customizables.vartable.length > 0){
          this.component_selected.component_props.customizables.vartable.forEach(e => {
            e.node_number = parseInt((e.number-this.component_selected.component_props.customizables.vartable[0].number)/parseInt(this.component_selected.component_props.customizables.count)) + 1
          })
        }
      },

      isNumber ($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57)){
          $event.preventDefault();
          return false
        } else{
          return true
        }
      },
      async onFileChange(){
        if(this.file){
          this.component_selected.component_props.customizables.vartable = []
          const rows = await readXlsFile(this.file)
          this.variablesArray = rows
          // eliminamos las variables que no se vayan a utilizar (las lineas que tengan alguna celda vacia)
          if(this.variablesArray.length > 0)
            this.variablesArray = this.variablesArray.filter(e => e[0] != null && e[1] != null && e[2] != null)
          else return 

          //si no hay variables que se vayan a usar se saldrá de la funcion
          if(this.variablesArray.length > 0){
            this.variablesArray.forEach(e =>{
              let variable = this.$splitVariable(e[0])
              this.component_selected.component_props.customizables.vartable.push({mem_type: variable.characters.toUpperCase(), number: variable.numbers, bit: variable.bit, description:e[1], type: e[2].toUpperCase(), historizar: e[3] != null ? 'SI': 'NO'})
           })

           //vemos en que nodo de lectura del plc se va a leer
            this.component_selected.component_props.customizables.vartable.forEach(e => {
              e.node_number = parseInt((e.number-this.component_selected.component_props.customizables.vartable[0].number)/parseInt(this.component_selected.component_props.customizables.count)) + 1
            })

            console.log( this.component_selected.component_props.customizables.vartable);
            this.variablesTratadas = true
            this.file =[]
          }else{
            this.variablesTratadas = false
            this.file = []
          } return 
        }
      },

      disconnect(){
        let injects = this.flows.filter(e => e.type == "inject" && e.name == `Read_${this.component_selected.component_props.customizables.connectionID}`) 
        if(injects.length > 0){
          injects.forEach(element => {
            this.disableNode(element)
          })
        }
        this.component_selected.component_props.customizables.connected = false
      },
      connectExistingPLC(){
        let injects = this.flows.filter(e => e.type == "inject" && e.name == `Read_${this.component_selected.component_props.customizables.connectionID}`) 
        if(injects.length > 0){
          injects.forEach(element => {
            this.enableNode(element)
          })
        }
      },
      async connect(){
        console.log("CONECTANDO...");
        console.log(this.flows);
        if(this.checkIP() && this.variablesTratadas){
          // begiratzen dugu ea konexio hori jada existitzen den
          let existingConnection = this.flows.find(e => e.type == "inject" && e.name == `Read_${this.component_selected.component_props.customizables.connectionID}`)
          if(!existingConnection){
            this.connectionNodeId = this.getNewNodeId
            this.addConnectionNode()

            // calculamos cuantos nodos harán falta para la lectura en nodered
            let readNodeNumber = this.component_selected.component_props.customizables.vartable[this.component_selected.component_props.customizables.vartable.length - 1].number - this.component_selected.component_props.customizables.vartable[0].number
            readNodeNumber = parseInt(readNodeNumber / parseInt(this.component_selected.component_props.customizables.count)+ 1)
            console.log("voy a meter estos: "+readNodeNumber);
            //gehitzen ditugu omronetik irakurtzeko behar diren nodoak
            for(let i = 0; i<readNodeNumber; i++){
              let first_address = this.component_selected.component_props.customizables.vartable.find(e => e.node_number == i+1)
                if(first_address){ // si no hay ninguna variable en un determinado rango no se añade ese nodo
                  this.addInject(first_address.number)                              
                  this.addReadNode(first_address.number)
                  this.addTopicFunctionNode(i+1)
                  this.addMqttOut()
                  await this.deployClientFlows()
                }
            } 
          }else{
            console.log("YA existe")
            if(this.modifying){  // si ya existe pero se modifican las variables y/o la ip y ouerto se borran los nodos del nodered y se vuelven a crear
              console.log(this.component_selected.component_props.customizables.connectionID);
              let injects = this.flows.filter(e => e.name == `Read_${this.component_selected.component_props.customizables.connectionID}`)
              let flows = []
              injects.forEach(element => {
                let omronRead = this.flows.find(node => node.id == element.wires[0][0])
                let connectionNode = this.flows.find(node => node.id == omronRead.connection)
                let functionNode = this.flows.find(node =>node.id == omronRead.wires[0][0])
                let mqttNode = this.flows.find(node => node.id == functionNode.wires[0][0])
                if(flows.length == 0)
                  flows = this.flows.filter(e => e != omronRead && e != functionNode && e != mqttNode && e != element && e != connectionNode )
                else 
                  flows = flows.filter(e => e != omronRead && e != functionNode && e != mqttNode && e != element && e != connectionNode)
                })
                console.log(flows.length);
                this.modifyFlows(flows)
                console.log("Hay estos nodos: ", this.flows)
                this.connectionNodeId = this.getNewNodeId
                this.addConnectionNode()

                // calculamos cuantos nodos harán falta para la lectura en nodered
                let readNodeNumber = this.component_selected.component_props.customizables.vartable[this.component_selected.component_props.customizables.vartable.length - 1].number - this.component_selected.component_props.customizables.vartable[0].number
                readNodeNumber = parseInt(readNodeNumber / parseInt(this.component_selected.component_props.customizables.count) + 1)
                //gehitzen ditugu omronetik irakurtzeko behar diren nodoak
                console.log(readNodeNumber);
                console.log(this.component_selected.component_props.customizables.vartable);
                for(let i = 0; i<readNodeNumber; i++){
                  let first_address = this.component_selected.component_props.customizables.vartable.find(e => e.node_number == i+1)
                  if(first_address){
                    this.addInject(first_address.number)                              
                    this.addReadNode(first_address.number)
                    this.addTopicFunctionNode(i+1)
                    this.addMqttOut()
                    await this.deployClientFlows()
                  }
                } 
              this.modifying = false
            }else
              this.connectExistingPLC()
          }
          this.component_selected.component_props.customizables.connected = true
        }else{
          console.log("IP no válida o no hay variables")
        }
      },
      addConnectionNode(){
        if(!this.component_selected.component_props.customizables.ip){
          console.log("Falta la IP");
          return
        }
        let da1 = this.component_selected.component_props.customizables.ip.split('.')
        if(!da1[3]){
          console.log("La ip no está completa");
          return
        }
        // let sa1 = process.env.VUE_APP_NODERED_CLIENT_HOST     // no se puede porque es localhost con el tunel
        // this.component_selected.component_props.customizables.SA1 = sa1.split('.')[3]
        this.component_selected.component_props.customizables.SA1 = "138"

        console.log(this.component_selected.component_props.customizables.ip);
        this.component_selected.component_props.customizables.DA1 = da1[3]
        this.addNode({
          id: this.connectionNodeId,
          type: "FINS Connection",
          name: this.component_selected.component_props.component_name,
          host: this.component_selected.component_props.customizables.ip,
          port: `${this.component_selected.component_props.customizables.port}`,
          MODE: "",
          MODEType: this.component_selected.component_props.customizables.MODEType,
          protocol: "",
          protocolType: this.component_selected.component_props.customizables.protocolType,
          ICF: this.component_selected.component_props.customizables.ICF,
          DNA: this.component_selected.component_props.customizables.DNA,
          DA1: this.component_selected.component_props.customizables.DA1,
          DA2: this.component_selected.component_props.customizables.DA2,
          SNA: this.component_selected.component_props.customizables.SNA,
          SA1: this.component_selected.component_props.customizables.SA1,  // si ponemos 0 es un nodo interno, si no suele ser los tres ultimos digitos del server IP
          SA2: this.component_selected.component_props.customizables.SA2,
          autoConnect: true
        })
      },
      addInject(first_address) {
        //lortu y eta id
        this.y = this.getNewNodeY({x: 180, pageId: this.getPageId})
        let nodeid = this.getNewNodeId
        this.addNode({
          id: nodeid,
          type: "inject",
          z: this.getPageId,             // hau aldatu beharko da
          name: `Read_${this.component_selected.component_props.customizables.connectionID}`,
          props: [
            {
              p: "topic",
              vt: "str"
            },
            {
              p: "count",
              v: parseInt(this.component_selected.component_props.customizables.count),                       
              vt: "num"
          }
          ],
          repeat: "1",  // cada cuantos segs leeremos del autómata
          crontab: "",
          once: false,
          onceDelay: 0.1,
          topic: "D"+first_address,          //nondik hasiko garen irakurtzen
          x: 180,
          y: this.y,
          wires: [
            [
              `${parseInt(nodeid) + 1}`
            ]
          ]
        })
      },
      addReadNode(first_address){
        let nodeid = this.getNewNodeId
        this.addNode({
          id: nodeid,
          type: "FINS Read",
          z: this.getPageId,                                
          name: `Read ${this.component_selected.component_props.customizables.connectionID}_${first_address}`,
          connection: `${this.connectionNodeId}`,
          addressType: "msg",
          address: "topic",
          countType: "msg",
          count: "count", // le llega como parametro del inject
          msgPropertyType: "msg",
          msgProperty: "payload",
          // outputFormatType: data.type == 'float' || data.type == 'boolean'? 'buffer': 'signed',
          outputFormatType:'buffer',
          outputFormat: "",
          x: 380,
          y: this.y,
          wires: [
            [
              `${parseInt(nodeid) + 1}`
            ]
          ]
        })
      },
      addTopicFunctionNode(node_number){
        let nodeid = this.getNewNodeId
        this.addNode({
          id: nodeid,
          type: "function",
          z: this.getPageId,
          name: `getTopicFrom${this.component_selected.component_props.component_name}`,
          func: `msg.topic = 'PLC${this.component_selected.component_props.customizables.connectionID}'+'_${node_number}'\nreturn msg;`,
          outputs: 1,
          noerr: 0,
          initialize: "",
          finalize: "",
          libs: [],
          x: 710,
          y: this.y,
          wires: [
            [
            `${parseInt(nodeid) + 1}`
            ]
          ]
        })
      },
      addMqttOut(){
        this.addNode({
          id: this.getNewNodeId,
          type: "mqtt out",
          z: this.getPageId,
          name: `mqtt out`,
          topic: "",
          qos: "2",
          retain: "",
          respTopic: "",
          contentType: "",
          userProps: "",
          correl: "",
          expiry: "",
          broker: "mqttbrokernode",
          x: 980,
          y: this.y,
          wires: [
            []
          ]
        })
      }, 
      toogle_variables(){
        this.show_variables=!this.show_variables;
      },
      leidos(r){
        this.variables=r;
      }
    
    },
  }
  </script>
  <style >
  
  .dialog{
    z-index:9000;
  }
  .v-data-table
    tbody
    tr:hover:not(.v-data-table__expanded__content) {
      background: #ffffff !important; 
  }
  </style>
import KoioteImageSwitchHover from './KoioteImageSwitchHover.vue'
import KoioteImageSwitchHoverProperties from './KoioteImageSwitchHoverProperties.vue'
import KoioteImageSwitchHover1Property from './default.json'

export default {
    install(Vue,options) {
        Vue.component('koiote-image-switch-hover', KoioteImageSwitchHover);
        Vue.component('koiote-image-switch-hover-properties', KoioteImageSwitchHoverProperties);
        Vue.prototype.$koioteImageSwitch1Property = KoioteImageSwitchHover1Property;

        Vue.mixin({
            mounted() {
             // console.log('Montadito!');
            }
          });
       }
  };
  

<template lang="es">
  <div>
 
    <v-text-field
      prefix="Nombre:"
      label="Nombre"
      class="ml-3 mr-3"
      @click="changeEditing(true)"
      @blur="changeEditing(false)"
      v-model="component_selected.component_props.component_name"
      single-line
    />
    <v-checkbox
      class="ml-2"
      color="info"
      v-model="component_selected.component_props.customizables.show_value" 
      label="Mostrar valor">
    </v-checkbox>
    <v-text-field
      prefix="Valor Máximo:"
      class="ml-3 mr-3"
      v-model.number="component_selected.component_props.customizables.valorMaximo"
      single-line
      type="number"
      @click="changeEditing(true)"
      @blur="changeEditing(false)"
      @keypress="isNumber($event)"
      :min="component_selected.component_props.customizables.value ? component_selected.component_props.customizables.value:0"
      />
      <!-- background color picker -->
    <koiote-color-picker-vue titulo="Color del Fondo"
      :pcolor="nuevo_objeto.customizables.colores[1].color"
      @event="cambio_background_color">
    </koiote-color-picker-vue>
    
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import KoioteColorPickerVue from '@/components/custom/KoioteColorPicker.vue';
export default {
  name:"KoioteSilo1Properties",
  components:{
      KoioteColorPickerVue
  },
  data() {
      return {
          //background
          backgroundmenu: false,

          nuevo_objeto:null,
      }
  },
  computed:{
    ...mapState(['component_selected']),
    ...mapState('nodered',['message']),
    ...mapGetters('nodered',['getConnections','getTopic'])
},
  created() {
    this.nuevo_objeto = JSON.parse(JSON.stringify(this.component_selected.component_props));
  },
  methods:{
    ...mapActions('nodered',['changeTopic']),
    ...mapActions(['changeEditing']),

    
    cambio_background_color(nc){
        // this.nuevo_objeto.customizables.colores[1].color=nc
        this.component_selected.component_props.customizables.colores[1].color=nc
    },
    isNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
        if(keyCode !== 46){
          $event.preventDefault();
          return false
        }
      }
    },
  },

  watch:{
    component_selected(){
      this.nuevo_objeto = JSON.parse(JSON.stringify(this.component_selected.component_props));
    },
  }
}
</script>
<style lang="">

</style>
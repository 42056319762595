<template>
  <div class="hello" id="general" :style="estilo" @click="click_on_screen">
    <!-- {{ Ventana de herramientas }} -->
    <koiote-toolbar
      v-if="!getIsPlayMode && is_admin"
      :tools="herramientas"
    ></koiote-toolbar>
    <!-- Fin del menú superior-->
    <!-- SCREEN -->
    <koiote-screen :overlay="overlay" :canDeselect="canDeselect"></koiote-screen>
    <!-- FIN DEL SCREEN -->
    <!-- Ventana de propiedades -->
    <koiote-properties-1 v-if="!getIsPlayMode && is_admin"></koiote-properties-1>
    <!-- Fin de la ventana de propiedades -->
      <!-- Footer -->
      <koiote-footer v-if="!getIsPlayMode && is_admin"></koiote-footer>
    <!-- Fin de la ventana de propiedades -->

   
    <Keypress
      key-event="keydown"
      :key-code="46"
      @success="canDeselect && !editing ? deleteDraggable() : null"
    />
  </div>
</template>
  
  <script>
// import KoioteProperties from "./generic/KoioteProperties.vue";
// import KoioteProperties from "./generic/KoioteProperties1.vue";
import KoioteToolbar from "./generic/KoioteToolbar.vue";
import KoioteFooter from "./generic/KoioteFooter.vue";
import KoioteScreen from "./generic/KoioteScreen.vue";
import NavigationBar from "@/components/NavigationBar.vue";

import { mapActions, mapGetters, mapState } from "vuex";
import KoioteProperties1 from './generic/KoioteProperties1.vue';
import Swal from 'sweetalert2'
import moment from 'moment';

export default {
  name: "HelloWorld",
  components: {
  
    KoioteToolbar,
    KoioteScreen,
    KoioteFooter,
    NavigationBar,
    Keypress: () => import("vue-keypress"),
    KoioteProperties1,
  },
  props: {
    msg: String,
  },
  
  mounted() {
      document.addEventListener("mousemove", this.mousemove);
      window.addEventListener("resize", this.getDimensions);
      window.addEventListener("transitionend", this.getDimensions);
     
      this.getMaquinasBack() //Rellenamos el store con las máquinas para poder crear las gráficas posteriormente
      this.gethistoricos8Back() //Rellenamos el store con los historicos de las últimas 8 horas
      this.getHistoricoSemana() //Rellenamos estore con historicos de toda la semana
  },

  async created() {
    try {
      this.loadTools();
      this.setFlows();
      await this.startApp()
      if (this.getPages.length > 0) {
        let mainPage = this.getPages.find((e) => e.is_main_page == true);
        if (mainPage) this.setSelectedPage(mainPage);
        else this.setSelectedPage(this.getPages[0]);
      }
      this.overlay = false;
    } catch (e) {
      //Sesion caducada
      if(e.response.status == 401 ||e.response.status == 419 ){
          console.log('Sesion caducada, redirigirimos a login!');
          Swal.fire({
            icon: 'error',
            title: 'Sesión caducada...',
            text: 'La sesión a caducado y seras redirigido al login',
          })
          localStorage.clear();
          this.$router.push({ name:'Login'})
        }
    }
  },

  data() {
    //x e y son el centro del rect
    return {
      width: document.documentElement.clientWidth,
      x: 50,
      y: 50,
      w: 100,
      h: 100,
      datos: [],
      herramientas: [],
      overlay: true,
    };
  },

  computed: {
    ...mapState([
      "selected_page",
      "miRect",
      "mouseX",
      "mouseY",
      "list_components",
      "tool",
      "component_selected",
      "full_height",
      "modified_draggables",
      "modified_pages",
      'editing',
    ]),
    ...mapState("nodered", ["flows", "message", "subscriptions"]),
    ...mapGetters([
      "vuexLastId",
      "getSelectedPage",
      "getScreenUpdated",
      "getIsAdmin",
      "getIsPlayMode",
      "getPages",
    ]),
    ...mapGetters("nodered", ["getConnections"]),
    canDeselect() {
      let resultado = false;
      if (Object.keys(this.component_selected).length != 0) {
        if (this.mouseX < 0 || this.mouseY < 0) return false;
        if (this.mouseX > this.miRect.width) return false;
        // resultado=this.miRect
        return true;
      }
    },
    is_admin() {
      return this.getIsAdmin;
    },
    canPut() {
      let resultado = false;
      if (Object.keys(this.tool).length != 0) {
        if (this.mouseX < 0 || this.mouseY < 0) return false;
        if (this.mouseX > this.miRect.width) return false;
        resultado = this.miRect;
      }
      return resultado;
    },
    estilo() {
      return this.canPut ? "cursor:crosshair!important" : "auto";
    },
  },//Fin computed

  methods: {
    ...mapActions([
      "mousemove",
      "setTool",
      "deleteDraggable",
      "changeHeight",
      "startApp",
      "setScreenUpdated",
      "setSelectedPage",
    ]),
    ...mapActions("nodered", ["setFlows"]),
    ...mapActions("api", ["postDraggable", "putDraggable", "logout", "getMaquinasBack", "gethistoricos8Back", "getHistoricosFecha"]),
    //Cargar todas las herramientas
    async loadTools() {
      let modulesReq = require.context("../plugins", true, /\.json$/);
      modulesReq.keys().forEach(async (k) => {
        //Eliminar "./"
        const plugin_name = k.substring(2);

        //Localizar raíz
        let index = plugin_name.lastIndexOf("/");
        const root = plugin_name.substring(0, index);

        let myPlugin = await import(`@/plugins/${plugin_name}`);
        myPlugin.root = `plugins/${root}`;
        this.herramientas.push(myPlugin);
      });
    },
    async add_element() {
      // let todos = this.list_components;
      const propiedades = this.tool.default.customized;
      console.log(propiedades);
      let nuevoComponente = {
        angle: 0,
        w: propiedades.width,
        h: propiedades.height,
        x: this.mouseX,
        y: this.mouseY,
        z: 10,
        // id:this.vuexLastId+1,
        id_page: this.getSelectedPage.id,
        selected: false,
        component_props: this.tool.settings,
      };
      nuevoComponente.component_props.customizables = JSON.parse(
        JSON.stringify(this.tool.customized)
      );
      // objeto.customized.initial_screen_size = {width:this.$parent.$el.offsetWidth, height:this.$parent.$el.offsetHeight}
      let w = window.document.getElementById("screen").offsetWidth;
      let h = window.document.getElementById("screen").offsetHeight;
      nuevoComponente.component_props.customizables.initial_screen_size = {
        width: w,
        height: h,
      };

      const res = await this.postDraggable(nuevoComponente); // hemen neurriak % etara pasatu behar dira
      nuevoComponente.id = res.data[0].id;
      nuevoComponente.component_props.id_draggable = res.data[0].id;
      this.list_components.push(nuevoComponente);
      await this.putDraggable(nuevoComponente); //id_draggable sortuta dagoenean eguneratzen dugu
      this.setTool({});
    },
    click_on_screen() {
      //Chequea si puede colocar un nuevo elemento en la posición
      if (this.canPut && Object.keys(this.getSelectedPage).length != 0) {
        this.add_element();
      }
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
      this.changeHeight(document.documentElement.clientHeight);
      this.setScreenUpdated(!this.getScreenUpdated);
    },
    getHistoricoSemana(){
      let ahora = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')
      let ayer = moment(new Date(Date.now() - 86400000 * 1)).format('YYYY-MM-DD') // menos un día en milis: 24 * 60 * 60 * 1000

      this.getHistoricosConFecha(ayer + ' 06:00:00', ahora)
    },
    getHistoricosConFecha(finicio, ffin){
      let rango = {
        fechaInicio: finicio,
        fechaFin: ffin,
      }
      return this.getHistoricosFecha(rango)
    },
  }, //Fin methods
};
</script>
  
  <style scoped>
.hello {
  margin: 0px;
  height: 100%;
  padding-top: 0%;
  /* border: solid black; */
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
  
<template>
    <v-container>
        <!-- Todos los programas de horno -->
        <v-card>
            <v-container>
                
                <v-card-title>
                    <span class="display-1">Horno</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-subtitle style="font-weight:bold; font-size:17px; text-align:center;" class="mt-6">
                    <span> Estado actual: </span>
                    <span class="mr-8" :style="estado=='Apagado'?'color:#ff3131;':'color:#56f000;'">{{ estado }}</span><br v-if="pantalla <500">
                    <span v-if="estado == 'Encendido'">  Programa nº {{ numPrograma }} </span>

                </v-card-subtitle>
              
                <!--Filtro por fechas-->
                <v-card text-center class="mb-6 mt-2 pr-3" outlined>
                    <v-row class="mt-1 justify-center">
                        <v-col cols="12" md="2" sm="3">
                            <v-card-title> Filtrar:</v-card-title>
                        </v-col>
                        <!-- Fecha de inicio -->
                        <v-col :class="pantalla <500?'':''" cols="6" md="3" sm="3">
                            <v-menu v-model="fechaInicioInput" :close-on-content-click="false" 
                            :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="fechaInicio"
                                        label="Fecha Inicio"
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                </template>
                                <v-date-picker v-model="fechaInicio" @input="fechaInicioInput = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        
                        <!-- Fecha fin -->
                        <v-col cols="6" md="3" sm="3">
                            <v-menu v-model="fechaFinInput" :close-on-content-click="false" 
                            :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="fechaFin"
                                label="Fecha Fin"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="fechaFin" @input="fechaFinInput = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="1" sm="2" class="mr-3"> 
                            <v-btn :class="pantalla < 500? 'ml-3 mb-2':'mt-3'" :block="pantalla < 500" color="primary" @click="filtrar">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                    <!-- Botón de buscar
                        <v-row>
                            <v-col cols="12" class="text-center"> 
                                <v-btn block color="primary" @click="filtrar">
                                    <v-icon>BUSCAR</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>-->
                    <!-- La tabla de los Ciclos -->
                </v-card>
                <v-data-table :items="items_filtrados" :headers="headers" :loading="loading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :search="search" 
                            :footer-props="{
                                'items-per-page-text':'Ciclos por página'
                            }">
                    
                    <!-- Opciones -->
                    <template v-slot:item.options="{ item }">
                        <v-icon @click="showGraphic(item)">
                            mdi-finance
                        </v-icon>

                        <v-icon @click="downloadCiclo(item)">
                            mdi-download
                        </v-icon>
                    </template>
                    
                    <!-- Fecha inicio -->
                    <template v-slot:item.created_at_fecha="{ item }">
                        {{ item.created_at.split(' ')[0] }}
                    </template>

                    <!-- Hora inicio -->
                    <template v-slot:item.created_at_hora="{ item }">
                        {{ item.created_at.split(' ')[1] }}
                    </template>
                </v-data-table>
            </v-container>
        </v-card>
        
        <div class="mt-4 text-subtitle-2"></div>

        <!-- Ciclo horno mostrado en formato gráfico -->
        <v-card v-show="seeGraphic">
            <v-container>
                <v-row class="float-right">
                        <v-btn
                            class="float-right"
                            fab
                            x-small
                        >

                        <!-- Botón para cerrar gráfica -->
                        <v-icon dark @click="closeGraphic()">
                            mdi-close
                        </v-icon>
                    </v-btn>
                    </v-row>

                    <!-- Gráfica lineal -->
                    <v-row>
                        <grafica-lineal :data="data" :title="ciclo_seleccionado.created_at"></grafica-lineal>
                    </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import GraficaLineal from '../plugins/graficolineal/GraficaLineal.vue'
import { ExportToCsv } from 'export-to-csv';

export default {
    name: 'HornoComponent',
    components: {
        GraficaLineal
    },
    data() {
        return {
            items: [],
            items_filtrados: [],
            headers: [
                { text: "ID", value: "id" },
                // { text: "N programa", value: "n_programa" },
                // { text: "Fecha Inicio", value: "created_at" },
                { text: "Fecha Inicio", value: "created_at_fecha" },
                { text: "Hora Inicio", value: "created_at_hora" },
                { text: "Opciones", value: "options" },
            ],
            seeGraphic: false,
            loading: true,
            ciclo_seleccionado: {},
            id_programa: 6,
            ciclo: [],
            labels: [],
            programa: [],
            temp_datos: [],
            teorico: [],
            programa_initial_time: '',
            initial_time: '',
            sortBy:"id",
            sortDesc:true,
            pantalla:null,
            search: '',
            fechaInicioInput: false,
            fechaFinInput: false,
            estado:null,
            fechaInicio: this.getFechaInicial(),
            fechaFin: this.getCurretDate(),
        }
    },
    computed: {
        ...mapState('nodered',['message','subscriptions','client']),
        numPrograma(){
            if(this.items.length > 0){
                let id = Math.max(...this.items.map(o => o.id)) //  buscamos el ultimo programa que se ha guardado en la bd
                return this.items.find(e => e.id == id).n_programa
            }
        },
        data(){
            let datos = {
                labels: this.labels,
                datasets: [
                    {
                        label: 'Datos',
                        borderColor: '#f87979',
                        backgroundColor: '#f87979',
                        data: this.temp_datos,
                        pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
                    }, 
                    {
                        label: 'Teórico',
                        borderColor: '#BDBDBD',
                        backgroundColor: '#BDBDBD',
                        data: this.teorico,
                        pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
                    },
                ]
            }
            return datos
        }
    },
    methods: {
        ...mapActions('api', ['getAllProgramas','getCiclo','getPrograma']),
        ...mapActions('nodered',['doSubscribe']),
        async getTodosProgramas() {
            const data = await this.getAllProgramas()
            let diferentID = []
            data.data.forEach(element => {
                let item = {
                    id: element.id,
                    n_programa: element.n_programa,
                    created_at: moment(element.created_at).format('YYYY-MM-DD HH:mm:ss'),
                    created_at_format: element.created_at,
                }
                //Solo quiero guardar los diferentes id, no queiro los parametros de inicalización
                if (!diferentID.includes(element.id)) {
                    this.items.push(item)
                    diferentID.push(element.id)
                }
            });
            this.items_filtrados = this.items
            this.filtrar()
            this.loading = false
        },

        showGraphic(item){
            this.seeGraphic = true
            this.ciclo_seleccionado = item
            
            //Limpiamos los (posibles) datos anteriores
            this.labels = []
            this.temp_datos = []
            this.teorico = []
            this.programa = []

            //Obtenemos los datos del ciclo
            this.getCicloInicial(item.id)
            this.getProgramaInicial(item.id)
        },
        closeGraphic(){
            this.seeGraphic = false
        },
        async getCicloInicial(id){
            const data = await this.getCiclo(id)
            this.initial_time = new Date(data.data[0].date_time).getTime()
            
            //Limpiamos datos anteriores
            this.ciclo = []

            data.data.forEach(element => {
                this.ciclo.push(element)
                this.labels.push(element.date_time)
                //Guardamos temperatura real
                this.temp_datos.push(Math.round(element.temp))
            });
        },
        async getProgramaInicial(id){
            //Obtener el array de temperaturas y tiempo marcados en el PLC desde BBDD
            const data = await this.getPrograma(id)
            //Guardamos tiempo inicial para ir sumando tiempo conforme a este para mostrarlos en la gráfica posteriormente
            this.programa_initial_time = new Date(data.data[0].created_at).getTime()
            //Insertamos el estado inicial
            this.teorico.push({x: new Date(data.data[0].created_at), y: 20})
            data.data.forEach(element => {
                this.programa.push(element)
                // this.labels_programa.push(this.sumarTiempo(element.hour, element.minute))
                let punto = {
                    x: this.sumarTiempo(element.hour, element.minute),
                    y: element.temp
                }
                //Guardamos hasta que dan aviso de temp=20 que sinifica señal de apagado de horno
                // if(element.temp > 21) this.teorico.push(punto)
                this.teorico.push(punto)
            })
            // this.loading = false
        },
        sumarTiempo(h, min){
            let tiempoTotal = h*60 + min
            //Sumamos el tiempo en milisegundos y guardamos en formato date para que el grafico lo pinte adecuadamente
            let salida = new Date(this.programa_initial_time + tiempoTotal*60*1000)
            //Guardamos en milisegundos para hacer la suma para las siguientes
            this.programa_initial_time = new Date(salida).getTime()
            return salida
        },
        getCurretDate() {
            return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        },
        getFechaInicial(){
            let hoy = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            const semana_pasada = this.diaAnterior(hoy.split('-')[0],hoy.split('-')[1],hoy.split('-')[2])
            return semana_pasada
        },
        diaAnterior(ano, mes, dia){
            let mes_menos_uno = parseInt(mes) - 1 //Internamente empieza desde 0
            const yesterday = new Date(ano, mes_menos_uno.toString(), dia)            
            yesterday.setDate(yesterday.getDate() - 7)
            return this.formatDate(yesterday)
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        formatDate(date) { // yyyy-mm-dd
            return [
            date.getFullYear(),
            this.padTo2Digits(date.getMonth() + 1),
            this.padTo2Digits(date.getDate()),
            ].join('-');
        },
        filtrar(){
            this.items_filtrados = []
            this.items.forEach(element => {
                if(element.created_at.split(' ')[0] >= this.fechaInicio && element.created_at.split(' ')[0] <= this.fechaFin){
                    this.items_filtrados.push(element)
                }
            });
        },
        async downloadCiclo(item){
            console.log('Descargar');
            await this.getCicloInicial(item.id)

            let current_ciclo = JSON.parse(JSON.stringify(this.ciclo));
            console.log(current_ciclo);

            current_ciclo.forEach(element => {
                // console.log(element);
                let prueba = new Date(element.date_time.replace(' ', 'T'));
                delete element.day
                //element.date=moment(prueba).format('YYYY-MM-DD HH:mm:ss')
                element['dia']=moment(prueba).format('YYYY-MM-DD')
                element['hora']=moment(prueba).format('HH:mm:ss')
                delete element['date']
            });

            const options = { 
                fieldSeparator: ';',
                quoteStrings: '"',
                decimalSeparator: ',',
                showLabels: true, 
                showTitle: false, // Sin titulo
                title: "Prueba", // Si ponemos título especificamos aquí
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: item.id
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(current_ciclo);
        },
    },
    created() {
        this.getTodosProgramas()
        this.pantalla = document.documentElement.clientWidth
    },
    watch:{
        'client.connected': function (newVal, oldVal){
            if(newVal == true){
                this.doSubscribe('HornoEncendido')
            }
        },
        message(){
            if(this.message.topic == 'HornoEncendido'){
                if(this.message.message == 1)
                    this.estado = 'Encendido'
                else    
                    this.estado = 'Apagado'
                }

            }

        }
    }
</script>

<style></style>
import KoioteTankCanvas from './KoioteTankCanvas.vue'
import KoioteTankCanvasProperties from './KoioteTankCanvasProperties.vue'
import KoioteTankCanvasProperty from './default.json'

export default {
    install(Vue,options) {
        Vue.component(KoioteTankCanvas.name, KoioteTankCanvas)
        Vue.component(KoioteTankCanvasProperties.name, KoioteTankCanvasProperties)
        Vue.prototype.$KoioteTankCanvasProperties = KoioteTankCanvasProperty


        Vue.mixin({
            mounted() {
            
            }
          });
       }
  };
  

<template lang="es">
        <v-card text>
           <!-- {{component_selected.component_props.customizables}} -->
            
          
             
                    <v-text-field 
                        label="Texto"
                        class="ml-3 mt-6 mr-3"
                        :clearable=true
                        v-model="nuevo_texto"
                        @keyup.enter="cambio_texto"
                        :clear-icon-cb="nuevo_texto == null? changeEditing(true):null"
                        @click="changeEditing(true)"
                        @blur="changeEditing(false)"
                    ></v-text-field>
                     
                    <v-checkbox
                        color="info"
                        hide-details
                        class="opcion ml-3"
                        v-model="component_selected.component_props.customizables.border"
                        label="Añadir borde"
                        >
                    </v-checkbox>
                       
                    <v-list-item v-if="component_selected.component_props.customizables.border">
                        <v-list-item-content>
                            <h4 class="text-center">Grosor del borde</h4>
                            <v-slider 
                           
                            v-model="component_selected.component_props.customizables.border_width"
                            :min="1"
                            :max="10"
                            :step="1"
                            thumb-label
                            thumb-color="black"
                            ></v-slider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <koiote-color-picker-vue titulo="Border color" 
                                :pcolor="component_selected.component_props.customizables.border_color"
                                @event="cambio_border_color"
                              
                                ></koiote-color-picker-vue>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>

                            <koiote-color-picker-vue titulo="Background color" 
                                :pcolor="component_selected.component_props.customizables.background_color"
                                @event="cambio_background_color"
                                
                            >
                            </koiote-color-picker-vue>
                        </v-list-item-content>
                    </v-list-item>
                    
                    <v-list-item>
                        <v-list-item-content>
                            <koiote-color-picker-vue titulo="Foreground color" 
                                :pcolor="component_selected.component_props.customizables.foreground_color"
                                @event="cambio_foreground_color"
                                >
                            </koiote-color-picker-vue>
                        </v-list-item-content>
                    </v-list-item>
                   
                </v-list-item-group>
            
          
        </v-card> 
   
</template>
<script>
import { mapActions, mapState } from 'vuex';
import KoioteColorPickerVue from '@/components/custom/KoioteColorPicker.vue';
export default {
    name: "KoioteLabelProperties",
    components: {
        KoioteColorPickerVue
    },
    computed: {
        ...mapState(['component_selected']),
    },
    methods: {
        ...mapActions(['changeEditing']),
        cambio_background_color(nc) {
            console.log("Cambio en background color")
            this.component_selected.component_props.customizables.background_color = nc
        },

        cambio_foreground_color(nc) {
            this.component_selected.component_props.customizables.foreground_color = nc
        },
        cambio_border_color(nc) {
            this.component_selected.component_props.customizables.border_color = nc
        },
        cambio_texto() {
            this.component_selected.component_props.customizables.text = this.nuevo_texto
            console.log(this.component_selected)
            this.changeEditing(false)
        }
    },
    data() {
        return {
            nuevo_texto: null,
        }
    },
    created() {
        this.nuevo_texto = this.component_selected.component_props.customizables.text

    }




}
</script>
<style lang="">
    
</style>
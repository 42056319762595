<template lang="es">
     <div :class="myProps.customizables.connected? 'conectado completo':'desconectado completo'">
        <p>
            {{myProps.component_name}}
        </p>
        <p>
           <v-icon style="vertical-align: middle" v-if="myProps.customizables.connected" large>mdi-access-point</v-icon>
           <v-icon style="vertical-align: middle" v-else large>mdi-access-point-off</v-icon>
        </p>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: "KoioteOmronConnection",
    props: {
        myProps: Object
    },
    computed: {
        ...mapGetters('nodered',['getConnections']),

    },
    created() {
        if (typeof this.myProps.customizables.connectionID != "number") {
            this.$emit('anadirConexion',this.myProps)
        }
        if (!this.getConnections.find(e => this.myProps.customizables.connectionID == e.id)) {
            this.addConnection({
                id: this.myProps.customizables.connectionID,    // excel-etik hartutako datuak
                ip: this.myProps.customizables.ip,
                variables: this.myProps.customizables.vartable,
                count: parseInt(this.myProps.customizables.count),
                name: this.myProps.component_name,
                type: "FINS Connection"
            })
          
            //Suscribirse 
            //Extraer array de nodenumber de las variables
            let listaDeNodenumbers = this.myProps.customizables.vartable.map(a=>a['node_number'])
            let UniquelistaDeNodenumbers = [...new Set(listaDeNodenumbers)];
            UniquelistaDeNodenumbers.forEach(element => {
                let topic_actual = `PLC${this.myProps.customizables.connectionID}_${element}`;
                this.doSubscribe(topic_actual)
            });
            
        }
    },
    methods: {
        ...mapActions('nodered', ['modifyFlows', 'addConnection','doSubscribe']),
    },

}
</script>
<style>
.completo {
    height: 100%;

}

.desconectado {
    text-align: center;
    border: dotted 2px black;
}

.conectado {
    text-align: center;
    border: dotted 2px black;
    background-color: #4CAF5050;
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

// Axios interceptors for checking session token
import interceptorsSetup from './utils/axiosInterceptors'
interceptorsSetup()

//Libreria necesaria para poder comprobar si dos objetos son iguales
import _ from 'lodash';
import VueResizeObserver from "vue-resize-observer";
Vue.use(VueResizeObserver);


//Elemento draggable, resizeable y girable
import KoioteDraggableResizable from '@/components/generic/KoioteDraggableResizable.vue'
// Vue.component('vue-drag-resize-rotate', KoioteDraggableResizable)
Vue.component('koiote-draggable-reseizeable', KoioteDraggableResizable)

import KoioteBasicElement from '@/components/generic/KoioteBasicElement.vue'
// Vue.component('vue-drag-resize-rotate', KoioteBasicElement)
Vue.component('koiote-basic-element', KoioteBasicElement)


//Para la barra de propiedades y herramientas
import VueDragResize from 'vue-drag-resize';
Vue.component('VueDragResize',VueDragResize)

import vuetify from './plugins/vuetify'


/////////////////////////// metodos generales ////////////////////////////////////
import {globalTransformBufferToFloat,globalTransformBufferToBits,splitVariable} from '@/helpers/dataMethods.js'
Vue.prototype.$globalTransformBufferToFloat=globalTransformBufferToFloat
Vue.prototype.$globalTransformBufferToBits=globalTransformBufferToBits
Vue.prototype.$splitVariable=splitVariable

//////////////////////////////////////////////////////////////////////////////////


import KoioteLabel from "./plugins/label/KoioteLabelPlugin.js"
Vue.use(KoioteLabel)
import KoioteTank from "./plugins/tank/KoioteTankPlugin.js"
Vue.use(KoioteTank)
import KoioteTankCanvas from "./plugins/tank_canvas/KoioteTankCanvasPlugin.js"
Vue.use(KoioteTankCanvas)
import KoioteOmronConnection from "./plugins/connection omron/KoioteOmronConnectionPlugin.js"
Vue.use(KoioteOmronConnection)
import KoioteValueLabelWrite from "./plugins/value_label_write/KoioteValueLabelWritePlugin.js"
Vue.use(KoioteValueLabelWrite)
import KoioteIbhLinkConnection from "./plugins/connection ibh_link/KoioteIBHLinkConnectionPlugin.js"
Vue.use(KoioteIbhLinkConnection)
import KoioteSnap7Connection from "./plugins/connection snap7/KoioteSnap7ConnectionPlugin.js"
Vue.use(KoioteSnap7Connection)
import KoioteNavigation from "./plugins/navigation/KoioteNavigationPlugin.js"
Vue.use(KoioteNavigation)
import KoioteValueLabel from "./plugins/value_label/KoioteValueLabelPlugin.js"
Vue.use(KoioteValueLabel)
import KoioteSelector from "./plugins/selector/KoioteSelectorPlugin.js"
Vue.use(KoioteSelector)
import KoioteSetReset from "./plugins/set_reset/KoioteSetResetPlugin.js"
Vue.use(KoioteSetReset)
import KoioteSilo1 from "./plugins/silo1/KoioteSilo1Plugin.js"
Vue.use(KoioteSilo1)
// import KoioteImageSwitch from "./plugins-unused/image-switch/KoioteImageSwitchPlugin.js"
// Vue.use(KoioteImageSwitch)
import KoioteImageSwitchHover from "./plugins/image-switch-hover/KoioteImageSwitchHoverPlugin.js"
Vue.use(KoioteImageSwitchHover)
import KoioteMomentaneo from "./plugins/momentaneo/KoioteMomentaneoPlugin.js"
Vue.use(KoioteMomentaneo)
import KoioteGraficoQuesito from "./plugins/graficoquesito/KoioteGraficoQuesitoPlugin.js"
Vue.use(KoioteGraficoQuesito)
import KoioteGraficoLineal from "./plugins/graficolineal/KoioteGraficoLinealPlugin.js"
Vue.use(KoioteGraficoLineal)
import KoioteGraficoZonas from "./plugins/graficozonas/KoioteGraficoZonasPlugin.js"
Vue.use(KoioteGraficoZonas)
import KoioteImageSwitchCanvas from "./plugins/image-switch-canvas/KoioteImageSwitchCanvasPlugin.js"
Vue.use(KoioteImageSwitchCanvas)

import KoioteSiloCanvas from "./plugins/silo_canvas/KoioteSiloCanvasPlugin.js"
Vue.use(KoioteSiloCanvas)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

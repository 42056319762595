import KoioteMomentaneo from './KoioteMomentaneo.vue'
import KoioteMomentaneoProperties from './KoioteMomentaneoProperties.vue'
import KoioteMomentaneo1Property from './default.json'


/// componente lectura excel
export default {
	install(Vue, options) {
		Vue.component(KoioteMomentaneo.name, KoioteMomentaneo)
		Vue.component(KoioteMomentaneoProperties.name, KoioteMomentaneoProperties)
		Vue.prototype.$KoioteMomentaneo1Property = KoioteMomentaneo1Property
		// para interpretar las variabes que llegan de nodered

		Vue.mixin({
			mounted() {
				// console.log('Montadito!');
			}
		});
	}
};


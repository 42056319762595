<template>
  <LineChartGenerator
    :options="chartOptions"
    :data="data"
    :styles="styles"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs'
// import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-moment'

import {
  Chart as ChartJS,
  Title,
  Tooltip,

  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  TimeScale
} from 'chart.js'

ChartJS.register(  Title,
  Tooltip,
  LineElement,
  LinearScale,
  PointElement,
  // annotationPlugin,
  TimeScale,
  CategoryScale)

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Inserte el título'
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  computed: {
    chartOptions() {
      let options = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 0.5,
        plugins:{
          title: {
            display: true,
            text: this.title,
          },
          datalabels: {
            display: false,
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              displayFormats: {
                quarter: 'MMM YYYY'
              }
            }
          }
        }
      }
      return options
    }
  },
}
</script>

<template lang="">
  <div class="fondo_componente">
    <div v-if="myProps.customizables.value > 0">
      <!-- <div class="componente_interno" :style="{ height:alturaCalculada,  top:topCalculado, 'background-color':bgColor }" -->
        <div class="componente_interno" :style="{ 'height':alturaCalculada,  'top':topCalculado,  'background': createBackgroundString  }"
      >
      <div v-if="myProps.customizables.show_value">
        {{formated_value}}
      </div>
      <!-- <span :class="myProps['posicion_texto']">{{myProps['titulo']}} {{myProps['nombre']}}</span> -->
    </div>
  </div>
</div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name:"KoioteTank",
   props:{
      myProps:Object
   },

   computed: {
    ...mapState('nodered',['message','ListOfmessages']),
    ...mapGetters('nodered',['getConnections']),
    bufferPos(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'BufferPos'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    dataType(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'VarType'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    myPlc(){
      if(this.myProps.customizables.topic != ""){
        return this.getConnections.find(e => e.id == this.myProps.customizables.plc)
      }
    },
    createBackgroundString() {
        let backString=`linear-gradient(90deg`
        if(this.myProps.customizables['colores']){
            this.myProps.customizables['colores'].forEach(element => {
                backString= backString + `, ${element['color']} ${element['porcentaje']}`
            });
        }
        return backString
    },
    value(){
      let miMensaje = this.ListOfmessages.find(a=>a.topic==this.myProps.customizables.topic)
      //return myArray
      // console.log("this.myProps.customizables.plc")
      // console.log(this.myProps.customizables.plc)
      if(miMensaje){
        if(this.myPlc && Object.keys(this.myPlc).length > 0 ){
          let functionName = this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
          functionName = 'readValueFrom'+functionName
          return this[functionName](miMensaje,this.myProps.customizables, this.bufferPos, this.dataType, this.myPlc)
        }
      }else{
        // console.log('No hay mensaje de ese topic :(');
      }
    },
    formated_value(){
        return this.var_type == 'bit' ? parseInt(this.myProps.customizables.value):
        (parseFloat(this.myProps.customizables.value)*(this.myProps.customizables.scale)).toFixed(this.myProps.customizables.decimals)
    },
    valorMaximo(){
        return this.myProps.customizables['valorMaximo']
    },

    topCalculado(){
      let porcentaje = this.value/this.valorMaximo*100
      return `${100-porcentaje}%`
    },
    alturaCalculada(){
      let porcentaje = this.value/this.valorMaximo*100
      return `${porcentaje}%`
    },
   }, 
   watch:{
    value(){
      if(this.value != undefined){
        this.$emit('cambioValor', {id: this.myProps.id_draggable, value: this.value})
      }
    }
   }
}
</script>
<style scoped>
.fondo_componente{
  z-index: 10;
  background: linear-gradient(90deg, rgb(153, 153, 158) 0%, rgb(222, 226, 228) 51%, rgb(107, 112, 113) 100%) !important;
  height: 100%;
  position: absolute;
  text-align:center;
  width: 100%;
  left: 0%;
  top: 0%;
  border: 1px black;
}
.componente_interno{
  position: absolute;
  color:aliceblue;
  text-align:center;
  background: rgb(2,0,36);
  /* background-color: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgb(33, 74, 235) 51%, rgba(2,35,42,1) 100%); */
  left: 0%;
  width: 100%;
}
</style>
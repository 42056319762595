import KoioteSilo1 from './KoioteSilo1.vue'
import KoioteSilo1Properties from './KoioteSilo1Properties.vue'
import KoioteSilo1Property from './default.json'

export default {
    install(Vue,options) {
        Vue.component(KoioteSilo1.name, KoioteSilo1)
        Vue.component(KoioteSilo1Properties.name, KoioteSilo1Properties)
        Vue.prototype.$TankProperties = KoioteSilo1Property


        Vue.mixin({
            mounted() {
            
            }
          });
       }
  };
  

<template>
    <div>
        <UsersComponentVue></UsersComponentVue>
    </div>
</template>
<script>
import UsersComponentVue from '../components/UsersComponent.vue';
export default {
    name: 'UsersView',
    components: {
        UsersComponentVue
    },
    
}

</script>
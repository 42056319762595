<template lang="es">
    <!-- este es un label -->

    <div :style="myStyle" class="completo">
        <!-- {{myProps}} -->
        {{myProps.customizables.text}}

    </div>
    <!-- Fin del label -->
</template>
<script>
import { mapState } from 'vuex';

export default {
    name:"KoioteLabel",
    props:{
        myProps:Object,
        w:Number,
        h:Number
    },
    computed: {
        ...mapState(['component_selected']),
        ancho(){
            let minimo = Math.min(this.h, this.w)
            if(document.documentElement.clientWidth >= 700)
                return minimo/2.5
            else
                return minimo/4
        },
        myStyle()
        {
            let style = {backgroundColor:this.myProps.customizables.background_color, 
                         color:this.myProps.customizables.foreground_color,
                          fontSize:this.ancho+'px'
                        }
            if(this.myProps.customizables.border){
                style.border = `${this.myProps.customizables.border_width}px solid ${this.myProps.customizables.border_color}`
            }

            return style
           
        }
    },
   

}
</script>
<style>
.labelCompleta{
    height:auto;
    width:auto;
}
.completo{
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}
    
</style>
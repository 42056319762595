<template lang="es">
     <div>
        <!-- {{ myProps.customizables.text }} {{ myProps.customizables.data_color }} -->
        <!-- {{temperatura}} -->
        <grafica-lineal
            :data="data"
            :title="myProps.customizables.text"
            v-if="!loading"
        >
        </grafica-lineal>
        <v-overlay :value="loading"> <!--Es absolute por defecto, no hace falta ponerlo-->
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import GraficaLineal from './GraficaLineal.vue';
import { mapGetters, mapActions, mapState } from "vuex";
import moment from 'moment';

export default {
    name: "KoioteGraficoLineal",
    props: {
        myProps: Object
    },
    components: {
        GraficaLineal,
    },
    data() {
        return {
            ciclo: [],
            labels: [],
            temp_datos: [],
            initial_time: '',
            id_programa: 6,
            programa: [],
            programa_initial_time: '',
            labels_programa: [],
            teorico: [],
            loading: true,
        }
    },
    created() {
        this.doSubscribe('HornoEncendido')
        this.doSubscribe('HornoTemperatura')
        this.doSubscribe('HornoPrograma')
        this.getCicloInicial(this.id_programa)
        this.getProgramaInicial(this.id_programa)

        console.log('Programa');
        console.log(this.programa);
        console.log('Teorico');
        console.log(this.teorico);
    },
    methods: {
        ...mapActions('nodered',['doSubscribe']),
        ...mapActions('api',['getCiclo','getPrograma']),
        async getProgramaInicial(id){
            //Obtener el array de temperaturas y tiempo marcados en el PLC desde BBDD
            const data = await this.getPrograma(id)
            //Guardamos tiempo inicial para ir sumando tiempo conforme a este para mostrarlos en la gráfica posteriormente
            this.programa_initial_time = new Date(data.data[0].created_at).getTime()
            //Insertamos el estado inicial
            this.teorico.push({x: new Date(data.data[0].created_at), y: 20})
            data.data.forEach(element => {
                this.programa.push(element)
                // this.labels_programa.push(this.sumarTiempo(element.hour, element.minute))
                let punto = {
                    x: this.sumarTiempo(element.hour, element.minute),
                    y: element.temp
                }
                //Guardamos hasta que dan aviso de temp=20 que sinifica señal de apagado de horno
                if(element.temp > 21) this.teorico.push(punto)
            })
            this.loading = false
        },
        sumarTiempo(h, min){
            let tiempoTotal = h*60 + min
            //Sumamos el tiempo en milisegundos y guardamos en formato date para que el grafico lo pinte adecuadamente
            let salida = new Date(this.programa_initial_time + tiempoTotal*60*1000)
            //Guardamos en milisegundos para hacer la suma para las siguientes
            this.programa_initial_time = new Date(salida).getTime()
            return salida
        },
        async getCicloInicial(id){
            const data = await this.getCiclo(id)
            this.initial_time = new Date(data.data[0].date_time).getTime()

            data.data.forEach(element => {
                this.ciclo.push(element)
                this.labels.push(element.date_time)
                //Guardamos temperatura real
                this.temp_datos.push(Math.round(element.temp))
            });
        },
    },
    computed: {
        ...mapState('nodered',['message']),
        estado_horno(){
            this.ibhLinkReadEstadoHorno()
        },
        num_programa(){
            let programa = this.ibhLinkReadPrograma()
            if(Object.keys(programa).length > 0 && programa.numPrograma != null){
                return programa.numPrograma //mirar si se llama asi la variable
            }
        },
        datos_programa(){
            let programa = this.ibhLinkReadPrograma()
            if(Object.keys(programa).length > 0 && programa.Puntos.length > 0){
                return programa.Puntos //mirar si se llama asi la variable
            }
        },
        temperatura(){ // la temperatura actual del horno
            return this.ibhLinkReadTemperatura()
        },
        data() {

            let datos = {
                // labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August' ],
                labels: this.labels,
                datasets: [
                    {
                        label: 'Datos',
                        borderColor: this.myProps.customizables.data_color,
                        backgroundColor: this.myProps.customizables.data_color,
                        data: this.temp_datos,
                        pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
                    }, 
                    {
                        label: 'Teórico',
                        borderColor: this.myProps.customizables.programa_border_color,
                        backgroundColor: this.myProps.customizables.programa_border_color,
                        // data: [30, 35, 30, 40, 50, 60, 50, 60],
                        // data: this.temp_teorico,
                        data: this.teorico,
                        // data: [
                        //     {
                        //         x: '2023-06-20T11:05:23.111Z',
                        //         y: 100,
                        //     },{
                        //         x: '2023-06-20T15:05:23.111Z',
                        //         y: 600,
                        //     },{
                        //         x: '2023-06-20T20:05:23.111Z',
                        //         y: 900,
                        //     },
                        // ],
                        pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
                    }
                ]
            }
            return datos
        },
    },
}
</script>

<style>
</style>
<template>
  <div>
    <v-select :items="icons"
     :label="component_selected.component_props.customizables.next_icon ? icons.find(e=>e.icon == component_selected.component_props.customizables.next_icon ).name:'Seleccionar'"
     item-text="name"
     item-value="icon"
     @change="selectedImage">
      <template v-slot:item="{ item }">
          <img width="20" height="20" :src="require(`./assets/${item.icon}`)">
          {{ item.name }}
      </template>
      
    </v-select>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {

  data() {
    return {
      icons:[
        {name:'Casa', icon:'house-icon.png'},
        {name:'Ajustes', icon:'tools-icon.png'},
        {name:'Salir', icon:'logout-icon.png'},
        {name:'Advertencia', icon:'warning-icon.png'},
        {name:'Flecha Izquierda', icon:'left-arrow-icon.png'},
        {name:'Flecha Derecha', icon:'right-arrow-icon.png'},

      ],
    };
  },
  computed:{
    ...mapState(['component_selected'])
  },
  methods: {
    selectedImage(image){
      if(image){
        this.component_selected.component_props.customizables.next_icon = image
        image = {}
      }

    }
  },
  created(){
    // fs.readdir(testFolder, (err, files) => {
    //   files.forEach(file => {
    //     console.log(file);
    //   });
    // });
  }
}
</script>
<style lang="">
  
</style>
<script>
import { mapActions } from "vuex";
export default {
  data(){
    return{
    }
  },
  methods: {
    ...mapActions(['setImageToSelectedPage']),
    onFileChange(payload) {
      //const file = payload.target.files[0]; // use it in case of normal HTML input
      const file = payload; // in case vuetify file input
      if (file) {
     
        let imagePreviewURL = URL.createObjectURL(file);
        this.setImageToSelectedPage(imagePreviewURL)
        URL.revokeObjectURL(file); // free memory
      } else {
          this.setImageToSelectedPage(null)
      }
    },
    

      
    
  },
}
</script>
<template>
    <div>
        <horno-component></horno-component>
    </div>
</template>

<script>
import HornoComponent from '@/components/HornoComponent.vue'

export default {
    name: 'Horno',
    components: {
        HornoComponent
    }
}
</script>

<style>
</style>
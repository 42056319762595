<template lang="">
    <div  class="footer">
        <!-- Koiote Inteligencia Industrial -->
    </div>
</template>
<script>
export default {
    
}
</script>
<style>
    .footer {
        text-align:center;
        position: fixed;
        bottom: 0;
        z-index:300;
        left: 0;
        width: 100%;
    /* Add necessary styles to the footer */
    }
</style>
import KoioteValueLabel from './KoioteValueLabel.vue'
import KoioteValueLabelProperties from './KoioteValueLabelProperties.vue'
import KoioteValueLabel1Property from './default.json'

export default {
    install(Vue, options) {
        Vue.component(KoioteValueLabel.name, KoioteValueLabel)
        Vue.component(KoioteValueLabelProperties.name, KoioteValueLabelProperties)
        Vue.prototype.$ValueLabelProperties = KoioteValueLabel1Property

        Vue.mixin({
            mounted() {
                // console.log('Montadito!');
            }
        });
    }
};


<template lang="">
  <div class="ml-2 mr-2">
    <!-- Change component name -->
    <!-- Change component from square to round -->
    <v-checkbox 
    color="info"
    hide-details
    class="opcion"
    v-model="component_selected.component_props.customizables.round"
    label="Formato circular"
    >
  </v-checkbox>
  <h4 class="text-center mt-3">Nombre</h4>
    <v-text-field
      class="ml-2 mr-2"
      v-model="component_selected.component_props.component_name"
      single-line
      clearable
      @keyup.enter="changeEditing(false)"
      :clear-icon-cb="component_selected.component_props.component_name == null? changeEditing(true):null"
      @click="changeEditing(true)"
      @blur="changeEditing(false)"
    />
  
    <!-- Add border to the nav -->
    <v-checkbox v-if="component_selected.component_props.customizables.nav_type=='Texto'"
      color="info"
      hide-details
      class="opcion"
      v-model="component_selected.component_props.customizables.border"
      label="Añadir borde"
      >
    </v-checkbox>
    <!-- Change border width -->
    <h4 v-if="component_selected.component_props.customizables.border" class="text-center mt-3">Grosor del borde</h4>
    <v-slider v-if="component_selected.component_props.customizables.border"
      v-model="component_selected.component_props.customizables.border_width"
      :min="1"
      :max="10"
      :step="1"
      thumb-label
      thumb-color="black"
    ></v-slider>

    <!-- Select border color -->
    <koiote-color-picker-vue v-if="component_selected.component_props.customizables.border" titulo="Color del Borde" 
      :pcolor="component_selected.component_props.customizables.border_color"
      @event="cambio_border_color"></koiote-color-picker-vue>

    <!-- Select where is going to redirect the nav component -->
    <h4 class="text-center mt-3">Destino</h4>
    <v-select
      v-model="next"
      :items="getPages"
      item-text='name'
      return-object
      :label="Object.keys(next).length >0 ? next.name : 'Seleccionar'"
      solo
    ></v-select>
    <!-- Select text or icon navigation -->
    <h4 class="text-center mt-3">Tipo</h4>
    <v-radio-group v-model="component_selected.component_props.customizables.nav_type"
    >
      <v-radio v-for="(item, index) in  ['Texto','Icono','Imagen']" 
          :key="index"
          :label="item"
          :value="item"
          color="info"
        ></v-radio>
    </v-radio-group>

    <!-- texto -->
    <div v-if="component_selected.component_props.customizables.nav_type=='Texto'">
      <h4 class="text-center mt-3">Texto</h4>
      <v-text-field
        class="ml-2 mr-2"
        v-model="component_selected.component_props.customizables.nav_text"
        single-line
        clearable
        @keyup.enter="changeEditing(false)"
        :clear-icon-cb="component_selected.component_props.customizables.nav_text == null? changeEditing(true):null"
        @click="changeEditing(true)"
        @blur="changeEditing(false)"
      />

      <koiote-color-picker-vue titulo="Background color" 
      :pcolor="component_selected.component_props.customizables.background_color"
      @event="cambio_background_color"></koiote-color-picker-vue>

      <koiote-color-picker-vue titulo="Foreground color" 
      :pcolor="component_selected.component_props.customizables.foreground_color"
      @event="cambio_foreground_color"></koiote-color-picker-vue>
    </div>

    <!-- icono -->
    <div v-else-if="component_selected.component_props.customizables.nav_type=='Icono'">
      <h4 class="text-center mt-3">Icono</h4>
      <select-icon></select-icon>
    </div>
   
     <!-- Imágen -->
     <div v-else-if="component_selected.component_props.customizables.nav_type=='Imagen'">
      <h4 class="text-center mt-3">Imagen</h4>
      <v-file-input             
        accept="image/*"
        label="Imagen"
        dense
        background-color="white"
        ref="refFileInput"
        @change="fileChangedInItem"
      ></v-file-input>
    </div>


  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import KoioteColorPickerVue from '@/components/custom/KoioteColorPicker.vue';
export default {
  name: "KoioteNavigationProperties",
  data() {
    return {
      next:{},
      file:null
     
    }

  },
 
  mounted() {
    if(this.component_selected.component_props.customizables.next_page != ""){
      this.next = this.getPages.find(e => e.id == this.component_selected.component_props.customizables.next_page)
    }
  },
  components: {
    KoioteColorPickerVue,
    SelectIcon: () => import('./SelectIcon.vue'),
  },
  methods: {
    ...mapActions(['changeEditing']),
    cambio_background_color(nc) {
      this.component_selected.component_props.customizables.background_color = nc
    },

    cambio_foreground_color(nc){
        this.component_selected.component_props.customizables.foreground_color = nc
    },
    cambio_border_color(nc){
        this.component_selected.component_props.customizables.border_color = nc
    },
    fileChangedInItem(e){
      let it = this.component_selected
      console.log(e)
      if(e != null){

        const reader = new FileReader();
        reader.onload = (event) => {
          //si ya existe imagen para ese valor, la reemplaza
          this.component_selected.component_props.customizables.image = event.target.result;
        };

        reader.readAsDataURL(e);
      }else
      {   // si se borra la imagen
        this.component_selected.component_props.customizables.image = null

      }
       
    },
  },
  computed: {
    ...mapGetters(['getPages']),
    ...mapState(['component_selected'])

  }, 
  watch:{
    next(){
      if(Object.keys(this.next).length > 0 && this.next.id != this.component_selected.component_props.customizables.next_page){
        this.component_selected.component_props.customizables.next_page = this.next.id
      }
    }
  }
}
</script>

<style>
.opcion {
  margin-top: 15%;
  margin-bottom: 15%;
}
</style>
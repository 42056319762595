import KoioteGraficoLineal from './KoioteGraficoLineal.vue'
import GraficaLineal from './GraficaLineal.vue'
import KoioteGraficoLinealProperties from './KoioteGraficoLinealProperties.vue'
import KoioteGraficoLineal1Property from './default.json'

import { Bar } from 'vue-chartjs'

export default {
    install(Vue,options) {
      Vue.component(GraficaLineal.name, GraficaLineal)
      Vue.component(KoioteGraficoLineal.name, KoioteGraficoLineal)
      Vue.component(KoioteGraficoLinealProperties.name, KoioteGraficoLinealProperties)
      Vue.prototype.$GraficoLinealProperties = KoioteGraficoLineal1Property

      Vue.mixin({
          mounted() {
            // console.log('Montadito!');
          }
        });
      }
  };
  

<template lang="es">
  <v-card text>
    <v-list>
      <v-list-item-group>

        <!-- Texto -->
        <v-list-item>
          <v-list-item-content>
            <v-text-field 
              label="Texto"
              :clearable=true
              v-model="nuevo_texto"
              @keyup.enter="cambio_texto"
              :clear-icon-cb="nuevo_texto == null? changeEditing(true):null"
              @click="changeEditing(true)"
              @blur="changeEditing(false)"
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>

        <!-- programa_border_color -->
        <v-list-item>
          <v-list-item-content>
            <koiote-color-picker-vue titulo="Color del programa" 
              :pcolor="nuevo_programa_border"
              @event="cambio_programa_color"
              @blur="changeEditing(false)"
              >
            </koiote-color-picker-vue>
          </v-list-item-content>
        </v-list-item>
        
        <!-- data_color -->
        <v-list-item>
          <v-list-item-content>
            <koiote-color-picker-vue titulo="Color del datos" 
              :pcolor="nuevo_data_color"
              @event="cambio_data_color"
              @blur="changeEditing(false)"
              >
            </koiote-color-picker-vue>
          </v-list-item-content>
        </v-list-item>

      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import KoioteColorPickerVue from '@/components/custom/KoioteColorPicker.vue';
export default {
  name: "KoioteGraficoZonasProperties",
  components: {
    KoioteColorPickerVue
  },
  data() {
    return {
      nuevo_objeto: null,
      nuevo_texto: null,
      nuevo_programa_border: null,
      nuevo_data_color: null,
    }
  },
  computed: {
    ...mapState(['component_selected']),
  },
  watch: {
    component_selected: {
      deep: true,
      handler() {
        console.log("handler")
        this.nuevo_texto = this.component_selected.component_props.customizables.text
        this.nuevo_programa_border = this.component_selected.component_props.customizables.programa_border_color
        this.nuevo_data_color = this.component_selected.component_props.customizables.data_color
      }
    },
  },
  methods: {
    ...mapActions(['changeEditing']),
    cambio_texto() {
      this.component_selected.component_props.customizables.text = this.nuevo_texto
      this.changeEditing(false)
      console.log(this.component_selected)
    },
    cambio_programa_color(nc){
      this.component_selected.component_props.customizables.programa_border_color = nc
    },
    cambio_data_color(nc){
      this.component_selected.component_props.customizables.data_color = nc
    },
  },
  created() {
    this.nuevo_objeto = JSON.parse(JSON.stringify(this.component_selected.component_props));
    this.nuevo_texto = this.component_selected.component_props.customizables.text
    this.nuevo_programa_border = this.component_selected.component_props.customizables.programa_border_color
    this.nuevo_data_color = this.component_selected.component_props.customizables.data_color
  },
}
</script>

<style lang="">
</style>
<template lang="es">
  <v-row justify="center" align="center">
      <v-col class="shrink" style="min-width: 220px;">
          <small>{{titulo}}</small>
          <v-text-field v-model="color" @click="changeEditing(true)" @blur="changeEditing(false)" @keyup.enter="changeEditing(false)" hide-details class="ma-0 pa-0" solo>
              
              <template v-slot:append>
                  <v-menu v-model="menu"   top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                          <div @click="changeEditing(true)" :style="swatchStyle" v-on="on" />
                      </template>
                      <v-card>
                          <v-card-text class="pa-0">
                              <v-color-picker v-model="color" flat />
                          </v-card-text>
                      </v-card>
                  </v-menu>
              </template>
          </v-text-field>
      </v-col>
  </v-row>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  data() {
      return {
          color: '',
          mask: '!#XXXXXXXX',
      menu: false,
      }
  },
  props:{
      pcolor:String,
      titulo:String,
  },
  created() {
      this.color=this.pcolor
  },
  methods: {
    ...mapActions(['changeEditing'])
  },
  computed: {
      swatchStyle() {
          const { color, menu } = this
          return {
              backgroundColor: color,
              cursor: 'pointer',
              height: '30px',
              width: '30px',
              borderRadius: menu ? '50%' : '4px',
              transition: 'border-radius 200ms ease-in-out'
          }
      },
  },
  watch:{
      pcolor(){
          this.color=this.pcolor;
      },
      color(a,b){
          if(typeof a !== 'undefined'){
              if(a!=b){
                  
                  this.$emit('event', a)
              }
          } 
      }
  }
}
</script>
<style lang="">
  
</style>
<template lang="es">
     <div :style="myStyle" class="completo">
        <p :style="valueStyle" id="value1-value-label">
          {{value}}
        
       </p>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  name: "KoioteValueLabel",
  props: {
    myProps: Object,
    h:Number,
    w:Number
  },
  computed: {
    ...mapState('nodered',['message','ListOfmessages']),
    ...mapGetters('nodered',['getConnections']),
    bufferPos(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'BufferPos'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }else return 'infinitisimo'
    },
    dataType(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'VarType'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    myPlc(){
      if(this.myProps.customizables.topic != ""){
        return this.getConnections.find(e => e.id == this.myProps.customizables.plc)
      }
    },
    ancho(){
        let minimo = Math.min(this.h, this.w)
        if(document.documentElement.clientWidth >= 700)
            return minimo/2
        else
            return minimo/4
    },
    myStyle() {
      return {
        backgroundColor: this.myProps.customizables.background_color,
        color: this.myProps.customizables.foreground_color,
        border: "1px solid " + this.myProps.customizables.border_color,
        fontSize: this.ancho+'px'
      };
    },
    valueStyle() {
      return { // automatico desde el css para que no haya que modificar todos manualmente
        // fontSize: this.myProps.customizables.font_size + "rem",
      };
    },
    value(){
      let miMensaje = this.ListOfmessages.find(a=>a.topic==this.myProps.customizables.topic)
      if(miMensaje){
        if(this.myPlc && Object.keys(this.myPlc).length > 0 ){
          let functionName = this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
          functionName = 'readValueFrom'+functionName
          return this[functionName](miMensaje,this.myProps.customizables, this.bufferPos, this.dataType, this.myPlc)
        }
      }else{
        // console.log('No hay mensaje de ese topic :(');
      }
    },

  },
  watch:{

    value(){
      if(this.value != undefined){
        this.$emit('cambioValor', {id: this.myProps.id_draggable, value: this.value})
      }
    },
   }
};
</script>
<style>
#value1-value-label {
  margin: 0;
  padding: 0;
}


/* @media screen and (max-width: 600px) {
  .completo {
    position:relative;
    width:100%;
  }
} */

/* @media screen and (min-width: 1200px) {
  .completo {
    font-size: 6em; 
  }
} */

</style>
<template lang="es">
  <div id="screen" >
      <v-overlay :value="overlay" :opacity="1" >
        <v-progress-circular indeterminate size="100">
          Cargando...
        </v-progress-circular>
      </v-overlay>

      <!-- We show the draggable only if its in this page and if we are executing we show it only if it can appear in this mode -->
      <!-- <vue-drag-resize-rotate class="draggable-resize-rotate"  v-for="(item, index ) in list_components" :key="index" -->
      <div v-if="!getIsPlayMode"  @touchend="comparaCoordenadas()" @mouseup="comparaCoordenadas()" >
        <koiote-draggable-reseizeable class="draggable-resize-rotate"  v-for="(item, index ) in list_components" :key="index"
        v-show="Object.keys(getSelectedPage).length != 0 && item.id_page == getSelectedPage.id && (!getIsPlayMode || item.component_props.show_in_exec)"
        @dragstop="dragstop"
        @drag="draggeando"
        @rotatestop="rotatestop"
        @resizestop="resizestop"
        

        :id="item.id"
        :initial_screen_size="item.component_props.customizables.initial_screen_size"
        :resizable=!getIsPlayMode :rotatable=!getIsPlayMode :draggable=!getIsPlayMode :selectable=!getIsPlayMode
        :x="item.x" :y="item.y" :w="item.w" :h="item.h" :angle="item.angle" @deselect="desactivado" :selected="item.selected"
        :item_actual="item"
        :zindex="item.z"
        @select="activado(item)" >
      
            <component
                :is="item.component_props.component_type" :w="item.w" :h="item.h"
                :myProps="item.component_props" @cambioValor="cambiarValor" :executable="getIsPlayMode" @anadirConexion="asignarConnectionId">
            </component>
      </koiote-draggable-reseizeable>
    </div>
    <div v-else class="prueba_exec">
    
      <koiote-basic-element v-for="(item, index) in list_components" :key="index"
        :elemento="item"
        v-show="Object.keys(getSelectedPage).length != 0 && item.id_page == getSelectedPage.id &&  item.component_props.show_in_exec"
      >
        <component
          :is="item.component_props.component_type" :w="item.w" :h="item.h"
          :myProps="item.component_props" @cambioValor="cambiarValor" :executable="getIsPlayMode">
        </component>
      </koiote-basic-element>

    </div>
    <!-- fin de los elementos -->
    <div v-if="getSelectedPage != null" class="imagen" :style="style">
      
      <img id="koiote_screen_background_image" class="fondo_exec" draggable="false" v-if="getSelectedPage.image != null && typeof getSelectedPage.image =='string'"
      :src="getSelectedPage.image"/>
      <img id="koiote_screen_background_image" class="fondo_exec" draggable="false" v-else
      src="@/assets/base.webp"/>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  
  props: {
    // menus_hided:Boolean,
    canDeselect: Boolean,
    overlay:Boolean,
  },
  computed: {
    ...mapState(['list_components', 'tool', 'component_selected', 'component_selected_original', 'selected_page', 'mouseY', 'editing', 'modal_activo']),
    ...mapGetters(['getSelectedPage', 'getIsPlayMode', 'getIsAdmin']),
    ...mapGetters('nodered', ['getConnections']),
    style() {
      return {
        'z-index': this.getSelectedPage['z_index']
      }
    }

  },
  methods: {
    ...mapActions(['setShowNavigation', 'setComponentSelected', 'deleteComponentSelected', 'updatePositionOrSize', 'addModifiedDraggable', 'pushToModified']),
    ...mapActions('api', ['postDraggable', 'putDraggable']),

    draggeando() {
      this.draggeado = true
    },
    comparaCoordenadas() {
      if (!this.draggeado)
        this.setShowNavigation(true)
    },
    asignarConnectionId(item) {
      if (this.getConnections.length == 0) {
        item.customizables.connectionID = 0
      } else {
        item.customizables.connectionID = (Math.max(...this.getConnections.map(e => parseInt(e.id))) + 1)
      }
      let draggable = this.list_components.find(e => e.id == item.id_draggable)
      console.log("ASIGNADO AL DRAGGANLE: " + draggable.id);
      this.pushToModified(draggable)
    },
    estilo(item) {
      item.real_x = item.x - item.w / 2
      item.real_y = item.y - item.h / 2
      item.porcentaje_x = 100 * (item.x - item.w / 2) / item.component_props.customizables.initial_screen_size.width
      item.porcentaje_y = 100 * (item.y - item.h / 2) / item.component_props.customizables.initial_screen_size.height
      item.porcentaje_ancho = 100 * item.w / item.component_props.customizables.initial_screen_size.width
      item.porcentaje_alto = 100 * item.h / item.component_props.customizables.initial_screen_size.height

      let style = `{width: ${item.porcentaje_ancho}%, height:${item.porcentaje_alto}%, position: 'absolute', top:${item.porcentaje_y}%, left:${item.porcentaje_x}%}`
      return style;
    },

    cambiarValor(data) {
      let component = this.list_components.find(e => e.id == data.id)
      if (component != undefined) {
        if (Object.keys(component).length > 0) {
          component.component_props.customizables.value = data.value
        }
      }
    },
    activado(elemento) {

      this.setComponentSelected(elemento.id)
    },
    desactivado() {
      if (this.canDeselect && !this.editing && !this.modal_activo) {
        this.deleteComponentSelected()
      }
    },

    dragstop(payload) {
      this.draggeado = false
      if (this.component_selected === null || payload.id != this.component_selected.id) {
        const index = this.list_components.findIndex(object => {
          return object.id === payload.id;
        });
        this.list_components[index].y = payload.y
        this.list_components[index].x = payload.x
      }
      else {
        this.component_selected.x = payload.x
        this.component_selected.y = payload.y
      }
      this.component_selected.real_x = payload.x - payload.w / 2
      this.component_selected.real_y = payload.y - payload.h / 2

      this.component_selected.porcentaje_x = 100 * (payload.x - payload.w / 2) / this.component_selected.component_props.customizables.initial_screen_size.width
      this.component_selected.porcentaje_y = 100 * (payload.y - payload.h / 2) / this.component_selected.component_props.customizables.initial_screen_size.height
      this.addModifiedDraggable()
    },
    rotatestop(payload) {
      if (this.component_selected === null || payload.id != this.component_selected.id) {
        const index = this.list_components.findIndex(object => {
          return object.id === payload.id;
        });
        this.list_components[index].y = payload.y
        this.list_components[index].x = payload.x
        this.list_components[index].w = payload.w
        this.list_components[index].h = payload.h
        this.list_components[index].angle = payload.angle

      }
      else {
        this.component_selected.x = payload.x
        this.component_selected.y = payload.y
        this.component_selected.w = payload.w
        this.component_selected.h = payload.h
        this.component_selected.angle = payload.angle
      }
      this.addModifiedDraggable()
      // this.positionOrSizeChange(payload)

    },
    resizestop(payload) {

      this.component_selected.x = payload.x
      this.component_selected.y = payload.y
      this.component_selected.w = payload.w
      this.component_selected.h = payload.h
      this.addModifiedDraggable()
    },

    moverX(p) {
      console.log(p)

      if (!this.getIsPlayMode && Object.keys(this.component_selected).length != 0) {
        this.component_selected.x += p;
        console.log("mover X")
        this.addModifiedDraggable(p)
      }
    },
    moverY(p) {
      if (!this.getIsPlayMode && Object.keys(this.component_selected).length != 0) {
        this.component_selected.y += p;
        console.log("mover Y")
        this.addModifiedDraggable(p)
      }
    }




  },//fin de los methos

  data() {
    return {
      width: 0,
      height: 0,
      copiedDrag: {},
      draggeado: false,
    }
  },
  watch: {
    component_selected() {
      // console.log("he cambiado de componente");
      this.draggeado = false
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener);
  },
  mounted() {
    const FLECHA_IZQUIERDA = '37';
    const FLECHA_ARRIBA = '38';
    const FLECHA_DERECHA = '39';
    const FLECHA_ABAJO = '40';

    this.width = this.$el.offsetWidth;
    this.height = this.$el.offsetHeight;
    this._keyListener = async function (e) {
      if (this.$route.name == 'Home') {

        if (!this.editing) {
          if (e.key.toUpperCase() === "C" && (e.ctrlKey || e.metaKey)) {  //  ctrl+c for copying draggables
            if (Object.keys(this.component_selected).length > 0) {
              this.copiedDrag = JSON.parse(
                JSON.stringify(this.component_selected)
              );
              this.copiedDrag.x = this.copiedDrag.x + 10
              this.copiedDrag.y = this.copiedDrag.y + 10
            }
          }
          if (e.key.toUpperCase() === "V" && (e.ctrlKey || e.metaKey)) {  //  ctrl+v for pasting draggables
            if (Object.keys(this.copiedDrag).length > 0 && Object.keys(this.selected_page).length > 0) {
              this.copiedDrag.id_page = this.selected_page.id
              const res = await this.postDraggable(this.copiedDrag)
              this.copiedDrag.id = res.data[0].id
              this.copiedDrag.component_props.id_draggable = res.data[0].id
              this.list_components.push(this.copiedDrag)
              await this.putDraggable(this.copiedDrag)  //id_draggable sortuta dagoenean eguneratzen dugu
              this.setComponentSelected(this.copiedDrag.id)
              this.copiedDrag = {}
            }
          }
          if (e.keyCode == FLECHA_IZQUIERDA) {
            e.preventDefault();

            this.moverX(-1);
          }
          if (e.keyCode == FLECHA_DERECHA) {
            e.preventDefault();

            this.moverX(+1);
          }
          if (e.keyCode == FLECHA_ABAJO) {
            e.preventDefault();

            this.moverY(+1);
          }
          if (e.keyCode == FLECHA_ARRIBA) {
            e.preventDefault();

            this.moverY(-1);
          }
        }
      };
    }
    document.addEventListener('keydown', this._keyListener.bind(this));
  },

}
</script>
<style>
.imagen {

  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;

  /* height:100%; */
  top: 0px;
  left: 0px;
}

#screen {
  margin: 0px;
  height: 92vh;
  position: relative;
  /* position: relative; */
  /* padding-top: 0%; */
}

.fondo_exec {
  width: 100%;
  height: 100%;
  position: relative;

  /* height:100%; */
  /* top: 0%; */
  left: 0px;

}

.prueba_exec {
  width: 100%;
  height: 100%;
  position: relative;
}

/* Para que los draggables se dibujen encima de la imagen de fondo */
/* .draggable-resize-rotate{
      z-index: 1000; 
      
    } */
</style>
<template lang="es">
  <div>
    <h4 class="text-center mt-3">Nombre</h4>
    <v-text-field
      class="ml-3 mr-3"
      v-model="component_selected.component_props.component_name"
      single-line
      @keyup.enter="changeEditing(false)"
      clearable
      :clear-icon-cb="component_selected.component_props.component_name == null? changeEditing(true):null"
      @click="changeEditing(true)"
      @blur="changeEditing(false)"
      />
    <v-checkbox
      class="ml-2"
      color="info"
      v-model="component_selected.component_props.customizables.show_value" 
      label="Mostrar valor">
    </v-checkbox>
    <div class="ml-3">
      Valor actual: {{component_selected.component_props.customizables.value}}
    </div>
 
    <v-text-field
      prefix="Valor Máximo:"
      class="ml-3 mr-3"
      v-model.number="component_selected.component_props.customizables.valorMaximo"
      single-line
      type="number"
      @click="changeEditing(true)"
      @blur="changeEditing(false)"
      @keypress="isNumber($event)"
      :min="component_selected.component_props.customizables.value ? component_selected.component_props.customizables.value:0"
    />
    <!--

    <h4 class="text-center mt-3">Valor Máximo</h4>
    <v-text-field
      class="ml-3 mr-3"
      v-model.number="component_selected.component_props.customizables.valorMaximo"
      single-line
      type="number"
      @keyup.enter="changeEditing(false)"
      @click="changeEditing(true)"
      @blur="changeEditing(false)"
      @keypress="isNumber($event)"
      :min="component_selected.component_props.customizables.value ? component_selected.component_props.customizables.value:0"
      /> -->
      <!-- background color picker -->
    <h4 class="text-center mt-3">Color del Fondo</h4>
    <koiote-color-picker-vue titulo="Color del Fondo"
      :pcolor="component_selected.component_props.customizables.colores[1].color"
      @event="cambio_background_color">
    </koiote-color-picker-vue>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import KoioteColorPickerVue from '@/components/custom/KoioteColorPicker.vue';
export default {
  name:"KoioteTankProperties",
  components:{
      KoioteColorPickerVue
  },
  data() {
      return {
          //background
          backgroundmenu: false,

      }
  },
  computed:{
    ...mapState(['component_selected']),
    ...mapState('nodered',['message']),
    ...mapGetters('nodered',['getConnections','getTopic'])
},
  methods:{
    ...mapActions('nodered',['changeTopic']),
    ...mapActions(['changeEditing']),

    
    cambio_background_color(nc){
        this.component_selected.component_props.customizables.colores[1].color=nc
    },
    isNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
        if(keyCode !== 46){
          $event.preventDefault();
          return false
        }
      }
    },
  },

}
</script>
<style lang="">

</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      requiresAuth: true,
      title: process.env.VUE_APP_TITLE,
      metaTags: [
        {
          name: 'Página principal',
          content: 'Página principal del editor de Scada para ' + process.env.VUE_APP_COMPANY
        },
      ]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta:{
      title: process.env.VUE_APP_TITLE,
      metaTags: [
        {
          name: 'Login',
          content: 'Página de Login del editor de Scada para ' + process.env.VUE_APP_COMPANY
        },
      ]
    }
   // meta: { hideNavbar: true},
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/alarms',
    name: 'alarms',
    component: () => import('../views/AlarmsView.vue'),
    meta: {
      requiresAuth: true,
      title: process.env.VUE_APP_TITLE,
      metaTags: [
        {
          name: 'Alarms',
          content: 'Página de visualización de alarmas del editor de Scada para ' + process.env.VUE_APP_COMPANY
        },
      ]
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/UsersView.vue'),
    meta: {
      requiresAuth: true,
      title: process.env.VUE_APP_TITLE,
      metaTags: [
        {
          name: 'Users',
          content: 'Página de gestión de usuarios del editor de Scada para ' + process.env.VUE_APP_COMPANY
        },
      ]
    }
  },
  {
    path: '/horno',
    name: 'horno',
    component: () => import('../views/HornoView.vue'),
    meta: {
      requiresAuth: true,
      title: process.env.VUE_APP_TITLE,
      metaTags: [
        {
          name: 'Horno',
          content: 'Página de historicos del horno para ' + process.env.VUE_APP_COMPANY
        },
      ]
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('token')
  
  // console.log("Es adminnnnnnnnnnnnn: "+is_admin);
  const pageTitle = to.meta.title
  if (pageTitle) 
    document.title = `${pageTitle}`
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    console.log('not logged in')
    next({name:'Login'})
  } else {
    if(to.path == '/users'){ // evitamos que se entre a la gestión de usuarios si no es admin o avanzado
      let is_admin = JSON.parse(localStorage.getItem("user"))
      if(is_admin){
        is_admin = (is_admin.role_name == 'admin') || (is_admin.role_name == 'avanzado')
        if(is_admin)
          next()
        else{
          router.push({name:'Home'})
          console.log("no puede entrar a la gestion de usuarios")
        }  
      }
    }
    if (loggedIn ? to.path !== '/login' : true) next();
  }
})

export default router

import KoioteIBHLinkConnection from './KoioteIBHLinkConnection.vue'
import IBHLinkVariableSelection from './IBHLinkVariableSelection.vue'
import KoioteIBHLinkConnectionProperties from './KoioteIBHLinkConnectionProperties.vue'
import KoioteIBHLinkConnection1Property from './default.json'
import ibhLinkReading from './ibhReading.js'



/// componente lectura excel
// import KoioteExcelReader from '@/components/custom/KoioteExcelReader.vue'
export default {
    install(Vue,options) {
        Vue.component(IBHLinkVariableSelection.name, IBHLinkVariableSelection)
        Vue.component(KoioteIBHLinkConnection.name, KoioteIBHLinkConnection)
        Vue.component(KoioteIBHLinkConnectionProperties.name, KoioteIBHLinkConnectionProperties)
        Vue.prototype.$IBHLinkConnectionProperties = KoioteIBHLinkConnection1Property
        // para interpretar las variabes que llegan de nodered
        Vue.mixin(ibhLinkReading)

        Vue.mixin({
            mounted() {
             // console.log('Montadito!');
            }
          });
       }
  };
  

import { mapState, mapGetters } from 'vuex';
import { Buffer } from 'buffer';

const omronReading = {
  computed:{
    ...mapGetters('nodered',['getConnections']),
    ...mapState('nodered',['message','ListOfmessages','subscriptions'])
  },
  methods: {
    
    calculateFINSConnectionBufferPos(data, plc) { // se le pasa this.myProps.customizables
      if(Object.keys(plc).length > 0 ){
        if(data.topic){
          // cogemos del topic el numero de read del que lee
          let nodeNumber = data.topic.split('_')[1]
          // buscamos la primera variable dentro de ese nodo de lectura
          let first_address = plc.variables.filter(e => e.node_number == nodeNumber)
          first_address = Math.min(...first_address.map(e => e.number))
          // calculamos la posicion del buffer 
          let position = this.calculateVarNumber(data) - first_address

          return position
        }
        else{
          return "errorBuffer"
        }
      }else console.log("PLC UNDEFINED")
    },

    calculateFINSConnectionVarType(data, plc) {

      if(Object.keys(plc).length > 0 ){
        //Filtramos los que están en el mismo area de memoria (CIO, D, H...)
        let variables_misma_letra = plc.variables.filter(e => (e.mem_type.includes(data.variable.match(/[a-zA-Z]+/g)[0])))
        // let variable_item = plc.variables.find(e => (e.number == this.calculateVarNumber(data)));

        let variable_item = variables_misma_letra.find(e => (e.number == this.calculateVarNumber(data)));
        
        if(variable_item){
          let tipo = variable_item.type == 'ENTERO'?'int':variable_item.type == 'REAL'?'float':'bit'
          return tipo
        }else return "wrongVarType"/*console.log("Esa variable no se lee")*/
      }else console.log("Componente sin plc asignado")
    },

    calculateVarNumber(data){
      if(data.variable.length > 0){ 
        return this.$splitVariable(data.variable).numbers
      }else return "undefinedVar"
    },
    transformBufferToFloatWithPosition(data,posicion){
      const i = posicion/2
      const A = data[0+4*i]
      const B = data[1+4*i]
      const C = data[2+4*i]
      const D = data[3+4*i]
      const newbuf = Buffer.from([C, D, A, B])
      return newbuf.readFloatBE()
    },
    transformBufferToFloat(data){
      let posicion = this.calculateBufferPos(data)
      const i = posicion/2
      if(this.message.topic == data.topic){

        const A = this.message.message[0+4*i]
        const B = this.message.message[1+4*i]
        const C = this.message.message[2+4*i]
        const D = this.message.message[3+4*i]
        const newbuf = Buffer.from([C, D, A, B])
        return newbuf.readFloatBE()
      }
    },
    // transformBufferToInt(data){
    //   return this.message.message[this.calculateBufferPos(data)*2+1]
    // },

    transformBufferToBits(data){
      let mensaje = (parseInt(this.message.message[this.calculateBufferPos(data)*2+1]) >>> 0).toString(2)
      // se le da la vuelta al array
      mensaje = Array.from(mensaje).reverse();
      console.log(parseInt(this.message.message[this.calculateBufferPos(data)*2+1]));
      mensaje = mensaje.map(Number)
      while (mensaje.length < 16)
        mensaje.push(0)
      return mensaje
    },

    readValueFromFINSConnection(mensaje, data,position,varType, plc){
      // console.log("readValueFromFINSConnection")
      if(!this.subscriptions.includes(data.topic)){
        // console.log("No se ha suscrito aun")
        return
      }
      if(position < parseInt(plc.count)){     //   porque en cada lectura leemos count elementos del plc
        if(varType == 'float'){
          let valor = this.transformBufferToFloatWithPosition(mensaje.datos, position)
          valor = (parseFloat(valor)*(data.scale)).toFixed(data.decimals)
          if(valor != undefined)
            return valor 
        }else if(varType == 'bit'){
          let bit = parseInt(data.variable.split('.')[1])
          if(isNaN(bit)){
            return "wrong var"
          }
          let p1=position*2
          let respuesta = this.$globalTransformBufferToBits(mensaje.datos, p1,2)
          return parseInt(respuesta[bit])
        }else if(varType == 'int'){
          let valor = 256*mensaje.datos[position*2] + mensaje.datos[position*2+1];
          if(data.scale != 1 && data.decimals != 0)
            return (parseFloat(valor)*data.scale).toFixed(data.decimals)
          else return 256*mensaje.datos[position*2] + mensaje.datos[position*2+1];
          // return this.transformBufferToInt(data)
        }else{
          // console.log("es: ",this.calculateVarType(data));
          return "wrongVarType"
        }
      }else{
        console.log("La posicion ", position, " esta fuera de rango");
      }
    }
  },
};

export default omronReading;
import { mapState, mapGetters, mapActions } from 'vuex';
import { Buffer } from 'buffer';

const omronWriting = {
	computed: {
		...mapGetters('nodered', ['getConnections', 'getNewNodeY', 'getNewNodeId','getPageId']),
		...mapState('nodered', ['message'])
	},
	methods: {
		...mapActions('api', ['deployClientFlows']),
		...mapActions('nodered', ['addNode','doPublish']),
		writeBitInFINSConnection(component, value) { // plc, variable, value, bit
			console.log("aqui escribiriamos un bit");
			if(value == 0 || value == 1 ){
				const payload = `{"timestamp": ${Date.now()}, "variable": "${component.variable}", "valor":[${value}]}`
				const topic = `PLC${component.plc}_write`
				this.doPublish({topic, mensaje: payload})
			}
		},
		writeIntInFINSConnection(component, value) {
			console.log("aqui escribiriamos un int");
			if(!value.toString().includes('.')){
				const payload = `{"timestamp": ${Date.now()}, "variable": "${component.variable}", "valor":[${value}]}`
				const topic = `PLC${component.plc}_write`
				this.doPublish({topic, mensaje: payload})
			}
		},

		writeFloatInFINSConnection(component, value) {// plc, variable, value
			console.log("aqui escribiriamos un floattt");
			const buffer = Buffer.allocUnsafe(4);
			buffer.writeFloatBE(parseFloat(value));
			let d = [];
			d[0] = 256 * buffer[2] + buffer[3]
			d[1] = 256 * buffer[0] + buffer[1]
			const payload = `{"timestamp": ${Date.now()}, "variable": "${component.variable}", "valor":[${d}]}`
      const topic = `PLC${component.plc}_write`
			this.doPublish({topic, mensaje: payload})

		},
		async addOmronWritingNodes(plc) {
			console.log("Aqui añadimos  los nodosssss");
			let connectionNodeId = this.flows.find(e => e.type == "FINS Connection" && e.host == plc.ip).id
			let y = this.getNewNodeY({ x: 680, pageId: this.getPageId })
			this.addMqttIn(plc.id, y)
			this.addVariableGetFunction(y)
			this.addOmronWriteNode(connectionNodeId, plc.id, y)
			await this.deployClientFlows()

		},

		addMqttIn(plcid, y) {
			//lortu y eta id
			let nodeid = this.getNewNodeId
			this.addNode({
				id: nodeid,
				type: "mqtt in",
				z: this.getPageId,             
				name: `PLC${plcid}_write`,
				topic: `PLC${plcid}_write`,
				qos: "2",
				datatype: "auto-detect",
				broker: "mqttbrokernode",
				nl: false,
				rap: true,
				rh: 0,
				inputs: 0,
				x: 180,
				y: y,
				wires: [
					[
						`${parseInt(nodeid) + 1}`
					]
				]
			})
		},
		addVariableGetFunction(y) {
			//lortu y eta id
			let nodeid = this.getNewNodeId
			this.addNode({
				id: nodeid,
				type: "function",
				z: this.getPageId,            
				name: `VariableGet`,
				// sacamos del payload del mensaje recibido la direccion de la variable y el valor a escribir
				func: "//comprobamos que el mensaje que nos llega se ha enviado mas tarde que el ultimo que se escribio en esa variable\nif(!global.get(`${msg.topic}_${msg.payload.variable}`) || msg.payload.timestamp > global.get(`${msg.topic}_${msg.payload.variable}`)){\n    global.set(`${msg.topic}_${msg.payload.variable}`, msg.payload.timestamp)\n    msg.topic = msg.payload.variable\n    msg.payload = msg.payload.valor\n    return msg;\n}",
				outputs: 1,
				noerr: 0,
				initialize: "",
				finalize: "",
				libs: [],
				x: 380,
				y: y,
				wires: [
					[
						`${parseInt(nodeid) + 1}`
					]
				]
			})
		},

		addOmronWriteNode(connectionNodeId, plcid, y) {
			//lortu y eta id
			let nodeid = this.getNewNodeId
			this.addNode({
				id: nodeid,
				type: "FINS Write",
				z: this.getPageId,            
				name: `Write_${plcid}`,
				connection: `${connectionNodeId}`,
				addressType: "msg",
				address: "topic",
				dataType: "msg",
				data: "payload",
				msgPropertyType: "str",
				msgProperty: "payload",
				x: 680,
				y: y,
				wires: [
					[]
				]
			})
		},
	}
};

export default omronWriting;
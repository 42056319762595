<template>
  <v-card text>
    <v-list>
      <v-list-item-group>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              prefix="Nombre:"
              v-model="component_selected.component_props.component_name"
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>       
        <v-list-item>
          <v-list-item-content>
           Valor actual: {{ component_selected.component_props.customizables.value }}
          </v-list-item-content>
        </v-list-item>       
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "KoioteSelectorProperties",
  computed: {
    ...mapState(["component_selected"]),
  },
  methods: {
  },
  data() {
    return {
      //background
    };
  },
};
</script>
<style lang="">
</style>
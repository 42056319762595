<template>
    <v-container>
        <h1>Alarmas</h1>
        <v-card>

            <!-- Alarmas activas actualmente -->
            <v-container>
                <v-card v-if="alarmas_activas">
                    <v-card-title>Actualmente activas</v-card-title>
                    <v-data-table :headers="headers_activas" :items="items_activas"
                            :footer-props="{
                                'items-per-page-text':'Alarmas por página'
                            }">
                        <template v-slot:[`item.estado`]="{ item }">
                            <div class="green--text">
                                {{item.estado}}
                            </div>
                        </template>
                    </v-data-table>
                </v-card>
                <v-card v-else>
                    <v-card-title>
                        Actualmente no se encuentra ninguna alarma activa
                    </v-card-title>
                </v-card>
            </v-container>

            <!-- Históricos de alarmas -->
            <v-container>
                <v-card>
                    <v-card-title>Históricos</v-card-title>
                    
                    <!-- Buscador -->
                    <v-text-field v-model="search" class="ml-4 mr-4 mb-4" append-icon="mdi-magnify" label="Buscar" single-line
                        hide-details></v-text-field>

                    <!-- Tabla de alarmas -->
                    <v-data-table :headers="headers" :items="items_historicos" :search="search" :sort-by="['fecha_alarma']" :sort-desc="[true]"
                        :loading="loading_historicos"
                        :footer-props="{
                            'items-per-page-text':'Alarmas por página'
                        }"
                        ></v-data-table>
                </v-card>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
    data() {
        return {
            search: null,
            loading_historicos: true,
            headers: [
                { text: "Fecha", value: "fecha_alarma" },
                { text: "Nombre", value: "nombre_alarma" },
                { text: "PLC", value: "nombre_plc" },
                // { text: "Variable", value: "variable" },
            ],
            headers_activas: [
                // { text: "ID", value: "id" },
                // { text: "Direccion", value: "direccion" },
                { text: "Nombre", value: "nombre" },
                // { text: "PLC", value: "id_plc" },
                // { text: "Valor Actual", value: "valor_actual" },
                { text: "Estado actual", value: "estado" },
            ],
            items_historicos: [],
            items_activas: [],
            alarmas_activas: false,
        };
    },
    computed: {
        ...mapGetters('api', ['getMaquinas']),
    },

    async created() {
        const alarms = await this.getHistoricosAlarmas();
        this.loading_historicos = false;
        this.items_historicos = alarms.data.results;
        //ordenamos el array por fecha y hacemos que aparezca la última fecha la primera
        this.items_historicos.sort(this.compareByDate).reverse()
        this.items_historicos.forEach(e => e.fecha_alarma = moment(e.fecha_alarma).format('YYYY-MM-DD HH:mm:ss'))
        
        //Obtenemos el estado actual de las alarmas
        const activas = this.getMaquinas
        
        //Filtrar alarmas activas
        activas.data.results.forEach(element => {
            element.estado = 'Activo'
            if(element.valor_actual == 3) {
                this.items_activas.push(element)
                this.alarmas_activas = true
            }
        });
    },

    methods: {
        ...mapActions('api', ['getHistoricosAlarmas','getHistoricosFecha']),
        compareByDate(a, b) {
            return a.fecha_alarma - b.fecha_alarma;
        },
    }
}
</script>
<template lang="es">
    <div>
      <h4 class="text-center mt-3">Nombre</h4>
      <v-text-field
        class="ml-3 mr-3"
        v-model="component_selected.component_props.component_name"
        single-line
        :disabled = component_selected.component_props.customizables.connected
        @keyup.enter="changeEditing(false)"
        clearable
        :clear-icon-cb="component_selected.component_props.component_name == null? changeEditing(true):null"
        @click="changeEditing(true)"
        @blur="changeEditing(false)"
      />
      <h4 class="text-center mt-3">IP</h4>
      <v-text-field
        class="ml-3 mr-3"
        v-model="component_selected.component_props.customizables.ip"
        single-line
        :disabled = component_selected.component_props.customizables.connected
        @keyup.enter="changeEditing(false)"
        clearable
        :clear-icon-cb="component_selected.component_props.customizables.ip == null? changeEditing(true):null"
        @click="changeEditing(true)"
        @blur="changeEditing(false)"
      />
      <h4 class="text-center mt-3">Puerto</h4>
      <v-text-field
        class="ml-3 mr-3"
        v-model.number="component_selected.component_props.customizables.port"
        single-line
        type="number"
        @keypress="validatePort($event)" 
        @keyup.enter="changeEditing(false)"
        @click="changeEditing(true)"
        @blur="changeEditing(false)"
        :min=0
        :max=65535
        :disabled = component_selected.component_props.customizables.connected
      />

      <div class="text-center" v-if="Object.keys(component_selected).length != 0">
        <v-btn class="btn mt-4"
        rounded
        color="primary"
        dark
        @click="component_selected.component_props.customizables.connected ? disconnect() : connect()"
        label="hola"
        >
        {{component_selected.component_props.customizables.connected ? 'DESCONECTAR':'CONECTAR'}}
        </v-btn>
      </div>
    </div>
  </template>
  <script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  export default {
    name:"KoioteSnap7ConnectionProperties",
    
    methods: {
    },
    data() {
        return {
            nuevo_objeto:null,
            connectionNodeId:null,
        }
    },
    created(){
      if(typeof this.component_selected.component_props.customizables.connectionID != "number" ){
        console.log("NO TENGO ID");
        if(this.getConnections.length == 0){
          this.nuevo_objeto.customizables.connectionID = 0
        }else{
          this.nuevo_objeto.customizables.connectionID = (Math.max(...this.getConnections.map(e => parseInt(e.id)))+1)
        }
        this.addConnection({
          id: this.component_selected.component_props.customizables.connectionID,    // excel-etik hartutako datuak
          ip: this.myProps.customizables.ip,
          variables:[{addr: "DB1,R0",    name: "DB1,R0"   },
                     {addr: "DB1,X4.0",  name: "DB1,X4.0" },
                     {addr: "DB1,I6",    name: "DB1,I6"   },
                     {addr: "DB1,S8.12", name: "DB1,S8.12"}],
          name: this.component_selected.component_props.component_name, 
          type:"s7 endpoint"})
        let s7InNode = this.flows.find(e => e.type == "s7 in" && e.name == `Read_${this.component_selected.component_props.customizables.connectionID}`) 
        if(s7InNode && s7InNode.d != true){
          this.component_selected.component_props.customizables.connected = true
        }else{
          this.component_selected.component_props.customizables.connected = false
        }
      }
    },
    computed:{
      ...mapState(['component_selected']),
      ...mapState('nodered',['flows']),
      ...mapGetters('nodered',['getNewNodeId','getNewNodeY','getConnections']),
      pageId(){                             // esto dependera de la pagina que sea, esta es por defecto
        if(this.flows.find(e => e.type == "tab" && e.label == 'Principal'))
          return this.flows.find(e => e.type == "tab").id
        else
          return "1"
      }
    },
    watch:{
        component_selected(){
        },
        'component_selected.component_props.customizables.port': function(){
          if(this.component_selected.component_props.customizables.port > 65535)
            this.component_selected.component_props.customizables.port = 65535
        },
    },
   
    methods:{
      ...mapActions(['changeEditing']),
      ...mapActions('nodered', ['addNode', 'addConnection','disableNode','enableNode']),
      ...mapActions('api',['deployClientFlows']),

      checkIP(){
        if (!(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi).test(this.component_selected.component_props.customizables.ip))
          return false
        else return true
      },

      validatePort($event){
        if(this.isNumber($event)){
          if((this.component_selected.component_props.customizables.port * 10 + parseInt($event.key)) > 65535)
            $event.preventDefault()
        }
      },

      isNumber ($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57)){
          $event.preventDefault();
          return false
        } else{
          return true
        }
      },
      
      disconnect(){
        let s7InNode = this.flows.find(e => e.type == "s7 in" && e.name == `Read_${this.component_selected.component_props.customizables.connectionID}`) 
        this.disableNode(s7InNode)
        this.component_selected.component_props.customizables.connected = false
      },

      connectExistingPLC(){
       let s7InNode = this.flows.find(e => e.type == "s7 in" && e.name == `Read_${this.component_selected.component_props.customizables.connectionID}`) 
        this.enableNode(s7InNode)
      },
      async connect(){
        console.log("CONECTANDO...");
        if(this.checkIP()){
          // begiratzen dugu ea konexio hori jada existitzen den
          let existingConnection = this.flows.find(e => e.type == "s7 endpoint" && e.address == this.component_selected.component_props.customizables.ip && e.port == this.component_selected.component_props.customizables.port)
          if(!existingConnection){
            this.connectionNodeId = this.getNewNodeId
            this.addConnectionNode()

            //gehitzen ditugu siemensetik irakurtzeko behar diren nodoak
            this.addReadNode()
            this.addTopicFunctionNode()
            this.addMqttOut()

            // this.doSubscribe(`PLC${this.component_selected.component_props.customizables.connectionID}`)

            await this.deployClientFlows()        
          }else{
            console.log("YA existe")
            this.connectExistingPLC()
          }
          this.component_selected.component_props.customizables.connected = true
        }else{
          console.log("IP no válida")
        }
      },
      addConnectionNode(){
        if(!this.component_selected.component_props.customizables.ip){
          console.log("Falta la IP");
          return
        }
        let da1 = this.component_selected.component_props.customizables.ip.split('.')
        if(!da1[3]){
          console.log("La ip no está completa");
          return
        }
        this.addNode({
          id: this.connectionNodeId,
          type: "s7 endpoint",
          transport: "iso-on-tcp",
          address: this.component_selected.component_props.customizables.ip,
          port: `${this.component_selected.component_props.customizables.port}`,
          rack: this.component_selected.component_props.customizables.rack,
          slot: this.component_selected.component_props.customizables.slot,
          localtsaphi: "01",
          localtsaplo: "00",
          remotetsaphi: "01",
          remotetsaplo: "00",
          connmode: "rack-slot",
          adapter: "",
          busaddr: "2",
          cycletime: "1000",
          timeout: "2000",
          name: this.component_selected.component_props.component_name,
          vartable: [
              {
                  addr: "DB1,R0",
                  name: "DB1,R0"
              },
              {
                  addr: "DB1,X4.0",
                  name: "DB1,X4.0"
              },
              {
                  addr: "DB1,I6",
                  name: "DB1,I6"
              },
              {
                  addr: "DB1,S8.12",
                  name: "DB1,S8.12"
              }
          ]
        })
      },
      addReadNode(){
        let nodeid = this.getNewNodeId
        this.addNode({
          id: nodeid,
          type:  "s7 in",
          z: this.pageId,                                
          endpoint:`${this.connectionNodeId}`,
          mode: "all",
          variable: "real",
          diff: false,
          name: `Read_${this.component_selected.component_props.customizables.connectionID}`,
          x: 380,
          y: this.getNewNodeY({x: 380, pageId: this.pageId}),
          wires: [
            [
              `${parseInt(nodeid) + 1}`
            ]
          ]
        })
      },
      addTopicFunctionNode(){
        let nodeid = this.getNewNodeId
        this.addNode({
          id: nodeid,
          type: "function",
          z: this.pageId,
          name: `getTopicFrom${this.component_selected.component_props.component_name}`,
          func: `msg.topic = 'PLC${this.component_selected.component_props.customizables.connectionID}'\nreturn msg;`,
          outputs: 1,
          noerr: 0,
          initialize: "",
          finalize: "",
          libs: [],
          x: 710,
          y: this.getNewNodeY({x: 710, pageId: this.pageId}),
          wires: [
            [
            `${parseInt(nodeid) + 1}`
            ]
          ]
        })
      },
      addMqttOut(){
        this.addNode({
          id: this.getNewNodeId,
          type: "mqtt out",
          z: this.pageId,
          name: `mqtt out`,
          topic: "",
          qos: "2",
          retain: "",
          respTopic: "",
          contentType: "",
          userProps: "",
          correl: "",
          expiry: "",
          broker: "mqttbrokernode",
          x: 980,
          y: this.getNewNodeY({x: 980, pageId: this.pageId}),
          wires: [
            []
          ]
        })
      }
    },
  }
  </script>
  <style lang="">
  
  </style>
<template lang="es">
        <v-card text>
            <v-list>
                <v-list-item-group>
                  
                          <v-checkbox
                            color="info"
                            class='ml-2'
                            hide-details
                            v-model="component_selected.component_props.customizables.round"
                            label="Formato circular"
                            >
                          </v-checkbox>
                      
                    <!-- text area for only read -->

                    <v-list-item>
                        <v-list-item-content>
                            <!-- <v-text-field
                                v-model="component_selected.component_props.customizables.value"
                                label="Valor Leido"
                                dense
                                background-color="white"
                                readonly
                            ></v-text-field> -->
                            Valor actual: {{component_selected.component_props.customizables.value}}
                        </v-list-item-content>
                    </v-list-item>
                    <!-- {{component_selected.component_props.customizables.setted_values}} -->
                    <v-list-item>
                    <!-- select a int value -->

                        <v-list-item-content>
                          <!-- <v-list-item-title> -->
                            <v-btn block @click="shuffle_mostrar_valores">Imágenes</v-btn>
                          <!-- </v-list-item-title> -->
                          <v-simple-table v-if="mostrar_valores">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th>Valor</th>
                                  <th>Imágen</th>
                                </tr>
                              </thead>
                              <tbody>
                                <!-- v-for="(item,index) in component_selected.component_props.customizables.values.sort((a,b)=>a-b)" -->

                                <tr
                                v-for="(item,index) in component_selected.component_props.customizables.values"
                                  :key="index"
                                >
                                <td>{{item}}</td>
                                <td>
                                  <span>
                                    <v-img 
                                    :src="getSelectedImage(item)"
                                    height="32px"
                                    width="32px"
                                    @click="cambiar_imagen(item)"
              
                                  ></v-img>

                                  </span>
                                 
                                  <v-file-input
                                
                                    accept="image/*"
                                    label="Imagen"
                                    dense
                                    background-color="white"
                                    :id="refFileInput(item)"
                                    ref="refFileInput"
                                    :class="clase_valor(item)"
                                    @change="fileChangedInItem(item,$event)"
                                 ></v-file-input>
                                </td>
                                </tr>
                                <tr>
                                  <td>
                                    <v-text-field @keyup.enter="guardar_valor" v-model="new_value"></v-text-field>
                                  </td>
                                  <td>
                                    <span>{{pendiente}}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </template>

                          </v-simple-table>
                            <!-- <v-select
                                v-model="value"
                                :items="component_selected.component_props.customizables.values"
                                label="Valor"
                                dense
                                background-color="white"
                                @change="updateSelectedImage(value)"
                            ></v-select> -->
                        </v-list-item-content>
                    </v-list-item>

                    <!-- <v-list-item v-if="value">
                        <v-list-item-content>
                          <v-list-item-title><h4>Imagen para el valor:</h4></v-list-item-title>

                            <v-file-input
                                accept="image/*"
                                label="Imagen"
                                dense
                                background-color="white"
                                ref="fileInput"
                                @change="onFileChange($event)"
                            ></v-file-input>
                        </v-list-item-content>
                    </v-list-item> -->
                    <!-- display the image from above -->
                    <v-list-item v-if="url">
                        <v-list-item-content>
                            <v-img
                                :src="url"
                                :alt="url"
                                width="100%"
                                height="100%"
                                contain
                            ></v-img>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item>
                        <v-list-item-content>
                            <div v-if="!addvaluemenu" class="text-center">
                                <v-btn
                                    color="primary"
                                    dark
                                    @click="addvaluemenu = true"
                                >
                                    Añadir otro valor
                                </v-btn>
                            </div>
                            <div v-else>
                                !-- input for value number with a guardar button that closes the menu --!
                                <v-text-field
                                    v-model="newvalue"
                                    label="Value"
                                    dense
                                    background-color="white"
                                ></v-text-field>
                                <v-btn
                                    color="blue"
                                    dark
                                    @click="addValue(newvalue)"
                                >
                                    Guardar
                                </v-btn>
                            </div>
                            </div>
                        </v-list-item-content>
                    </v-list-item> -->
                </v-list-item-group>
            </v-list>
        </v-card>

</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "KoioteImageSwitchProperties",
  computed: {
    ...mapState(["component_selected"]),
    pendiente(){
      return `<<Primero valor>>`
    },
  },
  methods: {
    ...mapActions(["updateComponent"]),
    onFileChange(e) {
      if(e != null){

        const reader = new FileReader();
        reader.onload = (event) => {
          if (
            //si ya existe imagen para ese valor, la reemplaza
          this.component_selected.component_props.customizables.setted_values.includes(this.value)
          ) {
            this.component_selected.component_props.customizables.images[
              this.component_selected.component_props.customizables.setted_values.indexOf(this.value)
            ] = event.target.result;
          } else {
            //si no existe, la agrega
            this.component_selected.component_props.customizables.images.push(event.target.result);
            this.component_selected.component_props.customizables.setted_values.push(this.value);
          }
          this.url = event.target.result;
        };
      
        reader.readAsDataURL(e);
      }else{   // si se borra la imagen
        if (this.component_selected.component_props.customizables.setted_values.includes(this.value)) {
          this.component_selected.component_props.customizables.images.splice(this.component_selected.component_props.customizables.setted_values.indexOf(this.value),1)
          this.component_selected.component_props.customizables.setted_values.splice(this.component_selected.component_props.customizables.setted_values.indexOf(this.value),1)

        }
        
      }
      //this.updateComponent(this.component_selected.component_props)
    
    },
    fileChangedInItem(it,e){
      console.log(it)
      if(e != null){

        const reader = new FileReader();
        reader.onload = (event) => {
          //si ya existe imagen para ese valor, la reemplaza
          if (this.component_selected.component_props.customizables.setted_values.includes(it)){
            this.component_selected.component_props.customizables.images[
              this.component_selected.component_props.customizables.setted_values.indexOf(it)
            ] = event.target.result;
          } else {
            //si no existe, la agrega
            this.component_selected.component_props.customizables.images.push(event.target.result);
            this.component_selected.component_props.customizables.setted_values.push(it);
          }
          //this.url = event.target.result;
          this.component_selected.component_props.customizables.images.splice();
          this.component_selected.component_props.customizables.setted_values.splice();
        };

        reader.readAsDataURL(e);
      }else
      {   // si se borra la imagen
        if (this.component_selected.component_props.customizables.setted_values.includes(this.value)) 
        {
          this.component_selected.component_props.customizables.images.splice(this.component_selected.component_props.customizables.setted_values.indexOf(this.value),1)
          this.component_selected.component_props.customizables.setted_values.splice(this.component_selected.component_props.customizables.setted_values.indexOf(this.value),1)

        }

      }
       
    },



    updateSelectedImage(value) {
      //updates the image that is being displayed in the toolbar
      if (this.component_selected.component_props.customizables.setted_values.includes(value)) {
        this.url =
          this.component_selected.component_props.customizables.images[
            this.component_selected.component_props.customizables.setted_values.indexOf(value)
          ];
      } else {
        this.url = null;
      }
    },
    clase_valor(it){
      return this.isImageSeted(it)?"d-none":"";
    },
    refFileInput(it){
      return `file_input_${it}`
    },
    cambiar_imagen(it)
    {
      const ref = this.refFileInput(it)
      let file_selected = document.getElementById(ref)
     
      file_selected.click()
      


      // this.$refs[`file_input_${it}`].click();
      
    },


    isImageSeted(value){
      return (this.component_selected.component_props.customizables.setted_values.includes(value))
    },

    getSelectedImage(value){
      if (this.component_selected.component_props.customizables.setted_values.includes(value))
      {
        return this.component_selected.component_props.customizables.images[
            this.component_selected.component_props.customizables.setted_values.indexOf(value)
        ];
      }
     
    },
    shuffle_mostrar_valores(){
      this.mostrar_valores=!this.mostrar_valores;
    },

    addValue(value) {
      //adds a value to the possible values array
      if (!this.component_selected.component_props.customizables.values.includes(value)) {
        this.component_selected.component_props.customizables.values.push(value);
        this.updateComponent(this.component_selected.component_props)

      }
      this.addvaluemenu = false;
    },

    guardar_valor(){
      this.addValue(this.new_value)
      this.new_value=null;
    }
  },
  data() {
    return {
      //background
      addvaluemenu: false,
      url: null,
      value: null,
      newvalue: null,
      mostrar_valores:false,
      new_value:null,
      
    };
  },
};
</script>
<style lang="">
</style>
import KoioteSiloCanvas from './KoioteSiloCanvas.vue'
import KoioteSiloCanvasProperties from './KoioteSiloCanvasProperties.vue'
import KoioteSiloCanvasProperty from './default.json'

export default {
    install(Vue,options) {
        Vue.component(KoioteSiloCanvas.name, KoioteSiloCanvas)
        Vue.component(KoioteSiloCanvasProperties.name, KoioteSiloCanvasProperties)
        Vue.prototype.$KoioteSiloCanvasProperties = KoioteSiloCanvasProperty


        Vue.mixin({
            mounted() {
            
            }
          });
       }
  };
  

import KoioteLabel from './KoioteLabel.vue'
import KoioteLabelProperties from './KoioteLabelProperties.vue'
import KoioteLabel1Property from './default.json'

export default {
    install(Vue,options) {
        Vue.component(KoioteLabel.name, KoioteLabel)
        Vue.component(KoioteLabelProperties.name, KoioteLabelProperties)
        Vue.prototype.$LabelProperties = KoioteLabel1Property


        Vue.mixin({
            mounted() {
             // console.log('Montadito!');
            }
          });
       }
  };
  

<template>
    <v-container>
        <v-container>
            <v-card outlined elevation="0">
                <v-card-title md="1">{{ hoy }}</v-card-title>
                <v-card md="11">
                    <grafica-zonas :data="data_1">
                    </grafica-zonas>
                    
                    <grafica-zonas-resumen :data="data_t">
                    </grafica-zonas-resumen>
                </v-card>
            </v-card>
        </v-container>
        
        <v-container outlined>
            <v-card outlined elevation="0">
                <v-card-title md="1">{{ dia_1 }}</v-card-title>
                <v-card md="11">
                    <grafica-zonas :data="data">
                    </grafica-zonas>

                    <grafica-zonas-resumen :data="data_1_t">
                    </grafica-zonas-resumen>
                </v-card>
            </v-card>
        </v-container>
        
        <!-- <v-card>
            <v-card-title md="1">{{ dia_2 }}</v-card-title>
            <v-card md="11">
                <grafica-zonas :data="data_2">
                </grafica-zonas>

                <grafica-zonas-resumen :data="data_2_t">
                </grafica-zonas-resumen>
            </v-card>
        </v-card>
        
        <v-card>
            <v-card-title md="1">{{ dia_3 }}</v-card-title>
            <v-card md="11">
                <grafica-zonas :data="data_3">
                </grafica-zonas>

                <grafica-zonas-resumen :data="data_3_t">
                </grafica-zonas-resumen>
            </v-card>
        </v-card>

        <v-card>
            <v-card-title md="1">{{ dia_4 }}</v-card-title>
            <v-card md="11">
                <grafica-zonas :data="data_4">
                </grafica-zonas>

                <grafica-zonas-resumen :data="data_4_t">
                </grafica-zonas-resumen>
            </v-card>
        </v-card>

        <v-card>
            <v-card-title md="1">{{ dia_5 }}</v-card-title>
            <v-card md="11">
                <grafica-zonas :data="data_5">
                </grafica-zonas>

                <grafica-zonas-resumen :data="data_5_t">
                </grafica-zonas-resumen>
            </v-card>
        </v-card>

        <v-card>
            <v-card-title md="1">{{ dia_6 }}</v-card-title>
            <v-card md="11">
                <grafica-zonas :data="data_6">
                </grafica-zonas>

                <grafica-zonas-resumen :data="data_6_t">
                </grafica-zonas-resumen>
            </v-card>
        </v-card> -->

    </v-container>
</template>

<script>
import GraficaZonas from './GraficaZonas.vue';
import GraficaZonasResumen from './GraficaZonasResumen.vue';
import moment from 'moment-timezone';

export default {
    components: {
        GraficaZonas, GraficaZonasResumen
    },
    props: {
        title: {
            type: String,
            default: 'Inserte el título'
        },
        semana_total: {
            type: Array            
        },
    },
    data() {
        return {
            hoy: moment(Date.now()).tz('Europe/Madrid').format('YYYY-MM-DD'),
            labels_semana: [],
            zona1: [],
            zona2: [],
            zona3: [],
            zona4: [],
            zona5: [],
            
            zona1_t: [],
            zona2_t: [],
            zona3_t: [],
            zona4_t: [],
            zona5_t: [],
            
            dia_1: moment(Date.now() - 86400000).tz('Europe/Madrid').format('YYYY-MM-DD'), //Ayer
            labels_semana_1: [],
            zona1_1: [],
            zona2_1: [],
            zona3_1: [],
            zona4_1: [],
            zona5_1: [],
            
            zona1_1_t: [],
            zona2_1_t: [],
            zona3_1_t: [],
            zona4_1_t: [],
            zona5_1_t: [],

            dia_2: moment(Date.now() - 86400000 * 2).tz('Europe/Madrid').format('YYYY-MM-DD'), //Ayer
            labels_semana_2: [],
            zona1_2: [],
            zona2_2: [],
            zona3_2: [],
            zona4_2: [],
            zona5_2: [],
            
            zona1_2_t: [],
            zona2_2_t: [],
            zona3_2_t: [],
            zona4_2_t: [],
            zona5_2_t: [],
            
            // dia_3: moment(Date.now() - 86400000 * 3).tz('Europe/Madrid').format('YYYY-MM-DD'), //Ayer
            // labels_semana_3: [],
            // zona1_3: [],
            // zona2_3: [],
            // zona3_3: [],
            // zona4_3: [],
            // zona5_3: [],
            
            // zona1_3_t: [],
            // zona2_3_t: [],
            // zona3_3_t: [],
            // zona4_3_t: [],
            // zona5_3_t: [],

            // dia_4: moment(Date.now() - 86400000 * 4).tz('Europe/Madrid').format('YYYY-MM-DD'), //Ayer
            // labels_semana_4: [],
            // zona1_4: [],
            // zona2_4: [],
            // zona3_4: [],
            // zona4_4: [],
            // zona5_4: [],
            
            // zona1_4_t: [],
            // zona2_4_t: [],
            // zona3_4_t: [],
            // zona4_4_t: [],
            // zona5_4_t: [],

            // dia_5: moment(Date.now() - 86400000 * 5).tz('Europe/Madrid').format('YYYY-MM-DD'), //Ayer
            // labels_semana_5: [],
            // zona1_5: [],
            // zona2_5: [],
            // zona3_5: [],
            // zona4_5: [],
            // zona5_5: [],
            
            // zona1_5_t: [],
            // zona2_5_t: [],
            // zona3_5_t: [],
            // zona4_5_t: [],
            // zona5_5_t: [],

            // dia_6: moment(Date.now() - 86400000 * 6).tz('Europe/Madrid').format('YYYY-MM-DD'), //Ayer
            // labels_semana_6: [],
            // zona1_6: [],
            // zona2_6: [],
            // zona3_6: [],
            // zona4_6: [],
            // zona5_6: [],
            
            // zona1_6_t: [],
            // zona2_6_t: [],
            // zona3_6_t: [],
            // zona4_6_t: [],
            // zona5_6_t: [],
        }
    },
    computed: {
        data() {
            let datos = {                
                labels: this.labels_semana,
                datasets: [
                    {
                        label: 'Automático',
                        // borderColor: 'rgb(255, 0, 0)',
                        backgroundColor: '#00ff00',
                        data: this.zona1,
                        fill: {
                            //target: { value:50 },
                            target: 'origin',
                            above: '#00ff00',   // Area will be red above the origin
                        },
                        pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
                    },
                    {
                        label: 'Parada por operario',
                        backgroundColor: '#a0a0a0',
                        data: this.zona2,
                        fill: {
                            target: 'origin',
                            above: '#a0a0a0',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: 'Parada alarma',
                        backgroundColor: '#ff0000',
                        data: this.zona3,
                        fill: {
                            target: 'origin',
                            above: '#ff0000',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: 'Mantenimiento',
                        backgroundColor: '#00d3ff',
                        data: this.zona4,
                        fill: {
                            target: 'origin',
                            above: '#00d3ff',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: 'Parada por falta de condiciones',
                        backgroundColor: '#ff9300',
                        data: this.zona5,
                        fill: {
                            target: 'origin',
                            above: '#ff9300',
                        },
                        pointRadius: 0,
                    }
                ]
            }
            return datos
        },
        data_t() {
            let datos = {                
                labels: this.labels_semana_1,
                datasets: [
                    {
                        label: Math.round(this.zona1_t.filter(e => e != null).length/this.labels_semana_1.length * 100) + '%',
                        // borderColor: 'rgb(255, 0, 0)',
                        backgroundColor: '#00ff00',
                        data: this.zona1_t,
                        fill: {
                            //target: { value:50 },
                            target: 'origin',
                            above: '#00ff00',   // Area will be red above the origin
                        },
                        pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
                    },
                    {
                        label: Math.round(this.zona2_t.filter(e => e != null).length/this.labels_semana_1.length * 100) + '%',
                        backgroundColor: '#a0a0a0',
                        data: this.zona2_t,
                        fill: {
                            target: 'origin',
                            above: '#a0a0a0',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: Math.round(this.zona4_t.filter(e => e != null).length/this.labels_semana_1.length * 100) + '%',
                        backgroundColor: '#00d3ff',
                        data: this.zona4_t,
                        fill: {
                            target: 'origin',
                            above: '#00d3ff',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: Math.round(this.zona5_t.filter(e => e != null).length/this.labels_semana_1.length * 100) + '%',
                        backgroundColor: '#ff9300',
                        data: this.zona5_t,
                        fill: {
                            target: 'origin',
                            above: '#ff9300',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: Math.round(this.zona3_t.filter(e => e != null).length/this.labels_semana_1.length * 100) + '%',
                        backgroundColor: '#ff0000',
                        data: this.zona3_t,
                        fill: {
                            target: 'origin',
                            above: '#ff0000',
                        },
                        pointRadius: 0,
                    }
                ]
            }
            return datos
        },
        data_1() {
            let datos = {                
                labels: this.labels_semana_1,
                datasets: [
                    {
                        label: 'Automático',
                        // borderColor: 'rgb(255, 0, 0)',
                        backgroundColor: '#00ff00',
                        data: this.zona1_1,
                        fill: {
                            //target: { value:50 },
                            target: 'origin',
                            above: '#00ff00',   // Area will be red above the origin
                        },
                        pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
                    },
                    {
                        label: 'Parada por operario',
                        backgroundColor: '#a0a0a0',
                        data: this.zona2_1,
                        fill: {
                            target: 'origin',
                            above: '#a0a0a0',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: 'Parada alarma',
                        backgroundColor: '#ff0000',
                        data: this.zona3_1,
                        fill: {
                            target: 'origin',
                            above: '#ff0000',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: 'Mantenimiento',
                        backgroundColor: '#00d3ff',
                        data: this.zona4_1,
                        fill: {
                            target: 'origin',
                            above: '#00d3ff',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: 'Parada por falta de condiciones',
                        backgroundColor: '#ff9300',
                        data: this.zona5_1,
                        fill: {
                            target: 'origin',
                            above: '#ff9300',
                        },
                        pointRadius: 0,
                    }
                ]
            }
            return datos
        },
        data_1_t() {
            let datos = {                
                labels: this.labels_semana,
                datasets: [
                    {
                        label: Math.round((this.zona1_1_t.filter(e => e != null).length)/this.labels_semana.length * 100) + '%',
                        // borderColor: 'rgb(255, 0, 0)',
                        backgroundColor: '#00ff00',
                        data: this.zona1_1_t,
                        fill: {
                            //target: { value:50 },
                            target: 'origin',
                            above: '#00ff00',   // Area will be red above the origin
                        },
                        pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
                    },
                    {
                        label: Math.round((this.zona2_1_t.filter(e => e != null).length)/this.labels_semana.length * 100) + '%',
                        backgroundColor: '#a0a0a0',
                        data: this.zona2_1_t,
                        fill: {
                            target: 'origin',
                            above: '#a0a0a0',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: Math.round((this.zona4_1_t.filter(e => e != null).length)/this.labels_semana.length * 100) + '%',
                        backgroundColor: '#00d3ff',
                        data: this.zona4_1_t,
                        fill: {
                            target: 'origin',
                            above: '#00d3ff',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: Math.round((this.zona5_1_t.filter(e => e != null).length)/this.labels_semana.length * 100) + '%',
                        backgroundColor: '#ff9300',
                        data: this.zona5_1_t,
                        fill: {
                            target: 'origin',
                            above: '#ff9300',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: Math.round((this.zona3_1_t.filter(e => e != null).length)/this.labels_semana.length * 100) + '%',
                        backgroundColor: '#ff0000',
                        data: this.zona3_1_t,
                        fill: {
                            target: 'origin',
                            above: '#ff0000',
                        },
                        pointRadius: 0,
                    }
                ]
            }
            return datos
        },
        data_2() {
            let datos = {                
                labels: this.labels_semana_2,
                datasets: [
                    {
                        label: 'Automático',
                        // borderColor: 'rgb(255, 0, 0)',
                        backgroundColor: '#00ff00',
                        data: this.zona1_2,
                        fill: {
                            //target: { value:50 },
                            target: 'origin',
                            above: '#00ff00',   // Area will be red above the origin
                        },
                        pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
                    },
                    {
                        label: 'Parada por operario',
                        backgroundColor: '#a0a0a0',
                        data: this.zona2_2,
                        fill: {
                            target: 'origin',
                            above: '#a0a0a0',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: 'Parada alarma',
                        backgroundColor: '#ff0000',
                        data: this.zona3_2,
                        fill: {
                            target: 'origin',
                            above: '#ff0000',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: 'Mantenimiento',
                        backgroundColor: '#00d3ff',
                        data: this.zona4_2,
                        fill: {
                            target: 'origin',
                            above: '#00d3ff',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: 'Parada por falta de condiciones',
                        backgroundColor: '#ff9300',
                        data: this.zona5_2,
                        fill: {
                            target: 'origin',
                            above: '#ff9300',
                        },
                        pointRadius: 0,
                    }
                ]
            }
            return datos
        },
        data_2_t() {
            let datos = {                
                labels: this.labels_semana_2,
                datasets: [
                    {
                        label: Math.round((this.zona1_2_t.filter(e => e != null).length)/this.labels_semana_2.length * 100) + '%',
                        // borderColor: 'rgb(255, 0, 0)',
                        backgroundColor: '#00ff00',
                        data: this.zona1_2_t,
                        fill: {
                            //target: { value:50 },
                            target: 'origin',
                            above: '#00ff00',   // Area will be red above the origin
                        },
                        pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
                    },
                    {
                        label: Math.round((this.zona2_2_t.filter(e => e != null).length)/this.labels_semana_2.length * 100) + '%',
                        backgroundColor: '#a0a0a0',
                        data: this.zona2_2_t,
                        fill: {
                            target: 'origin',
                            above: '#a0a0a0',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: Math.round((this.zona4_2_t.filter(e => e != null).length)/this.labels_semana_2.length * 100) + '%',
                        backgroundColor: '#00d3ff',
                        data: this.zona4_2_t,
                        fill: {
                            target: 'origin',
                            above: '#00d3ff',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: Math.round((this.zona5_2_t.filter(e => e != null).length)/this.labels_semana_2.length * 100) + '%',
                        backgroundColor: '#ff9300',
                        data: this.zona5_2_t,
                        fill: {
                            target: 'origin',
                            above: '#ff9300',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: Math.round((this.zona3_2_t.filter(e => e != null).length)/this.labels_semana_2.length * 100) + '%',
                        backgroundColor: '#ff0000',
                        data: this.zona3_2_t,
                        fill: {
                            target: 'origin',
                            above: '#ff0000',
                        },
                        pointRadius: 0,
                    }
                ]
            }
            return datos
        },
        // data_3() {
        //     let datos = {                
        //         labels: this.labels_semana_3,
        //         datasets: [
        //             {
        //                 label: 'Automático',
        //                 // borderColor: 'rgb(255, 0, 0)',
        //                 backgroundColor: '#00ff00',
        //                 data: this.zona1_3,
        //                 fill: {
        //                     //target: { value:50 },
        //                     target: 'origin',
        //                     above: '#00ff00',   // Area will be red above the origin
        //                 },
        //                 pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
        //             },
        //             {
        //                 label: 'Parada por operario',
        //                 backgroundColor: '#a0a0a0',
        //                 data: this.zona2_3,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#a0a0a0',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: 'Parada alarma',
        //                 backgroundColor: '#ff0000',
        //                 data: this.zona3_3,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff0000',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: 'Mantenimiento',
        //                 backgroundColor: '#00d3ff',
        //                 data: this.zona4_3,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#00d3ff',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: 'Parada por falta de condiciones',
        //                 backgroundColor: '#ff9300',
        //                 data: this.zona5_3,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff9300',
        //                 },
        //                 pointRadius: 0,
        //             }
        //         ]
        //     }
        //     return datos
        // },
        // data_3_t() {
        //     let datos = {                
        //         labels: this.labels_semana_3,
        //         datasets: [
        //             {
        //                 label: Math.round((this.zona1_3_t.filter(e => e != null).length)/this.labels_semana_3.length * 100) + '%',
        //                 // borderColor: 'rgb(255, 0, 0)',
        //                 backgroundColor: '#00ff00',
        //                 data: this.zona1_3_t,
        //                 fill: {
        //                     //target: { value:50 },
        //                     target: 'origin',
        //                     above: '#00ff00',   // Area will be red above the origin
        //                 },
        //                 pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
        //             },
        //             {
        //                 label: Math.round((this.zona2_3_t.filter(e => e != null).length)/this.labels_semana_3.length * 100) + '%',
        //                 backgroundColor: '#a0a0a0',
        //                 data: this.zona2_3_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#a0a0a0',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: Math.round((this.zona4_3_t.filter(e => e != null).length)/this.labels_semana_3.length * 100) + '%',
        //                 backgroundColor: '#00d3ff',
        //                 data: this.zona4_3_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#00d3ff',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: Math.round((this.zona5_3_t.filter(e => e != null).length)/this.labels_semana_3.length * 100) + '%',
        //                 backgroundColor: '#ff9300',
        //                 data: this.zona5_3_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff9300',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: Math.round((this.zona3_3_t.filter(e => e != null).length)/this.labels_semana_3.length * 100) + '%',
        //                 backgroundColor: '#ff0000',
        //                 data: this.zona3_3_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff0000',
        //                 },
        //                 pointRadius: 0,
        //             }
        //         ]
        //     }
        //     return datos
        // },
        // data_4() {
        //     let datos = {                
        //         labels: this.labels_semana_4,
        //         datasets: [
        //             {
        //                 label: 'Automático',
        //                 // borderColor: 'rgb(255, 0, 0)',
        //                 backgroundColor: '#00ff00',
        //                 data: this.zona1_4,
        //                 fill: {
        //                     //target: { value:50 },
        //                     target: 'origin',
        //                     above: '#00ff00',   // Area will be red above the origin
        //                 },
        //                 pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
        //             },
        //             {
        //                 label: 'Parada por operario',
        //                 backgroundColor: '#a0a0a0',
        //                 data: this.zona2_4,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#a0a0a0',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: 'Parada alarma',
        //                 backgroundColor: '#ff0000',
        //                 data: this.zona3_4,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff0000',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: 'Mantenimiento',
        //                 backgroundColor: '#00d3ff',
        //                 data: this.zona4_4,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#00d3ff',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: 'Parada por falta de condiciones',
        //                 backgroundColor: '#ff9300',
        //                 data: this.zona5_4,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff9300',
        //                 },
        //                 pointRadius: 0,
        //             }
        //         ]
        //     }
        //     return datos
        // },
        // data_4_t() {
        //     let datos = {                
        //         labels: this.labels_semana_4,
        //         datasets: [
        //             {
        //                 label: Math.round((this.zona1_4_t.filter(e => e != null).length)/this.labels_semana_4.length * 100) + '%',
        //                 // borderColor: 'rgb(255, 0, 0)',
        //                 backgroundColor: '#00ff00',
        //                 data: this.zona1_4_t,
        //                 fill: {
        //                     //target: { value:50 },
        //                     target: 'origin',
        //                     above: '#00ff00',   // Area will be red above the origin
        //                 },
        //                 pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
        //             },
        //             {
        //                 label: Math.round((this.zona2_4_t.filter(e => e != null).length)/this.labels_semana_4.length * 100) + '%',
        //                 backgroundColor: '#a0a0a0',
        //                 data: this.zona2_4_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#a0a0a0',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: Math.round((this.zona4_4_t.filter(e => e != null).length)/this.labels_semana_4.length * 100) + '%',
        //                 backgroundColor: '#00d3ff',
        //                 data: this.zona4_4_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#00d3ff',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: Math.round((this.zona5_4_t.filter(e => e != null).length)/this.labels_semana_4.length * 100) + '%',
        //                 backgroundColor: '#ff9300',
        //                 data: this.zona5_4_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff9300',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: Math.round((this.zona3_4_t.filter(e => e != null).length)/this.labels_semana_4.length * 100) + '%',
        //                 backgroundColor: '#ff0000',
        //                 data: this.zona3_4_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff0000',
        //                 },
        //                 pointRadius: 0,
        //             }
        //         ]
        //     }
        //     return datos
        // },
        // data_5() {
        //     let datos = {                
        //         labels: this.labels_semana_5,
        //         datasets: [
        //             {
        //                 label: 'Automático',
        //                 // borderColor: 'rgb(255, 0, 0)',
        //                 backgroundColor: '#00ff00',
        //                 data: this.zona1_5,
        //                 fill: {
        //                     //target: { value:50 },
        //                     target: 'origin',
        //                     above: '#00ff00',   // Area will be red above the origin
        //                 },
        //                 pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
        //             },
        //             {
        //                 label: 'Parada por operario',
        //                 backgroundColor: '#a0a0a0',
        //                 data: this.zona2_5,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#a0a0a0',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: 'Parada alarma',
        //                 backgroundColor: '#ff0000',
        //                 data: this.zona3_5,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff0000',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: 'Mantenimiento',
        //                 backgroundColor: '#00d3ff',
        //                 data: this.zona4_5,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#00d3ff',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: 'Parada por falta de condiciones',
        //                 backgroundColor: '#ff9300',
        //                 data: this.zona5_5,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff9300',
        //                 },
        //                 pointRadius: 0,
        //             }
        //         ]
        //     }
        //     return datos
        // },
        // data_5_t() {
        //     let datos = {                
        //         labels: this.labels_semana_5,
        //         datasets: [
        //             {
        //                 label: Math.round((this.zona1_5_t.filter(e => e != null).length)/this.labels_semana_5.length * 100) + '%',
        //                 // borderColor: 'rgb(255, 0, 0)',
        //                 backgroundColor: '#00ff00',
        //                 data: this.zona1_5_t,
        //                 fill: {
        //                     //target: { value:50 },
        //                     target: 'origin',
        //                     above: '#00ff00',   // Area will be red above the origin
        //                 },
        //                 pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
        //             },
        //             {
        //                 label: Math.round((this.zona2_5_t.filter(e => e != null).length)/this.labels_semana_5.length * 100) + '%',
        //                 backgroundColor: '#a0a0a0',
        //                 data: this.zona2_5_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#a0a0a0',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: Math.round((this.zona4_5_t.filter(e => e != null).length)/this.labels_semana_5.length * 100) + '%',
        //                 backgroundColor: '#00d3ff',
        //                 data: this.zona4_5_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#00d3ff',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: Math.round((this.zona5_5_t.filter(e => e != null).length)/this.labels_semana_5.length * 100) + '%',
        //                 backgroundColor: '#ff9300',
        //                 data: this.zona5_5_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff9300',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: Math.round((this.zona3_5_t.filter(e => e != null).length)/this.labels_semana_5.length * 100) + '%',
        //                 backgroundColor: '#ff0000',
        //                 data: this.zona3_5_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff0000',
        //                 },
        //                 pointRadius: 0,
        //             }
        //         ]
        //     }
        //     return datos
        // },
        // data_6() {
        //     let datos = {                
        //         labels: this.labels_semana_6,
        //         datasets: [
        //             {
        //                 label: 'Automático',
        //                 // borderColor: 'rgb(255, 0, 0)',
        //                 backgroundColor: '#00ff00',
        //                 data: this.zona1_6,
        //                 fill: {
        //                     //target: { value:50 },
        //                     target: 'origin',
        //                     above: '#00ff00',   // Area will be red above the origin
        //                 },
        //                 pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
        //             },
        //             {
        //                 label: 'Parada por operario',
        //                 backgroundColor: '#a0a0a0',
        //                 data: this.zona2_6,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#a0a0a0',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: 'Parada alarma',
        //                 backgroundColor: '#ff0000',
        //                 data: this.zona3_6,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff0000',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: 'Mantenimiento',
        //                 backgroundColor: '#00d3ff',
        //                 data: this.zona4_6,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#00d3ff',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: 'Parada por falta de condiciones',
        //                 backgroundColor: '#ff9300',
        //                 data: this.zona5_6,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff9300',
        //                 },
        //                 pointRadius: 0,
        //             }
        //         ]
        //     }
        //     return datos
        // },
        // data_6_t() {
        //     let datos = {                
        //         labels: this.labels_semana_6,
        //         datasets: [
        //             {
        //                 label: Math.round((this.zona1_6_t.filter(e => e != null).length)/this.labels_semana_6.length * 100) + '%',
        //                 // borderColor: 'rgb(255, 0, 0)',
        //                 backgroundColor: '#00ff00',
        //                 data: this.zona1_6_t,
        //                 fill: {
        //                     //target: { value:50 },
        //                     target: 'origin',
        //                     above: '#00ff00',   // Area will be red above the origin
        //                 },
        //                 pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
        //             },
        //             {
        //                 label: Math.round((this.zona2_6_t.filter(e => e != null).length)/this.labels_semana_6.length * 100) + '%',
        //                 backgroundColor: '#a0a0a0',
        //                 data: this.zona2_6_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#a0a0a0',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: Math.round((this.zona4_6_t.filter(e => e != null).length)/this.labels_semana_6.length * 100) + '%',
        //                 backgroundColor: '#00d3ff',
        //                 data: this.zona4_6_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#00d3ff',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: Math.round((this.zona5_6_t.filter(e => e != null).length)/this.labels_semana_6.length * 100) + '%',
        //                 backgroundColor: '#ff9300',
        //                 data: this.zona5_6_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff9300',
        //                 },
        //                 pointRadius: 0,
        //             },
        //             {
        //                 label: Math.round((this.zona3_6_t.filter(e => e != null).length)/this.labels_semana_6.length * 100) + '%',
        //                 backgroundColor: '#ff0000',
        //                 data: this.zona3_6_t,
        //                 fill: {
        //                     target: 'origin',
        //                     above: '#ff0000',
        //                 },
        //                 pointRadius: 0,
        //             }
        //         ]
        //     }
        //     return datos
        // },
    },
    created() {
        this.DataPrportionCalc()
    },
    methods: {
        DataPrportionCalc(){
            //Tenemos guardado la maquina que tenemos que observar en id_maquina_grafico
            this.labels_semana = []
            this.zona1 = []
            this.zona2 = []
            this.zona3 = []
            this.zona4 = []
            this.zona5 = []

            this.labels_semana_1 = []
            this.zona1_1 = []
            this.zona2_1 = []
            this.zona3_1 = []
            this.zona4_1 = []
            this.zona5_1 = []

            this.labels_semana_2 = []
            this.zona1_2 = [],
            this.zona2_2 = [],
            this.zona3_2 = [],
            this.zona4_2 = [],
            this.zona5_2 = [],

            // this.zona1_3 = [],
            // this.zona2_3 = [],
            // this.zona3_3 = [],
            // this.zona4_3 = [],
            // this.zona5_3 = [],
            
            // this.zona1_4 = [],
            // this.zona2_4 = [],
            // this.zona3_4 = [],
            // this.zona4_4 = [],
            // this.zona5_4 = [],
            
            // this.zona1_5 = [],
            // this.zona2_5 = [],
            // this.zona3_5 = [],
            // this.zona4_5 = [],
            // this.zona5_5 = [],
            
            // this.zona1_6 = [],
            // this.zona2_6 = [],
            // this.zona3_6 = [],
            // this.zona4_6 = [],
            // this.zona5_6 = [],

            console.log('DataPrportionCalc');
            let primerDia = moment(Date.now()).tz('Europe/Madrid').format('YYYY-MM-DD')
            let segundoDia = moment(Date.now() - 86400000).tz('Europe/Madrid').format('YYYY-MM-DD')
            let tercerDia = moment(Date.now() - 86400000 * 2).tz('Europe/Madrid').format('YYYY-MM-DD')
            let cuartoDia = moment(Date.now() - 86400000 * 3).tz('Europe/Madrid').format('YYYY-MM-DD')
            let quintoDia = moment(Date.now() - 86400000 * 4).tz('Europe/Madrid').format('YYYY-MM-DD')
            let sextoDia = moment(Date.now() - 86400000 * 5).tz('Europe/Madrid').format('YYYY-MM-DD')
            let p1 = primerDia + ' 06:00:00'
            let p2 = segundoDia + ' 06:00:00'
            let p3 = tercerDia + ' 06:00:00'
            let p4 = cuartoDia + ' 06:00:00'
            let p5 = quintoDia + ' 06:00:00'
            let p6 = sextoDia + ' 06:00:00'

            let anterior_t = ''
            let anterior_valor = ''
            this.semana_total.forEach(element => {
                //Si hay cambio guardamos (valor o día)
                if((element.valor != anterior_valor) || this.distintoDia(anterior_t, element.created_at)){

                }

                //Datos anteriores a hoy 06:00:00
                // this.pushGeneral('2020-01-01 06:00:00', p6, element.created_at, this.labels_semana_6, element.valor, this.zona1_6, this.zona2_6, this.zona3_6, this.zona4_6, this.zona5_6)
                // this.pushGeneral(p6, p5, element.created_at, this.labels_semana_5, element.valor, this.zona1_5, this.zona2_5, this.zona3_5, this.zona4_5, this.zona5_5)
                // this.pushGeneral(p5, p4, element.created_at, this.labels_semana_4, element.valor, this.zona1_4, this.zona2_4, this.zona3_4, this.zona4_4, this.zona5_4)
                // this.pushGeneral(p4, p3, element.created_at, this.labels_semana_3, element.valor, this.zona1_3, this.zona2_3, this.zona3_3, this.zona4_3, this.zona5_3)
                // this.pushGeneral(p3, p2, element.created_at, this.labels_semana_2, element.valor, this.zona1_2, this.zona2_2, this.zona3_2, this.zona4_2, this.zona5_2)
                this.pushGeneral(p2, p1, element.created_at, this.labels_semana, element.valor, this.zona1, this.zona2, this.zona3, this.zona4, this.zona5)
                this.pushGeneral(p1, primerDia + ' 24:00:00', element.created_at, this.labels_semana_1, element.valor, this.zona1_1, this.zona2_1, this.zona3_1, this.zona4_1, this.zona5_1)
                
                anterior_t = element.created_at
                anterior_valor = element.valor
            });
            //Cálculo de tiempo total en cada zona
            let zona1_aux = this.zona1_1.filter(e => e != null)
            let zona2_aux = this.zona2_1.filter(e => e != null)
            let zona3_aux = this.zona3_1.filter(e => e != null)
            let zona4_aux = this.zona4_1.filter(e => e != null)
            let zona5_aux = this.zona5_1.filter(e => e != null)
            this.rellenarDatos(zona1_aux, zona2_aux, zona3_aux, zona4_aux, zona5_aux, this.zona1_t, this.zona2_t, this.zona3_t, this.zona4_t, this.zona5_t)
            
            let zona1_1_aux = this.zona1.filter(e => e != null)
            let zona2_1_aux = this.zona2.filter(e => e != null)
            let zona3_1_aux = this.zona3.filter(e => e != null)
            let zona4_1_aux = this.zona4.filter(e => e != null)
            let zona5_1_aux = this.zona5.filter(e => e != null)
            this.rellenarDatos(zona1_1_aux, zona2_1_aux, zona3_1_aux, zona4_1_aux, zona5_1_aux, this.zona1_1_t, this.zona2_1_t, this.zona3_1_t, this.zona4_1_t, this.zona5_1_t)
            
            // let zona1_2_aux = this.zona1_2.filter(e => e != null)
            // let zona2_2_aux = this.zona2_2.filter(e => e != null)
            // let zona3_2_aux = this.zona3_2.filter(e => e != null)
            // let zona4_2_aux = this.zona4_2.filter(e => e != null)
            // let zona5_2_aux = this.zona5_2.filter(e => e != null)
            // this.rellenarDatos(zona1_2_aux, zona2_2_aux, zona3_2_aux, zona4_2_aux, zona5_2_aux, this.zona1_2_t, this.zona2_2_t, this.zona3_2_t, this.zona4_2_t, this.zona5_2_t)
            
            // let zona1_3_aux = this.zona1_3.filter(e => e != null)
            // let zona2_3_aux = this.zona2_3.filter(e => e != null)
            // let zona3_3_aux = this.zona3_3.filter(e => e != null)
            // let zona4_3_aux = this.zona4_3.filter(e => e != null)
            // let zona5_3_aux = this.zona5_3.filter(e => e != null)
            // this.rellenarDatos(zona1_3_aux, zona2_3_aux, zona3_3_aux, zona4_3_aux, zona5_3_aux, this.zona1_3_t, this.zona2_3_t, this.zona3_3_t, this.zona4_3_t, this.zona5_3_t)
            
            // let zona1_4_aux = this.zona1_4.filter(e => e != null)
            // let zona2_4_aux = this.zona2_4.filter(e => e != null)
            // let zona3_4_aux = this.zona3_4.filter(e => e != null)
            // let zona4_4_aux = this.zona4_4.filter(e => e != null)
            // let zona5_4_aux = this.zona5_4.filter(e => e != null)
            // this.rellenarDatos(zona1_4_aux, zona2_4_aux, zona3_4_aux, zona4_4_aux, zona5_4_aux, this.zona1_4_t, this.zona2_4_t, this.zona3_4_t, this.zona4_4_t, this.zona5_4_t)
            
            // let zona1_5_aux = this.zona1_5.filter(e => e != null)
            // let zona2_5_aux = this.zona2_5.filter(e => e != null)
            // let zona3_5_aux = this.zona3_5.filter(e => e != null)
            // let zona4_5_aux = this.zona4_5.filter(e => e != null)
            // let zona5_5_aux = this.zona5_5.filter(e => e != null)
            // this.rellenarDatos(zona1_5_aux, zona2_5_aux, zona3_5_aux, zona4_5_aux, zona5_5_aux, this.zona1_5_t, this.zona2_5_t, this.zona3_5_t, this.zona4_5_t, this.zona5_5_t)
            
            // let zona1_6_aux = this.zona1_6.filter(e => e != null)
            // let zona2_6_aux = this.zona2_6.filter(e => e != null)
            // let zona3_6_aux = this.zona3_6.filter(e => e != null)
            // let zona4_6_aux = this.zona4_6.filter(e => e != null)
            // let zona5_6_aux = this.zona5_6.filter(e => e != null)
            // this.rellenarDatos(zona1_6_aux, zona2_6_aux, zona3_6_aux, zona4_6_aux, zona5_6_aux, this.zona1_6_t, this.zona2_6_t, this.zona3_6_t, this.zona4_6_t, this.zona5_6_t)
        },
        distintoDia(a, b){
            return 1
        },
        pushGeneral(finicio, ffin, tiempo_data, label, valor, z1, z2, z3, z4, z5){
            let d = new Date(tiempo_data)
            if(d > new Date(finicio) && d < new Date(ffin)){
                label.push(tiempo_data)
                switch (valor) {
                    case 1:
                        z1.push(1)
                        z2.push(null)
                        z3.push(null)
                        z4.push(null)
                        z5.push(null)
                        break;
                    case 2:
                        z1.push(null)
                        z2.push(1)
                        z3.push(null)
                        z4.push(null)
                        z5.push(null)
                        break;
                    case 3:
                        z1.push(null)
                        z2.push(null)
                        z3.push(1)
                        z4.push(null)
                        z5.push(null)
                        break;
                    case 4:
                        z1.push(null)
                        z2.push(null)
                        z3.push(null)
                        z4.push(1)
                        z5.push(null)
                        break;
                    case 5:
                        z1.push(null)
                        z2.push(null)
                        z3.push(null)
                        z4.push(null)
                        z5.push(1)
                        break;
                
                    default:
                        z1.push(null)
                        z2.push(null)
                        z3.push(null)
                        z4.push(null)
                        z5.push(null)
                        break;
                }
            }
        },
        //Las primeras 5 zonas son los datos ordenados con timestamp y los siguientes 5 son arrays que representan el tiempo global en cada zona
        rellenarDatos(z1, z2, z3, z4, z5, z1_r, z2_r, z3_r, z4_r, z5_r){
            z1.forEach(e => { 
                z1_r.push(1)
                z2_r.push(null)
                z3_r.push(null)
                z4_r.push(null)
                z5_r.push(null)
            });
            z2.forEach(e => { 
                z1_r.push(null)
                z2_r.push(1)
                z3_r.push(null)
                z4_r.push(null)
                z5_r.push(null)
            });
            z3.forEach(e => { 
                z1_r.push(null)
                z2_r.push(null)
                z3_r.push(1)
                z4_r.push(null)
                z5_r.push(null)
            });
            z4.forEach(e => { 
                z1_r.push(null)
                z2_r.push(null)
                z3_r.push(null)
                z4_r.push(1)
                z5_r.push(null)
            });
            z5.forEach(e => { 
                z1_r.push(null)
                z2_r.push(null)
                z3_r.push(null)
                z4_r.push(null)
                z5_r.push(1)
            });
        },
    },
}
</script>

<style></style>
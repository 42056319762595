<template>
	<v-container>
		<!-- fab btn to create a user -->
		<h1>Usuarios</h1>
		<v-card>
			<v-card-title>
				<v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
			</v-card-title>
			<v-data-table :headers="headers" :items="items" :search="search" :footer-props="{
				'items-per-page-text': 'Usuarios por página'
			}">

				<template v-slot:[`item.role_name`]="{ item }">
					<!-- Rol admin -->
					<v-chip class="chips" disabled color="#00008B" dark v-if="item.role_name == 'admin'" >Admin
						<v-icon right>mdi-shield-crown</v-icon>
					</v-chip>
					<!-- rol normal -->
					<v-chip class="chips" disabled color="#00008B" dark  v-else-if="item.role_name == 'normal'" >Normal
						<v-icon right >mdi-account</v-icon>
					</v-chip>
					<!-- rol avanzado -->
					<v-chip class="chips" disabled color="#00008B" dark  v-else-if="item.role_name == 'avanzado'" >Avanzado
						<v-icon right >mdi-account-plus</v-icon>
					</v-chip>
				</template>
				<template v-slot:[`item.acciones`]="{ item }">
					<v-row>
						<v-col cols="1">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">

									<v-icon v-on=on right @click="dialog2 = true; selectedItem = item" color="blue">
										mdi-account-edit-outline
									</v-icon>
								</template>
								<span>Editar Usuario</span>
							</v-tooltip>
						</v-col>
						<!-- change user password -->
						<v-col cols="1">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-icon v-on=on right @click="dialog4 = true; selectedItem = item" color="green">
										mdi-lock-reset
									</v-icon>
								</template>
								<span>Cambiar Contraseña</span>
							</v-tooltip>
						</v-col>
						<v-col cols="1">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-icon v-on=on right @click="dialog = true; selectedItem = item" color="red">
										mdi-delete
									</v-icon>
								</template>
								<span>Eliminar Usuario</span>
							</v-tooltip>
						</v-col>
					</v-row>

				</template>
			</v-data-table>
			<v-dialog v-model="dialog" persistent max-width="600">
				<v-card>
					<v-card-title>
						<span class="headline">Está seguro de que desea eliminar el usuario?</span>
					</v-card-title>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="red" text @click="dialog = false; eliminar(selectedItem)">
							Eliminar
						</v-btn>
						<v-btn color="black" text @click="dialog = false;">
							Cancelar
						</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<!-- v-dialog edit user fill with user info -->
			<v-dialog v-model="dialog2">
				<v-card elevation="2">
					<v-card-title>
						<span class="headline">Editar Usuario</span>
					</v-card-title>
					<v-card-text>
						<v-form ref="edit_form">
							<v-col>
								<v-text-field :rules="[rules.required]" v-model="selectedItem.username" label="Nombre"
									required></v-text-field>
							</v-col>
							<!-- text area email -->
							<v-col>
								<v-text-field :rules="[rules.required, rules.email]" v-model="selectedItem.email" label="Email"
									required></v-text-field>
							</v-col>
							<v-col>
								<!-- Select user role -->
								<v-radio-group v-if="isAdmin" v-model="selectedItem.role_name" label="Rol">
									<v-radio v-for="n in roles" :key="n.value" :label="n.text" :value="n.value">
										<template v-slot:label>
											{{ n.text}}<v-icon right>{{n.icono}}</v-icon>
										</template>
									</v-radio>
								</v-radio-group>
								
								<v-radio-group v-else v-model="selectedItem.role_name" label="Rol">
									<v-radio v-for="n in rolesAvanzado" :key="n.value" :label="n.text" :value="n.value">
										<template v-slot:label>
											{{ n.text}}<v-icon right>{{n.icono}}</v-icon>
										</template>
									</v-radio>
								</v-radio-group>
							</v-col>
							<v-spacer></v-spacer>
							<v-btn color="black" text @click="dialog2 = false;">
								Cancelar
							</v-btn>

							<v-btn color="blue" text @click="dialog2 = false; updateSelectedUser(selectedItem)">
								Guardar
							</v-btn>
						</v-form>
						<!-- text area name -->

					</v-card-text>
				</v-card>
			</v-dialog>
			<!-- v-dialog create user -->
			<v-dialog v-if="dialog3" v-model="dialog3">
				<v-card elevation="2">
					<v-card-title>
						<span class="headline">Crear Usuario</span>
					</v-card-title>
					<v-card-text>
						<v-form ref="create_form">
							<!-- text area name -->
							<v-col>
								<v-text-field :rules="[rules.required]" v-model="newUser.username" label="Nombre" required></v-text-field>
							</v-col>
							<!-- text area email -->
							<v-col>
								<v-text-field :rules="[rules.required, rules.email]" v-model="newUser.email" label="Email"
									required></v-text-field>
							</v-col>
							<v-col>
								<!-- text area password -->
								<v-text-field v-model="newUser.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
									:rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1"
									label="Contraseña" hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
							</v-col>

							<v-col>
								<!-- text area password -->
								<v-text-field v-model="repeatPassword" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
									:rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1"
									label="Repetir Contraseña" hint="At least 8 characters" counter
									@click:append="show1 = !show1"></v-text-field>
							</v-col>
							<v-col>
								<!-- Select user role -->
								<v-radio-group v-if="isAdmin" v-model="newUser.role_name" label="Rol">
									<v-radio v-for="n in roles" :key="n.value" :label="n.text" :value="n.value">
										<template v-slot:label>
											{{ n.text}}<v-icon right>{{n.icono}}</v-icon>
										</template>
									</v-radio>
								</v-radio-group>
								
								<v-radio-group v-else v-model="newUser.role_name" label="Rol">
									<v-radio v-for="n in rolesAvanzado" :key="n.value" :label="n.text" :value="n.value">
										<template v-slot:label>
											{{ n.text}}<v-icon right>{{n.icono}}</v-icon>
										</template>
									</v-radio>
								</v-radio-group>
							</v-col>

							<v-spacer></v-spacer>
							<v-btn color="black" text @click="dialog3 = false;">
								Cancelar
							</v-btn>

							<v-btn color="blue" text @click="dialog3 = false; createUser(newUser)">
								Guardar
							</v-btn>
						</v-form>

					</v-card-text>
				</v-card>


			</v-dialog>
			<!-- v-dialog change password -->
			<v-dialog v-if="dialog4" v-model="dialog4">
				<v-card elevation="2">
					<v-card-title>
						<span class="headline">Cambiar Contraseña</span>
					</v-card-title>
					<v-card-text>
						<v-form ref="change_password_form">
							<v-col>
								<!-- text area password -->
								<v-text-field v-model="selectedItem.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
									:rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1"
									label="Nueva Contraseña" hint="At least 8 characters" counter
									@click:append="show1 = !show1"></v-text-field>
							</v-col>

							<v-col>
								<!-- text area password -->
								<v-text-field v-model="repeatPassword" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
									:rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1"
									label="Repetir Contraseña" hint="At least 8 characters" counter
									@click:append="show1 = !show1"></v-text-field>
							</v-col>

							<v-spacer></v-spacer>
							<v-btn color="black" text @click="dialog4 = false;">
								Cancelar
							</v-btn>

							<v-btn color="blue" text @click="dialog4 = false; changeSelectedUserPassword(selectedItem)">
								Guardar
							</v-btn>
						</v-form>

					</v-card-text>
				</v-card>
			</v-dialog>
		</v-card>
		<v-col class="text-right">
			<v-fab-transition>
				<v-btn @click="dialog3 = true" fab large dark bottom color="blue" left class="v-btn--example">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-fab-transition>
		</v-col>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	data() {
		return {
			search: null,
			headers: [
				{ text: "Usuario", value: "username" },
				{ text: "Tipo", value: "role_name" },
				{ text: "Email", value: "email" },
				{ text: "Acciones", value: "acciones" }
			],
			roles:[
				{ text: "Admin", icono: 'mdi-shield-crown', value:'admin'},
				{ text: "Normal", icono: 'mdi-account', value:'normal'},
				{ text: "Avanzado", icono: 'mdi-account-star', value:'avanzado'},
			],
			rolesAvanzado:[
				// { text: "Admin", icono: 'mdi-shield-crown', value:'admin'},
				{ text: "Normal", icono: 'mdi-account', value:'normal'},
				{ text: "Avanzado", icono: 'mdi-account-star', value:'avanzado'},
			],
			dialog: false, //delete user
			dialog2: false, //edit user
			dialog3: false, //create user
			dialog4: false, //change password
			show1: false, //password
			repeatPassword: '',
			rules: {
				required: value => !!value || 'Required.',
				min: v => v.length >= 8 || 'Min 8 characters',
				email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
			},
			newUser: {
				username: "",
				email: "",
				password: "",
				role_name: "normal",
			},
			selectedItem: {
				username: "",
			},
			items: [],
			isAdmin: false,
		};
	}, //Fin data

	computed: {
		...mapGetters(['getIsAdmin'])
	},

	created() {
		this.isAdmin = this.getIsAdmin
		this.obtenerUsuarios()
	},

	methods: {
		...mapActions('api', ['getUsers', 'inactivateUser', 'updateUserInformation', 'changeUserPassword', 'postUser']),

		async obtenerUsuarios(){
			const users = await this.getUsers();
			let usuarios = users.data;

			//Con el rol admin mostramos todos los usuarios
			//Con el rol avanzado no mostramos los usuarios admin
			usuarios.forEach(e => {
				e.password = ''
				if(this.isAdmin){
					this.items.push(e)
				}else{
					if(e.role_name != 'admin'){
						this.items.push(e)
					}
				}
			});
		},

		async eliminar(item) {
			console.log(item);
			await this.inactivateUser(item.user_id);
			this.items = this.items.filter(e => e.user_id != item.user_id);
		},

		async updateSelectedUser(item) {
			console.log(item);
			//if item.password == repeatPassword
			try {
				if (this.$refs.edit_form.validate()) {
					await this.updateUserInformation(item);
					this.items = this.items.map(e => e.user_id == item.user_id ? item : e);
				}
				else {
					alert("Campos no validos, revisa el formulario");
				}
			}
			catch (error) {
				console.log(error);
				alert("Error al actualizar el usuario");
			}

		},
		async changeSelectedUserPassword(item) {
			console.log(item);
			//if item.password == repeatPassword
			try {
				if (item.password == this.repeatPassword && this.$refs.change_password_form.validate()) {
					await this.changeUserPassword(item);
					this.items = this.items.map(e => e.user_id == item.user_id ? item : e);
				}
				else {
					alert("Campos no validos, revisa el formulario");
				}
			}
			catch (error) {
				console.log(error);
				alert("Error al actualizar el usuario");
			}

		},
		async createUser(item) {
			try {
				console.log(item);
				// if item.password == repeatPassword
				if (item.password == this.repeatPassword && this.$refs.create_form.validate()) {
					await this.postUser(item);
					const users = await this.getUsers();
					this.items = users.data;
					this.items.forEach(e => e.password = '');
					this.repeatPassword = '';
					this.newUser = {
						username: "",
						email: "",
						password: "",
						role_name: "normal",
					};
				}
				else {
					alert("Campos no validos, revisa el formulario");
				}
			}
			catch (error) {
				console.log(error);
				alert("Error al crear el usuario");
			}

		}
	} //Fin methods
}
</script>
<style>
.chips{
	width:100px;
}


</style>
<template lang="es">
  <v-card text>
    <v-list>
      <v-list-item-group>
        <v-list-item>
          <v-list-item-content>
            <koiote-color-picker-vue titulo="Border color" 
            :pcolor="component_selected.component_props.customizables.border_color"
            @event="cambio_border_color"
          
            ></koiote-color-picker-vue>
          </v-list-item-content>
        </v-list-item>
        <v-checkbox
        color="info"
        hide-details
        class='ml-2'
        v-model="component_selected.component_props.customizables.round"
        label="Formato circular"
        >
        </v-checkbox>

        <!-- Select if it navigates on click -->
        <v-checkbox
        color="info"
        hide-details
        class='ml-2'
        v-model="component_selected.component_props.customizables.navigate"
        label="Navegar"
        >
        </v-checkbox>

         <!-- Select where is going to redirect the nav component -->

         <v-list-item v-if="component_selected.component_props.customizables.navigate">
          <v-list-item-content>
            <h4 class="text-center mt-3">Destino</h4>
            <v-select
              v-model="next"
              :items="getPages"
              item-text='name'
              return-object
              :label="Object.keys(next).length >0 ? next.name : 'Seleccionar'"
              solo
            ></v-select>
          </v-list-item-content>
        </v-list-item>

        <!-- Show value -->
        <v-list-item>
          <v-list-item-content>
            Valor actual: {{component_selected.component_props.customizables.value}}
          </v-list-item-content>
        </v-list-item>
      <v-list-item>
      <!-- select a int value -->

      <v-list-item-content>
        <!-- <v-list-item-title> -->
          <v-btn block @click="shuffle_mostrar_valores">Imágenes</v-btn>
        <!-- </v-list-item-title> -->
        <v-simple-table v-if="mostrar_valores">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Valor</th>
                <th>Imagen</th>
              </tr>
            </thead>
            <tbody>
              <!-- v-for="(item,index) in component_selected.component_props.customizables.values.sort((a,b)=>a-b)" -->

              <tr
              v-for="(item,index) in component_selected.component_props.customizables.values"
                :key="index"
              >
              <td>{{item}}</td>
              <td>
                <span>
                  <v-img 
                  :src="getSelectedImage(item)"
                  height="32px"
                  width="32px"
                  @click="cambiar_imagen(item)"

                ></v-img>

                </span>
                
                <v-file-input
              
                  accept="image/*"
                  label="Imagen"
                  dense
                  background-color="white"
                  :id="refFileInput(item)"
                  ref="refFileInput"
                  :class="clase_valor(item)"
                  @change="fileChangedInItem(item,$event)"
                ></v-file-input>
              </td>
              </tr>
              <!-- <tr>
                <td>
                  <v-text-field @keyup.enter="guardar_valor" v-model="new_value"></v-text-field>
                </td>
                <td>
                  <span>{{pendiente}}</span>
                </td>
              </tr> -->
            </tbody>
          </template>

        </v-simple-table>
          
      </v-list-item-content>
          </v-list-item>
          <!-- display the image from above -->
          <v-list-item v-if="url">
              <v-list-item-content>
                  <v-img
                      :src="url"
                      :alt="url"
                      width="100%"
                      height="100%"
                      contain
                  ></v-img>
              </v-list-item-content>
          </v-list-item>

      </v-list-item-group>
  </v-list>
</v-card>

</template>
<script>
import KoioteColorPickerVue from '@/components/custom/KoioteColorPicker.vue';
import { mapActions,mapGetters, mapState } from "vuex";
export default {
  name: "KoioteImageSwitchHoverProperties",
  computed: {
    ...mapState(["component_selected"]),
    ...mapGetters(['getPages']),

  },
  components:{
    KoioteColorPickerVue
  },
  methods: {
    ...mapActions(["updateComponent"]),
    cambio_border_color(nc){
      this.component_selected.component_props.customizables.border_color = nc
    },
    
    fileChangedInItem(it, e) {
      if (e != null) {

        const reader = new FileReader();
        reader.onload = (event) => {
          //si ya existe imagen para ese valor, la reemplaza
          if (this.component_selected.component_props.customizables.setted_values.includes(it)) {
            this.component_selected.component_props.customizables.images[
              this.component_selected.component_props.customizables.setted_values.indexOf(it)
            ] = event.target.result;
          } else {
            //si no existe, la agrega
            this.component_selected.component_props.customizables.images.push(event.target.result);
            this.component_selected.component_props.customizables.setted_values.push(it);
          }
          //this.url = event.target.result;
          this.component_selected.component_props.customizables.images.splice();
          this.component_selected.component_props.customizables.setted_values.splice();
        };

        reader.readAsDataURL(e);
      } else {   // si se borra la imagen
        if (this.component_selected.component_props.customizables.setted_values.includes(this.value)) {
          this.component_selected.component_props.customizables.images.splice(this.component_selected.component_props.customizables.setted_values.indexOf(this.value), 1)
          this.component_selected.component_props.customizables.setted_values.splice(this.component_selected.component_props.customizables.setted_values.indexOf(this.value), 1)

        }

      }
    },

    updateSelectedImage(value) {
      //updates the image that is being displayed in the toolbar
      if (this.component_selected.component_props.customizables.setted_values.includes(value)) {
        this.url =
          this.component_selected.component_props.customizables.images[
          this.component_selected.component_props.customizables.setted_values.indexOf(value)
          ];
      } else {
        this.url = null;
      }
    },
    clase_valor(it) {
      return this.isImageSeted(it) ? "d-none" : "";
    },
    refFileInput(it) {
      return `file_input_${it}`
    },
    cambiar_imagen(it) {
      const ref = this.refFileInput(it)
      let file_selected = document.getElementById(ref)

      file_selected.click()
    },


    isImageSeted(value) {
      return (this.component_selected.component_props.customizables.setted_values.includes(value))
    },

    getSelectedImage(value) {
      if (this.component_selected.component_props.customizables.setted_values.includes(value)) {
        return this.component_selected.component_props.customizables.images[
          this.component_selected.component_props.customizables.setted_values.indexOf(value)
        ];
      }

    },
    shuffle_mostrar_valores() {
      this.mostrar_valores = !this.mostrar_valores;
    },
  },
   
  mounted() {
    if(this.component_selected.component_props.customizables.next_page != "" && this.component_selected.component_props.customizables.next_page != undefined){
      this.next = this.getPages.find(e => e.id == this.component_selected.component_props.customizables.next_page)
    }
  },
  data() {
    return {
      //background
      next:{},
      addvaluemenu: false,
      url: null,
      value: null,
      mostrar_valores: false,

    };
  },
  watch:{
    next(){
      if(Object.keys(this.next).length > 0 && this.next.id != this.component_selected.component_props.customizables.next_page){
        this.component_selected.component_props.customizables.next_page = this.next.id
      }
    }
  }
};
</script>
<style >
.imagen_pequeña{
  position:absolute;
  width:100%;
  height: 100%;
}
</style>
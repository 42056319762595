<template>
  <div>
    <div class="fondo_componente chaflan">

      <div v-if="myProps.customizables.value > 0">
        <!-- <div class="componente_interno" :style="{ height:alturaCalculada,  top:topCalculado, 'background-color':bgColor }" -->
        <div class="componente_interno"
          :style="{ 'height': alturaCalculada, 'top': topCalculado, 'background': createBackgroundString }">

          <!-- <span :class="myProps['posicion_texto']">{{myProps['titulo']}} {{myProps['nombre']}}</span> -->
          <!-- {{ myProps.customizables.topic }} -->
          <div v-if="myProps.customizables.show_value">

            {{ myProps.customizables.value }}
          </div>

        </div>

      </div>


    </div>

  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: "KoioteSilo1",
  props: {
    myProps: Object
  },

  computed: {
    ...mapState('nodered', ['message','ListOfmessages']),
    ...mapState(['component_selected']),
    ...mapGetters('nodered', ['getConnections']),
    bufferPos(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'BufferPos'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    dataType(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'VarType'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    myPlc(){
      if(this.myProps.customizables.topic != ""){
        return this.getConnections.find(e => e.id == this.myProps.customizables.plc)
      }
    },
    createBackgroundString() {
      let backString = `linear-gradient(90deg`
      if (this.myProps.customizables['colores']) {
        this.myProps.customizables['colores'].forEach(element => {
          backString = backString + `, ${element['color']} ${element['porcentaje']}`
        });
      }
      return backString
    },
    value() {
      let miMensaje = this.ListOfmessages.find(a=>a.topic==this.myProps.customizables.topic)
      if(miMensaje){
        if(this.myPlc && Object.keys(this.myPlc).length > 0 ){
          let functionName = this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
          functionName = 'readValueFrom'+functionName
          return this[functionName](miMensaje,this.myProps.customizables, this.bufferPos, this.dataType, this.myPlc)
        }
      }else{
        // console.log('No hay mensaje de ese topic :(');
      }
    },
    valorMaximo() {
      return this.myProps.customizables['valorMaximo']
    },
    topCalculado() {
      let porcentaje = this.value / this.valorMaximo * 100
      return `${100 - porcentaje}%`
    },
    alturaCalculada() {
      let porcentaje = this.value / this.valorMaximo * 100
      return `${porcentaje}%`
    },

    // formated_value() {
    //   return this.calculateVarType(this.myProps.customizables) == 'bit' ? parseInt(this.myProps.customizables.value) :
    //     (parseFloat(this.myProps.customizables.value) * (this.myProps.customizables.scale)).toFixed(this.myProps.customizables.decimals)
    // }
  },
  watch: {
    value() {
     
      if (this.value != undefined) {
        this.$emit('cambioValor', { id: this.myProps.id_draggable, value: this.value })
      }
    }
  }
}
</script>
<style scoped>
.fondo_componente {
  background: linear-gradient(90deg, rgb(153, 153, 158) 0%, rgb(222, 226, 228) 51%, rgb(107, 112, 113) 100%) !important;
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0%;
  top: 0%;
  border: 1px black;
}

.componente_interno {
  position: absolute;
  color: aliceblue;
  text-align: center;
  background: rgb(2, 0, 36);
  /* background-color: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgb(33, 74, 235) 51%, rgba(2,35,42,1) 100%); */
  left: 0%;
  width: 100%;
}

.chaflan {
  clip-path: polygon(63.12% 0%,
      31.56% 0.25%,
      0% 0.25%,
      0% 77.7%,
      27.75% 98.75%,
      72.25% 98.75%,
      99.5% 77.7%,
      99.5% 0%);
}

.texto_en_silo {

  color: currentColor;
  mix-blend-mode: difference;

  position: absolute;
  z-index: 20;

  top: -10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 
.chaflan::before{
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  border-top: 20px solid #000000;
  border-right: 20px solid transparent;
}

.chaflan::after{
  content: '';
  position: absolute;
  bottom: -20px;
  right: -20px;
  border-bottom: 20px solid #000000;
  border-left: 20px solid transparent;
} */
</style>
<template>
  <div>
    <!-- <h4 class="text-center mt-3">Seleccione el PLC</h4> -->
    <v-select
      v-model="plc"
      class="ml-3 mr-3"
      label="Seleccione el PLC"
      :items="getConnections"
      :item-text="item => 'PLC'+item.id +' - '+ item.name"
      return-object
    ></v-select>
    <omron-variable-selection v-if="plc != {name:'(Seleccionar)'} && plc.type =='FINS Connection'"></omron-variable-selection>
    <ibh-link-variable-selection v-if="plc != {name:'(Seleccionar)'} && plc.type == 'IBHLink Connection'"></ibh-link-variable-selection>
  </div>

</template>
<script>

import { mapGetters, mapActions, mapState } from 'vuex';
export default {
    data() {
        return {
            plc: {
                name: "(Seleccionar)",
            },
        };
    },
    created() {
        if (this.component_selected.component_props.customizables.plc != null) {
            this.plc = this.getConnections.find(e => e.id == this.component_selected.component_props.customizables.plc);
        }
    },
    methods: {
        ...mapActions(["addModifiedDraggable"]),        
    },
    computed: {
        ...mapState(["component_selected"]),
        ...mapGetters("nodered", ["getConnections", "existsInNodered", "getTopic"]),
    },
    watch: {
        component_selected() {
            if (this.component_selected.component_props.customizables.plc != null) {
              this.plc = this.getConnections.find(e => e.id == this.component_selected.component_props.customizables.plc);
            }
            else {
              this.plc = {
                name: "(Seleccionar)",
            };
            }
        },
        plc() {
          if(this.plc.id != undefined){
            console.log("Cambio plc a: " + this.plc.id);
            this.component_selected.component_props.customizables.plc = this.plc.id;
          }
        },
    },
}
</script>
<style>
h4{
  margin:5%;
}
</style>
<template lang="es" >
    <div @click="mostrar_variables(true)"
    >
     <!-- @mouseover="myProps.customizables.hover_function?callDynamicFunction(myProps.customizables):null" 
    @mouseleave="myProps.customizables.hover_function?callDynamicFunction(myProps.customizables):null"   -->
        <v-tooltip  bottom>
            <template v-slot:activator="{ on, attrs }">
                <!-- Lo que vemos a la hora de editar -->
                <div v-bind="attrs" 
                @mouseover="getIsPlayMode && myProps.customizables.hover_function?mouseFunction(true):null" 
                @mouseleave="getIsPlayMode && myProps.customizables.hover_function?mouseFunction(false):null" 
                v-on="on">
                     <span v-if="!isPlayMode">
                        G
                        <!-- valor actual: {{ parseInt(myProps.customizables.value) }} -->
                        <!-- {{ maquinas }} -->
                        <!-- {{ datos }} -->
                    </span> 
                    <!-- En modo play mostramos el mismo texto pero casi invisible mediante css -->
                    <span v-else class="text" >
                        G
                        <!-- valor actual: {{ parseInt(myProps.customizables.value) }} -->
                    </span>
                </div>
            </template>

           Tiempo en cada estado
            <span>
                <v-card v-if="chartDataAqui && getIsPlayMode" width="300" height="150">
                    <!-- <v-card-title> Actualmente en Automatico </v-card-title> -->
                    <grafica-quesito :chartData="chartDataAqui" :loading="loading"></grafica-quesito>
                </v-card>
            </span>
        </v-tooltip>
        
        <v-dialog
            v-model="editar_variables"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card v-if=editar_variables>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="cerrar()"> <v-icon>mdi-close</v-icon> </v-btn>
                    <v-toolbar-title>{{ myProps.component_name }}</v-toolbar-title>
                </v-toolbar>

                <grafica-zonas-semanal
                    :semana_total="historicos_semana"
                    :title="myProps.component_name"
                >
                </grafica-zonas-semanal>
                <!-- <v-overlay :value="loading">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-overlay> -->
                
                <!-- Actualizar campos -->
                {{historicoSemana}}
                <v-container>
                    <v-container>
                        <v-layout justify-center align-center>
                            <v-card>
                                <v-img width="900" justify="center" src="@/assets/LEYENDA_P1_y_P2.png" transition="scale-transition" />
                            </v-card>
                        </v-layout>
                    </v-container>
                </v-container>
            </v-card>
        </v-dialog>    
    </div>
</template>

<script>
import GraficaZonasSemanal from './GraficaZonasSemanal.vue';
import GraficaQuesito from './GraficaQuesito.vue';
import { mapGetters, mapActions, mapState } from "vuex";
import moment from 'moment-timezone';

export default {
    name: "KoioteGraficoQuesito",
    props: {
        myProps: Object
    },
    components: {
        GraficaQuesito, GraficaZonasSemanal,
    },
    data() {
        return {
            datos: [1, 1, 1, 1, 1],
            maquinas: [],
            id_maquina_grafico: -1,
            historicos: [],
            historicos_semana: [],
            nuevo_objeto: null,
            loading: true,
            play: false,
            editar_variables:false,
            labels: [],
        }
    },
    watch: {
        value() {
            if (this.value != undefined) {
                this.$emit('cambioValor', { id: this.myProps.id_draggable, value: this.value })
            }
        },
        play(){
            console.log('Damos al play!!!');
        },
        historicosBack(){
            this.getMachines()
            this.getHistorico()
        },
    },
    computed: {
        ...mapState(['client'], ['component_selected']),
        ...mapState('nodered', ['ListOfmessages']),
        ...mapGetters(["getIsPlayMode"]),
        ...mapGetters('nodered', ['getConnections']),
        ...mapGetters('api', ['getMaquinas','getHistoricos8','getHistoricoSemana']),

        historicoSemana(){
            const data = this.getHistoricoSemana
            
            //return data.filter(e => e.id_estado == this.id_maquina_grafico)
            this.historicos_semana = data.filter(e => e.id_estado == this.id_maquina_grafico)
            //this.DataPrportionCalc()
            return ''
        },
        historicosBack(){
            return this.getHistoricos8
        },
        isPlayMode() {
            this.play = this.getIsPlayMode
            return this.getIsPlayMode;
        },
        chartDataAqui() {
            let chartData = {
                labels: ['Automático', 'Parada sin alarma', 'Parado alarma', 'Mantenimiento','Falta condiciones'],
                datasets: [
                    {
                        backgroundColor: ['#00ff00', '#a0a0a0', '#ff0000', '#00d3ff','#ff9300'],
                        data: this.datos
                        // data: [40, 50, 50, 10]
                    }
                ]
            }
            return chartData
        },
        bufferPos(){
            if(this.myPlc){
                let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
                let functionName = 'calculate'+plcType+'BufferPos'
                return this[functionName](this.myProps.customizables, this.myPlc)
            }
        },
        dataType(){
            if(this.myPlc){
                let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
                let functionName = 'calculate'+plcType+'VarType'
                return this[functionName](this.myProps.customizables, this.myPlc)
            }
        },
        myPlc(){
            if(this.myProps.customizables.topic != ""){
                return this.getConnections.find(e => e.id == this.myProps.customizables.plc)
            }
        },
        value() {
            let miMensaje = this.ListOfmessages.find(a=>a.topic==this.myProps.customizables.topic)
            if(miMensaje){
                if(this.myPlc && Object.keys(this.myPlc).length > 0 ){

                let functionName = this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
                functionName = 'readValueFrom'+functionName
                return this[functionName](miMensaje,this.myProps.customizables, this.bufferPos, this.dataType, this.myPlc)
                }
            }else{
                // console.log('No hay mensaje de ese topic :(');
            }
        },
    },//Fin computed
    methods: {
        ...mapActions(['callDynamicFunction','setModalActivo']),

        getMachines(){
            const data = this.getMaquinas
            try {
                data.data.results.forEach(element => {
                this.maquinas.push(element)
                //Guardamos el id de BBDD para historicos de la maquina
                if(this.myProps.customizables.variable === element.direccion){
                    this.id_maquina_grafico = element.id
                } //Guardamos ir de la maquina/variable
            });
            } catch (error) {
                console.log(error)
            }
          
        },
        mouseFunction(v){
            this.myProps.customizables.hover_function_payload.value = v 
            this.callDynamicFunction(this.myProps.customizables)
        },
        getHistorico(){
            const data = this.getHistoricos8
            try {
                data.data.results.forEach(element => {
                if(element.id_estado == this.id_maquina_grafico) this.historicos.push(element)
            });
            } catch (error) {
                console.log(error)
                
            }
           
            this.PieDataProportionCalc()
            this.loading = false
        },
        PieDataProportionCalc(){
            //Tenemos guardado la maquina que tenemos que observar en id_maquina_grafico
            this.datos = [0,0,0,0,0]
            this.historicos.forEach(element => {
                this.labels.push(element.created_at)
                switch (element.valor) {
                    case 1:
                        this.datos[0] += 1
                        break;
                    case 2:
                        this.datos[1] += 1
                        break;
                    case 3:
                        this.datos[2] += 1
                        break;
                    case 4:
                        this.datos[3] += 1
                        break;
                    case 5:
                        this.datos[4] += 1
                        break;
                
                    default:
                        break;
                }
            });
        },
        mostrar_variables(v){
            this.editar_variables = !this.editar_variables
            // this.DataPrportionCalc()
            this.setModalActivo(v)
        },
        cerrar(){
            this.editar_variables=false;
            this.setModalActivo(false);
        },
    },//Fin methods
    async mounted() {
        //this.getHistorico7dias()
        await this.getMachines()
        await this.getHistorico()
    },
}
</script>

<style>
.completo {
    height: 100%;

}


.conexion {
    text-align: center;
    border: dotted 2px black;
}

.text {
    font-family: Garamond, serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.01);
    z-index:-1!important;
}
.mi_grafico{
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0)!important;
    z-index: 20;

}
</style>
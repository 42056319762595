import KoioteImageSwitch from './KoioteImageSwitch.vue'
import KoioteImageSwitchProperties from './KoioteImageSwitchProperties.vue'
import KoioteImageSwitch1Property from './default.json'

export default {
    install(Vue,options) {
        Vue.component('koiote-image-switch', KoioteImageSwitch);
        Vue.component('koiote-image-switch-properties', KoioteImageSwitchProperties);
        Vue.prototype.$koioteImageSwitch1Property = KoioteImageSwitch1Property;

        Vue.mixin({
            mounted() {
             // console.log('Montadito!');
            }
          });
       }
  };
  

<template lang="">
    <v-container class="rounded">
        <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Página </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
    <v-divider class="mb-4"></v-divider>

        <v-text-field
            :label="getSelectedPage.name"
            placeholder="Nuevo Nombre"
            v-model="new_title"
            
        /> 
        <v-checkbox
            class="mb-5"
            label="Página principal"
            color="info"
            v-model="getSelectedPage.is_main_page"
            :disabled="getSelectedPage.is_main_page ?true:false"
            @click="changeToMainPage"
            hide-details
          ></v-checkbox>
          <v-text-field
          prefix="z:"
          v-model="zIndex"
          type="number"
          >
         
          </v-text-field>
        <v-file-input v-if="!getSelectedPage.image || getSelectedPage.image == null" accept="image/*" 
            label="Imagen de fondo"
            @change="onFileChange"
            v-model="image"
        >
        </v-file-input>
        <div v-else class=" mb-5 text-center">
            <v-btn text color="primary" @click="deleteImage">Cambiar imagen</v-btn>
        </div>
        <v-btn block
       @click="deleteThisPage"
       color="error"
       >
        Eliminar
       </v-btn>
       </v-container>
</template>
<script>
import ImagePreviewMixin from "@/mixins/ImagePreviewMixin";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
    props:{
        page:Object
    },

    data() {
        return {
            image:null,
            new_title: "",
            modifyed:false,
            isMainPage:false,
            zIndex:0
        }
    },//fin de data

    mixins: [ImagePreviewMixin],

    methods: {
        ...mapActions(['setImageToSelectedPage','setPages','unselectPage','addModifiedPage']),
        // ...mapActions('nodered',['changeFlowName']),
        ...mapActions('api',['putPageImage','deletePage','deletePageImage']),
        async deleteImage(){
            this.getSelectedPage.image = null
            this.image = null
            await this.deletePageImage(this.getSelectedPage.id)
        },
        async deleteThisPage(){
            await this.deletePage(this.getSelectedPage.id)        
            this.setPages(this.getPages.filter(item => item != this.getSelectedPage))
            this.unselectPage()
        },
        changeToMainPage(e){
            this.getPages.forEach(element => { // quitamos la pagina que sea principal y la asignamos a esta
                if(element != this.getSelectedPage && element.is_main_page == true){
                    element.is_main_page = false
                    this.addModifiedPage(element)
                }
            });
            this.addModifiedPage(this.getSelectedPage)
        }
    },
    computed: {
        ...mapGetters(['getSelectedPage','getPages']),
        ...mapState(['modified_pages','selected_page'])
    },
    mounted(){
        this.new_title = this.getSelectedPage.name
        this.zIndex = this.getSelectedPage.z_index
    },
    watch: {
        getSelectedPage(){
            if(Object.keys(this.getSelectedPage).length != 0 ){
                this.new_title = this.getSelectedPage.name
            }else{
                this.new_title = ""
            }
        },
        new_title(){
            if(this.new_title != this.getSelectedPage.name){
                this.getSelectedPage.name = this.new_title
                this.addModifiedPage(this.getSelectedPage)
            }
        },
        zIndex(){
            if(this.zIndex != this.getSelectedPage.z_index){
                this.getSelectedPage.z_index = this.zIndex
                this.addModifiedPage(this.getSelectedPage)
            }
        },
        async image(){
            if(this.image != null){
                console.log(this.getSelectedPage.image)
                await this.putPageImage({id: this.getSelectedPage.id, image:this.image})
            }
        },
        selected_page:{
            handler(newValue, oldValue) {
                if (!_.isEqual(this.selected_page, newValue)) {
                    this.addModifiedPage(this.selected_page)
                }
            },
            deep:true,

        }
    }
    
}
</script>
<style>
    .boton{
        margin-top:5%;
    }
</style>
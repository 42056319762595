<!-- 
Elemento que se visualiza en la lista de páginas creadas.
-->
<template lang="">
    <v-list-item-content >
        <v-list-item-title > {{page.name}} <v-icon class="mb-1 ml-2" v-if="page.is_main_page" color="black">mdi-home</v-icon></v-list-item-title>
    </v-list-item-content>
    
</template>
<script>
export default {
    props:{
        page:Object
    },

}
</script>
<style lang="">
    
</style>
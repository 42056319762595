/*Módulo para control de variables en PLCs
Cada PLC, independientemente de la marca, va a tener un array de variables que va a poder leer o escribir
En definitiva, va a contar con un array de Objectos
*/


const state = {
    // users: [],
    // currentUser: null
    plcs:[]
  };
  
  const mutations = {
    // SET_USERS(state, users) {
    //   state.users = users;
    // },
    // SET_CURRENT_USER(state, user) {
    //   state.currentUser = user;
    // }
    ADD_PLC(state,plc){
        state.plcs.push(plc)
    }
  };
  
  const actions = {
    // fetchUsers({ commit }) {
    //   // Perform an asynchronous operation to fetch users
    //   // Then commit the mutation to update the state
    //   const users = [/* fetched users */];
    //   commit('SET_USERS', users);
    // },
    // setCurrentUser({ commit }, user) {
    //   commit('SET_CURRENT_USER', user);
    // }
    addPlc({commit},plc){
        commit('ADD_PLC',plc)
    },
    addVariable2PLC({commit},payload){
        
    }
  };
  
  const getters = {
    // getUsers: state => state.users,
    // getCurrentUser: state => state.currentUser
    getPLCs: state=>state.plcs
  };
  
  export default {
    state,
    mutations,
    actions,
    getters
  };
  
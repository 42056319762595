<template lang="es">

    <!-- <div>
      <h4 class="text-center mt-3">Nombre</h4>
      <v-text-field
        class="ml-3 mr-3"
        v-model="nuevo_objeto.component_name"
        single-line
        :disabled = nuevo_objeto.customizables.connected
      />
    </div> -->
    <div>
      <h4 class="text-center mt-3">Nombre</h4>
      <v-text-field
        class="ml-3 mr-3"
        v-model="component_selected.component_props.component_name"
        single-line
        :clearable=true
        :clear-icon-cb="component_selected.component_props.component_name == null? changeEditing(true):null"
        @click="changeEditing(true)"
        @blur="changeEditing(false)"
      />
      <koiote-hover-function></koiote-hover-function>
  </div>
</template>

<script>
import KoioteHoverFunction from '@/components/custom/KoioteHoverFunction.vue';
import { mapActions, mapState } from 'vuex';
export default {
  name: "KoioteGraficoQuesitoProperties",
  data() {
    return {
      // nuevo_objeto: null,
    }
  },
  components:{
    KoioteHoverFunction

  },
  computed: {
    ...mapState(['component_selected']),
  },
  watch: {
    // component_selected() {
    //   this.nuevo_objeto = JSON.parse(JSON.stringify(this.component_selected.component_props));
    // },
  },
  methods: {
    ...mapActions([ 'changeEditing']),
  },
  // created() {
  //   this.nuevo_objeto = JSON.parse(JSON.stringify(this.component_selected.component_props));
  // },
}
</script>

<style lang="">
</style>
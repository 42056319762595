import { mapState, mapGetters } from 'vuex';

const ibhLinkReading = {
  computed:{
    ...mapGetters('nodered',['getConnections']),
    ...mapState('nodered',['message'])
  },
  methods: {
    readValueFromIBHLinkConnection(data){
      if(data.topic == 'HornoEncendido')
        return this.ibhLinkReadEstadoHorno()
      else if(data.topic == 'HornoPrograma') 
        return this.ibhLinkReadPrograma()
      else if(data.topic == 'HornoTemperatura') 
        return this.ibhLinkReadTemperatura()
      else return 'WRONGTOPIC'
    },
    ibhLinkReadEstadoHorno() {
      if(Object.keys(this.message).length > 0 && this.message.topic == 'HornoEncendido' ){
        //estado = 1 --> encnedido; estado = 0 --> apagado
        if(this.message.message != 'ERROR')
          return this.message.message
        else  
          console.log('Error en la lectura de la variable de encendido/apagado')
      }
    },
    ibhLinkReadPrograma() {
      if(Object.keys(this.message).length > 0 && this.message.topic == 'HornoPrograma' ){
        // devuelve un objeto con: numPrograma (numero de programa) y un array con los puntos para el grafico (temperatura hora y minuto)
          return this.message.message
      }
    },
    ibhLinkReadTemperatura() {
      if(Object.keys(this.message).length > 0 && this.message.topic == 'HornoTemperatura' ){
        // Devuelve un float cada segundo con la temperatura del horno
        if(this.message.message != 'ERROR')
          return this.message.message
        else  
          console.log('Error en la lectura de la variable de encendido/apagado')
      }
    }
  }
}
export default ibhLinkReading;

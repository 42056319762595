<template>
  <v-card text>
    <v-list>
      <v-list-item-group>
        <v-list-item>
          <v-list-item-content>
            Valor actual: {{ component_selected.component_props.customizables.value }}
          
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <koiote-color-picker-vue
              titulo="Background color"
              :pcolor="component_selected.component_props.customizables.background_color"
              @event="cambio_background_color"
            ></koiote-color-picker-vue>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <koiote-color-picker-vue
              titulo="Foreground color"
              :pcolor="component_selected.component_props.customizables.foreground_color"
              @event="cambio_foreground_color"
            ></koiote-color-picker-vue>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <koiote-color-picker-vue
              titulo="Border color"
              :pcolor="component_selected.component_props.customizables.border_color"
              @event="cambio_border_color"
            ></koiote-color-picker-vue>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-select
              :items="font_size_array"
              label="Font size"
              v-model="component_selected.component_props.customizables.font_size"
            ></v-select>
          </v-list-item-content>
          <v-list-item-content>
            <v-text-field label="Unidades" v-model="component_selected.component_props.customizables.unities" @keyup.enter="changeEditing(false)" @click="changeEditing(true)" @blur="changeEditing(false)"> </v-text-field>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import KoioteColorPickerVue from "@/components/custom/KoioteColorPicker.vue";
export default {
  name: "KoioteValueLabelProperties",
  components: {
    KoioteColorPickerVue,
  },
  computed: {
    ...mapState(["component_selected"]),
  },
  methods: {
    ...mapActions(['changeEditing']),
    cambio_background_color(nc) {
      console.log("cambio en background color")
      this.component_selected.component_props.customizables.background_color = nc;
    },

    cambio_foreground_color(nc) {
      this.component_selected.component_props.customizables.foreground_color = nc;
    },
    cambio_border_color(nc) {
      this.component_selected.component_props.customizables.border_color = nc;
    },
  },
  data() {
    return {
      //background
      backgroundmenu: false,

      //background
      foregroundmenu: false,
      font_size_array: Array.from(Array(40).keys()).map((x) => x / 2 + 1),
      unidades:'culo'
    };
  },
};
</script>
<style lang="">
</style>
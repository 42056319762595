import axios from 'axios';

const state = ()=> ({
  myAxios: axios.create({
    baseURL: `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_BACK_HOST}:${process.env.VUE_APP_PORT}`,
    headers: {
      "Content-Type": "application/json",
      common: {
        "Authorization": 'Bearer ' + localStorage.getItem('token') || null
      }
    },
  }),
  user:{},
  maquinas: [],
  historicos: [],
  historicoSemana: [],
})
const getters = {
  getUser(){
    return JSON.parse(localStorage.getItem('user'))
  },
  getToken(){
    return localStorage.getItem('token')
  },
  getAxios(state){
    return state.myAxios
  },
  getMaquinas(state){
    return state.maquinas
  },
  getHistoricos8(state){
    return state.historicos
  },
  getHistoricoSemana(state){
    return state.historicoSemana
  },
}
const mutations = {

}
const actions = {
  setUpInterceptors({state}){
    state.myAxios.interceptors.request.use(function(config) {
      let token = localStorage.getItem('token')
      if(token) {
        config.headers.Authorization = `Bearer ${token}`;
      }else{
      }
      return config;
    }, function(err) {
        return Promise.reject(err);
    });
    state.myAxios.interceptors.response.use((response) => {
        // If the response status is between 200 and 299, it's a successful response, so return it as is.
        return response;
      },(error) => {
        if (error.response && error.response.status === 413) {
         //console.error('Payload Too Large (413) - The server refused to process the request due to large payload.');
    
          // You can also throw a custom error or return a modified response if needed
          // For example, you can throw an error like this:
          // throw new Error('Payload Too Large (413) - The server refused to process the request due to large payload.');
          // or return a modified response like this:
          // return Promise.reject('Payload Too Large (413) - The server refused to process the request due to large payload.');
        }
    
        return Promise.reject(error);
      }
    );
  },
  setUser({state}, user){
    state.user = user
    localStorage.setItem('user', user)
  },
  setToken({state},token){
    localStorage.setItem('token', token)
  },

  //    LOGIN & LOGOUT
  async login({state},userInfo){
    try{
      const res = await state.myAxios.post('auth/login/', userInfo)
      this.dispatch('api/setToken',res.data.token)
      this.dispatch('api/setUser', JSON.stringify(res.data.user))
      state.myAxios = axios.create({
        baseURL: `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_BACK_HOST}:${process.env.VUE_APP_PORT}`,
        headers: {
          "Content-Type": "application/json",
          common: {
            "Authorization": 'Bearer ' + res.data.token
          }
        },
      })
      if(res.data.user){
        if (res.data.user.role_name == 'admin') {
          this.dispatch('setRol',1)
          this.dispatch('setIsPlayMode', false)
          this.dispatch('setIsAdmin',true)
        }else{
          if(res.data.user.role_name == 'avanzado') this.dispatch('setRol',2)
          else this.dispatch('setRol',3)
          this.dispatch('setIsPlayMode', true)
          this.dispatch('setIsAdmin',false)
        }
      }
    }
    catch (error) {
      return error;
    }
  },
  logout({state}){
    state.user = {}
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    console.log("loged out");
  },

  //    PAGES
  getPages({state}){
    return state.myAxios.get(`pages/`)
  },
  getPageImage({state},id){
    return state.myAxios.get(`pages/images/${id}`, { responseType: 'blob' })
  },
  getPageDraggables({state},id){
    return state.myAxios.get(`draggables/page/${id}`)
  },
  putPageImage({state},payload){
    const formData = new FormData();
    formData.append("file", payload.image);  // appending file
    console.log("cambio guardando imageeen");
    // sending file to the backend
    return state.myAxios.put(`pages/images/${payload.id}`, formData)
  },
  deletePageImage({state},id){
    return state.myAxios.put(`pages/images/${id}`, {msg: "deleted"})
  },
  putPageName({state},page){
    return state.myAxios.put(`pages/${page.id}`,page)
  },
  deletePage({state},id){
    return state.myAxios.delete(`pages/${id}`)
  },
  postPage({state},page){
    return state.myAxios.post(`pages/`,page)
  },

  //    DRAGGABLES
  postDraggable({state},draggable){
    return state.myAxios.post('draggables/', draggable)
  },
  deleteDraggable({state},id){
    return state.myAxios.delete(`/draggables/${id}`)
  },
  putDraggable({state}, payload){
   
      return state.myAxios.put(`draggables/${payload.id}`, payload);
  
  },
  getDraggables({state}){
    return state.myAxios.get('draggables/')
  },
  putComponent({state},draggable){
    return state.myAxios.put(`draggables/component/${draggable.id}`,draggable.component_props)
  },
  getComponentImage({state},id){
    return state.myAxios.get(`draggables/component/image/${id}`, { responseType: 'blob' })
  },
  postComponentImage({state},payload){
    const formData = new FormData();
    formData.append("image", payload.image)  // appending file
    formData.append("name", payload.image.name)
    return state.myAxios.post(`draggables/component/image/${payload.id}`,formData)
  },
  putComponentImage({state}, draggable){
    return state.myAxios.put(`draggables/component/image/${draggable.id}`,draggable.component_props)
  },
  // putDraggableProperties({state}, payload){
  //   return state.myAxios.put(`draggables/properties/${payload.id}`,payload)
  // },

  //    DEVICES
  getDevices({state}){
    return state.myAxios.get('devices/')
  },
  getDevice({state}, ip){
    return state.myAxios.get(`devices/${ip}`)
  },
  postDevice({state},device){
    return state.myAxios.post('devices/',device)
  },
  deleteDevice({state},id){
    return state.myAxios.delete(`devices/${id}`)
  },
  updateDevice({state},device){
    return state.myAxios.put(`devices/${device.id}`, device)
  },
  getDeviceVariables({state},id){
    return state.myAxios.get(`devices/variables/${id}`)
  },


  //    NODERED
  // deployFull({state, rootState}){
  //   return state.myAxios.post(`${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_NODERED_CLIENT_HOST}:${process.env.VUE_APP_NODERED_PORT}/flows/`,rootState.nodered.flows,{
  //     headers: {
  //       'Node-RED-Deployment-Type': 'full'
  //     }
  //   });
  // },
  // deployServerFlows({state, rootState}){
  //   return state.myAxios.post(`${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_NODERED_CLIENT_HOST}:${process.env.VUE_APP_NODERED_PORT}/flows/`,rootState.nodered.flows,{
  //     headers: {
  //       'Node-RED-Deployment-Type': 'flows'
  //     }
  //   });
  // },
  getFlows({state}){
    return state.myAxios.get(`${process.env.VUE_APP_NODERED_CLIENT_PROTOCOL}://${process.env.VUE_APP_NODERED_CLIENT_HOST}:${process.env.VUE_APP_NODERED_CLIENT_PORT}/flows/`,{
    });
  },
  deployClientFlows({state, rootState}){
    return state.myAxios.post(`${process.env.VUE_APP_NODERED_CLIENT_PROTOCOL}://${process.env.VUE_APP_NODERED_CLIENT_HOST}:${process.env.VUE_APP_NODERED_CLIENT_PORT}/flows/`, rootState.nodered.flows,{
      headers: {
        'Node-RED-Deployment-Type': 'flows',
        'Content-Type': 'application/json'
      }
    });
  },
  postFlow({state}, flow){
    return state.myAxios.post(`${process.env.VUE_APP_NODERED_CLIENT_PROTOCOL}://${process.env.VUE_APP_NODERED_CLIENT_HOST}:${process.env.VUE_APP_NODERED_CLIENT_PORT}/flow`,flow)
  },
  deleteFlow({state},id){
    return state.myAxios.delete(`${process.env.VUE_APP_NODERED_CLIENT_PROTOCOL}://${process.env.VUE_APP_NODERED_CLIENT_HOST}:${process.env.VUE_APP_NODERED_CLIENT_PORT}/flow/${id}`)
  },
  /* USERS */
  getUsers({state}){
    return state.myAxios.get('users/')
  },
  getUser({state},user_id){
    return state.myAxios.get(`users/${user_id}`)
  },
  postUser({state},user){
    return state.myAxios.post('users/',user)
  },
  inactivateUser({state},user_id){
    return state.myAxios.put(`users/inactivate/${user_id}`)
  },
  updateUser({state},user){
    return state.myAxios.put(`users/${user.user_id}`,user)
  },
  updateUserInformation({state},user){
    return state.myAxios.put(`users/update-info/${user.user_id}`,user)
  },
  changeUserPassword({state},user){
    return state.myAxios.put(`users/update-password/${user.user_id}`,user)
  },

  //Historicos
  async getMaquinasBack({state}){
    state.maquinas = await state.myAxios.get('historico/estado_maquinas')
  },
  async gethistoricos8Back({state}){
    state.historicos = await state.myAxios.get('historico/historico_8')
  },
  // getHistorico8({state}, id_ciclo){
  //   return state.myAxios.get(`historico/historico_8/${id_ciclo}`)
  // },
  getHistoricosAlarmas({state}){
    return state.myAxios.get('historico/historico_alarmas')
  },
  
  getHistoricosFecha({state},rango){
    state.myAxios.get('historico/historicos',{
      params: {
          fechaInicio: rango.fechaInicio,
          fechaFin: rango.fechaFin,
      },
      headers: { "token": localStorage.getItem('token')} 
    }).then(res => {
      console.log('Tengo la peticion de historico');
      console.log(res.data.results);
      state.historicoSemana = res.data.results
      Object.freeze(state.historicoSemana)
    })
  },

  //Hornos
  getCiclo({state},id_programa){
    return state.myAxios.get(`hornos/ciclos/${id_programa}`)
  },
  getPrograma({state},id_programa){
    return state.myAxios.get(`hornos/${id_programa}`)
  },
  getAllProgramas({state}){
    return state.myAxios.get(`hornos`)
  },

}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
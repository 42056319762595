<template>
  <!-- <div id="parentContainer"> -->
    <canvas id="myCanvas" ref="myCanvas"></canvas>
    <!-- {{ value }}
{{ myProps.customizables.mostrar_valor }}
-->
  <!-- </div>  -->
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';


import image0Src from '@/assets/tanque_vacio_sinsombra.png'
import imageSrc from '@/assets/tanque_vacio_sombra.png'

// import imageSrc from "@/assets/tanque_vacío.png";
import liquidoSrc from "@/assets/liquido_tanque1.png";
import mask1Src from "@/assets/mask_tanque1.png";

export default {
  name: "koiote-tank-canvas",
  props: {
    myProps: Object
  },

  // props:{
  //     max:[Number,String],
  //     value:[Number,String]
  // },
  data() {
    return {
      max: 100,
      // value: 20,
      image: null,
      image0: null,
      mask: null,
      mask1: null,
      liquido: null,
      imageWidth: 0,
      imageHeight: 0,
      imagenCargada: false,
      mascadaCargada: false,
      // todasLasImagenesCargadas:false
    };
  },
  watch: {

    value(a) {
      // console.log(a)
      this.aplicar_mascara();
      if (this.value != undefined) {
          this.$emit('cambioValor', { id: this.myProps.id_draggable, value: this.value })
      }
    },
    todasLasImagenesCargadas(a) {
      if (a) {
        this.aplicar_mascara();
      }
    }


  },
  mounted(){
    this.imagen0Cargada = false
    this.imagenCargada = false
    this.mascadaCargada = false

    this.loadImage();//con sombra
    this.loadImage0();//sin sombra
    this.loadLiquido();
    this.loadMask();
  },
  created() {
    window.addEventListener('resize', this.resizeCanvas, false);
  

  },
  methods: {
    resizeCanvas() {

      const canvas = this.$refs.myCanvas;
      try {
        // console.log("Me resizeooooooooooooo");
        if(canvas.offsetHeight > 0 && canvas.offsetWidth > 0){

          // let parent = canvas.parentNode;
          //   canvas.style.width='100%';
          // canvas.style.height='100%';
          canvas.width = canvas.offsetWidth;
          canvas.height = canvas.offsetHeight;
          
          this.aplicar_mascara();
        }
      } catch (error) {

      }


      // canvas.width = window.innerWidth;
      // canvas.height = window.innerHeight;
    },

    aplicar_mascara() {
      this.limpiar_canvas();
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext('2d');
      if (this.image0 && canvas) {

        // ctx.drawImage(this.image, 0, 0);
        this.drawBox()

        ctx.globalCompositeOperation = "destination-in";
        ctx.drawImage(this.image0, 0, 0, canvas.width, canvas.height);
        // ctx.globalCompositeOperation = "overlay";
        ctx.globalCompositeOperation = "multiply";
        ctx.drawImage(this.image, 0, 0, canvas.width, canvas.height);
      }
      else {
        // console.log("Me falta algo :(");
      }
      // if(this.myProps.customizables.mostrar_valor){
      //     // Set the font style for the text
      //     // const fontSize = 30;
      //     // ctx.font = `${fontSize}px Arial`;
          
      //     ctx.globalCompositeOperation = "xor";

      //       const texto = this.value.toString();
      //       const fontSize = 30;
      //       ctx.font = `${fontSize}px Arial`;

      //       const textWidth = ctx.measureText(texto).width;
      //       const textHeight = 0;// ctx.measureText(texto).height;

      //       // Establecer la posición del texto
      //       const x = canvas.width/2-textWidth/2;
      //       const y = canvas.height/2-textHeight/2;

      //       // Establecer el color del texto
      //       ctx.fillStyle = 'white';

      //       // Dibujar el texto en el canvas
      //       ctx.fillText(texto, x, y);
      //   }
    },
    limpiar_canvas() {
      // console.log("Limpiando canvas")
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext('2d');
      

      // Limpia el lienzo
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
    drawBox() {

      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext("2d");


      let alto = this.value * canvas.height / this.max;
      let dy = canvas.height - alto;

      ctx.drawImage(this.liquido, 0, dy, canvas.width, alto);
    },

    loadMask() {
      const img = new Image();
      img.onload = () => {
        // console.log("mask loaded")
        this.mask1 = img;
        //this.draw();
        //this.draw_mask();
        this.maskWidth = img.naturalWidth;
        this.maskHeight = img.naturalHeight;
        this.mascadaCargada = true;
      };
      //   img.src = imageSrc;
      img.src = mask1Src;
    },
    loadImage() {
      const img = new Image();
      img.onload = () => {
        this.image = img;
        //this.draw();
        this.imageWidth = img.naturalWidth;
        this.imageHeight = img.naturalHeight;
      };
      img.src = imageSrc;
      this.imagenCargada = true;
    },
    loadImage0() {
      const img = new Image();
      img.onload = () => {
        this.image0 = img;
        //this.draw();
        this.imageWidth = img.naturalWidth;
        this.imageHeight = img.naturalHeight;
      };
      img.src = image0Src;
    },
    loadLiquido() {
      const img = new Image();
      img.onload = () => {
        this.liquido = img;
        //this.draw();
        this.imageWidth = img.naturalWidth;
        this.imageHeight = img.naturalHeight;
      };
      img.src = liquidoSrc;
    },
    draw() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext('2d');


      // Dibuja la imagen adaptada al lienzo
      if (this.image) {
        ctx.drawImage(this.image, 0, 0);
      }
    },//fin draw

    draw_mask() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext('2d');


      // Dibuja la imagen adaptada al lienzo
      if (this.mask) {

        ctx.drawImage(
          this.mask,
          0,
          0,
          this.imageWidth,
          this.imageHeight,
          0,
          0,
          canvas.width,
          canvas.height
        );



      } else {
        console.log("no está!")
      }

    }

  },

  computed: {
    ...mapState('nodered', ['message','ListOfmessages']),
    ...mapState(['component_selected']),
    ...mapGetters('nodered', ['getConnections']),
    bufferPos(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'BufferPos'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    dataType(){
      if(this.myPlc){
        let plcType =  this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate'+plcType+'VarType'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    myPlc(){
      if(this.myProps.customizables.topic != ""){
        return this.getConnections.find(e => e.id == this.myProps.customizables.plc)
      }
    },
    todasLasImagenesCargadas() {
      return ((this.image != null) && (this.image0 != null) && (this.liquido));
    },

    value() {
      let miMensaje = this.ListOfmessages.find(a=>a.topic==this.myProps.customizables.topic)
      if(miMensaje){
        if(this.myPlc && Object.keys(this.myPlc).length > 0 ){
          let functionName = this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
          functionName = 'readValueFrom'+functionName
          return this[functionName](miMensaje,this.myProps.customizables, this.bufferPos, this.dataType, this.myPlc)
        }
      }else{
        // console.log('No hay mensaje de ese topic :(');
      }
    },
  }, 
};

</script>

<style scoped>
.draggable-resize-rotate #parentContainer {
  width: 100%;
  height: 100%;
  position: relative;

}

#myCanvas {
  width: 100%;
  height: 100%;
  position: relative;
}

canvas {
  /* border: 1px solid black; */
  border: none;
}
</style>

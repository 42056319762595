import { Buffer } from 'buffer';

export const globalTransformBufferToFloat=(buffer,posicion)=>{
    const i = posicion/2
    const A = buffer[0+4*i]
    const B = buffer[1+4*i]
    const C = buffer[2+4*i]
    const D = buffer[3+4*i]
    const newbuf = Buffer.from([C, D, A, B])
    return newbuf.readFloatBE()
}


export const globalTransformBufferToBits=(buffer,posicion,len)=>{
    let salida=[]
    for (let i=0;i<len;i++){
        let valor_actual = buffer[posicion-i+len-1]
        let resultado_actual = (valor_actual >>> 0).toString(2)
        // console.log(resultado_actual)
        
        let resultado_actual_revertido = Array.from(resultado_actual).reverse();
        let previo = resultado_actual_revertido.map(Number)
        while(previo.length<8){
            previo.push(0)
        }
        salida = salida.concat(previo)
        
        // console.log(resultado_actual_revertido)
        
        
    }

    return salida;
    
}

export const splitVariable =(variable)=>{
    const regex = /([a-zA-Z]+)(\d+)/g;
    let match;
      // if(variable.includes('myProps'))
      // console.log(variable);
    let nuevaVariable = {bit:null, characters:null, numbers:null }
    if (variable.includes(".")) {
      nuevaVariable.bit = variable.split(".")[1]
      variable = variable.split(".")[0];
    }
    while ((match = regex.exec(variable))) {
      nuevaVariable.characters = match[1];
      nuevaVariable.numbers = parseInt(match[2]);
    }
    if (!nuevaVariable.characters) {
      console.log("Faltan los caracteres en la variable");
      return 'error:no chars';
    }
    if (!nuevaVariable.numbers) {
      console.log("Direccion de variable mal introducida");
      return 'error:no numbers';
    }
    return nuevaVariable 
  }
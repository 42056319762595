import KoioteSelector from './KoioteSelector.vue'
import KoioteSelectorProperties from './KoioteSelectorProperties.vue'
import KoioteSelector1Property from './default.json'


/// componente lectura excel
export default {
	install(Vue, options) {
		Vue.component(KoioteSelector.name, KoioteSelector)
		Vue.component(KoioteSelectorProperties.name, KoioteSelectorProperties)
		Vue.prototype.$KoioteSelector1Property = KoioteSelector1Property
		// para interpretar las variabes que llegan de nodered

		Vue.mixin({
			mounted() {
				// console.log('Montadito!');
			}
		});
	}
};


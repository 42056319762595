<template>
  <v-card text>
    <v-list>
      <v-list-item-group>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              prefix="Nombre:"
              v-model="component_selected.component_props.component_name"
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>       
        <v-list-item>
          <v-list-item-content>
           Valor actual: {{ component_selected.component_props.customizables.value }}
          </v-list-item-content>
        </v-list-item>       
        <v-list-item>
          <v-list-item-content>
            <koiote-color-picker-vue titulo="Background color" 
                :pcolor="component_selected.component_props.customizables.background_color"
                @event="cambio_background_color"
            >
            </koiote-color-picker-vue>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <koiote-color-picker-vue titulo="Foreground color" 
                :pcolor="component_selected.component_props.customizables.foreground_color"
                @event="cambio_foreground_color"
            >
            </koiote-color-picker-vue>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              prefix="Valor Set:"
              class="ml-3 mr-3"
              v-model.number="component_selected.component_props.customizables.set_value"
              single-line
              type="number"
              @keyup.enter="changeEditing(false)"
              @click="changeEditing(true)"
              @blur="changeEditing(false)"
            />
            <v-text-field
              prefix="Valor Reset:"
              class="ml-3 mr-3"
              v-model.number="component_selected.component_props.customizables.reset_value"
              single-line
              type="number"
              @keyup.enter="changeEditing(false)"
              @click="changeEditing(true)"
              @blur="changeEditing(false)"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import KoioteColorPickerVue from '@/components/custom/KoioteColorPicker.vue';
export default {
  name: "KoioteSetResetProperties",
  computed: {
    ...mapState(["component_selected"]),
  },
  methods: {
    ...mapActions(['changeEditing']),
    cambio_background_color(nc) {
      console.log("cambio en background color")
      this.component_selected.component_props.customizables.background_color = nc;
    },
    cambio_foreground_color(nc) {
      console.log("cambio en foreground color")
      this.component_selected.component_props.customizables.foreground_color = nc;
    },
  },
  components:{
        KoioteColorPickerVue
    },
  data() {
    return {
     
    };
  },
};
</script>
<style lang="">
</style>
import Vue from 'vue'
import Vuex from 'vuex'

import nodered from '@/store/modules/nodered.js'
import api from '@/store/modules/api'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    modal_activa: false,
    component_selected:{},
    component_selected_original:{},
    list_components:[],
    tool:{},
    full_height:null,
    pages:[],
    selected_page:{},
    last_selected_page:{},
    visited_pages:[],
    selected_page_draggables:[],
    modified_draggables:[],
    modified_pages:[],
    is_play_mode: false,
    is_admin: false,
    rol: null,
    visited_pages:[],
    screen_updated: false, 
    mouseX:0,
    editing:false,
    mouseY:0,
    miRect:{},
    show_navigation:false, 
  },
  getters: {
    vuexLastId(state){
      let res = -1;

      state.list_components.forEach(element => {
        if(element.id>res)res=element.id
      });
      return res
    },
    getSelectedPage(state){
      return state.selected_page
    },
    getLastSelectedPage(state){
      return state.last_selected_page
    },
    getPages(state){
      return state.pages
    },
    getIsPlayMode(state){
      return state.is_play_mode
    },
    getScreenUpdated(state){
      return state.screen_updated
    },
    getIsAdmin(state){
      return state.is_admin
      // return JSON.parse(localStorage.getItem("user")).role_name == "admin"
    },
    getRol(state){
      return state.rol
    },
    getMainPage(state){
      return state.pages.find(a=>a['is_main_page']==true)
    }
  },
  mutations: {
    commitSetComponentSelected(state,payload){
      state.component_selected=payload;
      state.component_selected_original= JSON.parse(JSON.stringify(payload));
    },
    commitUnsetSelectedComponent(state){
      state.component_selected = {}
      state.component_selected_original= JSON.parse(JSON.stringify({}));
    },
    commitSetComponents(state,payload){
      state.list_components=payload
    },
    setTool(state,payload){
      state.tool=payload
    },
    setHeight(state,h){
      state.full_height = h
    },
    setPages(state, pages){
      state.pages = pages
    },
    setEditing(state,v){
      state.editing = v
    },
    sortArray(state,objectArray){
      objectArray.sort((a, b) => {
        return a.id - b.id;
      });
    },

    setSelectedPage(state,page){
      state.selected_page = page
      //Si la página que estamos visitando es distinta a la última visitada, agregar a lista de visitadas
      if(state.visited_pages.length>0){
        let lastElement = state.visited_pages[state.visited_pages.length - 1];
        if(lastElement.id!=page.id)state.visited_pages.push(page)
      }else{
        state.visited_pages.push(page)
      }
      state.selected_page_draggables = []
      state.list_components.forEach(element => {
        if(element.id_page == page.id){
          state.selected_page_draggables.push(element)
        }
      });
      console.log("En la pagina "+page.id)
    },
    unselectedPage(state){
      state.last_selected_page = []
      state.last_selected_page = JSON.parse(JSON.stringify(state.selected_page)) 
      
      state.selected_page = {}
      state.component_selected = {}
      state.selected_page_draggables = []
    },
    addPageToArray(state,page){
      state.pages.push(page)
    },
    addToModifiedPages(state,page){
      state.modified_pages.push(page)
    },
    setImageToPage(state,img){
      state.selected_page.image = img;
    },
    addToModifiedDraggables(state){
      state.modified_draggables.push(state.component_selected)
    },
    clearModifiedDraggables(state){
      state.modified_draggables = []
    },
    clearModifiedPages(state){
      state.modified_pages = []
    },
    clearArray(state){
      state.list_components = []
    },
    addToDraggablesData(state, draggableData){
      state.list_components.push(draggableData)
      if (!state.selected_page_draggables.includes(draggableData)){
        state.selected_page_draggables.push(draggableData)
      }
    },
    setIsPlayMode(state, is_play_mode){
      state.is_play_mode = is_play_mode
    },
    setScreenUpdated(state, value){
      state.screen_updated = value
    },
    setIsAdmin(state, value){
      state.is_admin = value
    },
    setModalActivo(state,v){
      state.modal_activo=v
    },
    setRol(state, value){
      console.log('Entro en setROl');
      switch (value) {
        case 1:
          state.rol = 'admin'
          break;
        case 2:
          state.rol = 'avanzado'
          break;
        case 3:
          state.rol = 'normal'
          break;
        default:
          state.rol = 'normal'
          break;
      }
    },
    setNavigation(state, value){
      state.show_navigation = value
    },

  },
  actions: {
    changeHeight({commit},h){
      commit('setHeight', h)
    },
    changeEditing({commit},value){
      commit('setEditing', value)
    },
    setShowNavigation({commit},value){
      commit('setNavigation', value)
    },
    //Cuando se seleccione un elemento colocado en pantalla, se deben mostrar sus propiedades
    setComponentSelected({commit,state},id){
      console.log("Componente seleccionado", id);
      if(id==-1){
        state.list_components.map(a=>a.selected=false)
        commit('commitSetComponentSelected',{})
        return
      }
      //Deseleccionar todos los draggables que no sean el seleccionado
      let others = state.list_components.filter(e=> e.id!=id)
      others.map(a=>a.selected=false)
      // state.component_selected.selected = true
      //Seleccionar el draggable seleccionado
      let payload = state.list_components.find(e=> e.id==id);
      payload.selected=true
      commit('commitSetComponentSelected',payload)
    },
    deleteComponentSelected({commit,state}){
      console.log('deleteComponentSelected');
      state.list_components.map(a=>a.selected=false)
      commit('commitUnsetSelectedComponent')
    },
    
    //Cargar elementos guardados
    setComponents({commit},payload){
      commit('commitSetComponents',payload)
    },
    // getAllDraggables({commit,state}){
    //   state.pages.forEach(element => {
    //     this.dispatch('addPageDraggables',element.id)
    //   });
    // },
    async addPageDraggables({commit, state}, page_id){
      //Obtener del back dragables de cada página
      let draggables = await this.dispatch('api/getPageDraggables',page_id)
      if(draggables.data.length > 0){
        draggables = draggables.data
        for(let i=0;i<draggables.length;i++){
          if(draggables[i].id_page === page_id){
            let newDraggableData = {
              id: draggables[i].id,
              id_page: draggables[i].id_page,
              w: parseFloat(draggables[i].w),
              h: parseFloat(draggables[i].h),
              x: parseFloat(draggables[i].x),
              y: parseFloat(draggables[i].y),
              angle: parseFloat(draggables[i].angle),
              z:draggables[i].zindex,//TODO:must be read from backend
              component_props:{}              
            }
            if(draggables[i].component){  
              newDraggableData.component_props = draggables[i].component
              newDraggableData.selected = false
              // if(newDraggableData.component_props.customizables.variable && newDraggableData.component_props.customizables.variable.includes('myProps'))
              // console.log(newDraggableData.id_page);
              commit('addToDraggablesData', newDraggableData)
              
            }
          }
        }
        // this.dispatch('sortDraggables')
      }else{
        console.log("No draggables in this page");
      }
    },

    mousemove({commit, state},event){
      if(localStorage.getItem('user') != null){
        try {
          state.miRect = document.getElementById("screen").getBoundingClientRect()
          state.mouseX = -state.miRect.x + event.clientX;
          state.mouseY = -state.miRect.y + event.clientY;
        } catch (error) {
          // console.log('Cannot get mouse position',error)
        }
      }
    },
    sortDraggables({commit, state}){
      commit('sortArray', state.list_components)
    },
    //Pulsa sobre herramienta
    setTool({commit},payload){
      commit('setTool',payload)
    },

    //    PAGINAS
    setPages({commit, state}, pages){
      console.log("Asignando paginas")
      // this.dispatch('nodered/restartFlows')
      commit('setPages', pages)
      commit('sortArray',state.pages)
    },
    setSelectedPage({commit,state}, page){
      commit('unselectedPage')
      commit('setSelectedPage', page)
    },
    
    unselectPage({commit}){
      commit('unselectedPage')
    },

    async addPage({commit}, page){
      const res = await this.dispatch('api/postPage',page) 
      page.id = res.data[0].id
      commit('addPageToArray', page)
      this.dispatch('setSelectedPage',page)                
    },
    addModifiedPage({commit, state},page){
      let isModified = state.modified_pages.find(e => e.id == page.id)
      if(!isModified){
        commit('addToModifiedPages',page)
      }
    },
    setImageToSelectedPage({commit},payload){
      commit('setImageToPage',payload);
    },
    goToBackPage({commit,state}){
      //Quitar último elemento del array de páginas visitadas
      if(state.visited_pages.length>0)
      {
        state.visited_pages.pop()
        let lastElement = state.visited_pages[state.visited_pages.length - 1];
        this.dispatch('setSelectedPage',lastElement)  
        
      }
      //Coger último elemento (tras haber eliminado el anterior) y hacer set de esa página
    },



    /////////// draggables
    addModifiedDraggable({commit, state}){
      let isModified = state.modified_draggables.find(e => e.id == state.component_selected.id)
      if(!isModified){
        commit('addToModifiedDraggables')
      }
    },
    pushToModified({state},item){
      let isModified = state.modified_draggables.find(e => e.id == item.id)
      if(!isModified){
        state.modified_draggables.push(item)
      }
    },
    deleteModifiedDraggables({commit}){
      console.log("Voy a borrar la lista de modificados");
      commit('clearModifiedDraggables')
    },
    deleteModifiedPages({commit}){
      commit('clearModifiedPages')
    },

    async deleteDraggable({commit, state}){
      if(state.component_selected){
        if(state.component_selected.component_props.customizables.connectionID){ //si es un nodo de conexión lo borramos del nodered
          state.nodered.connections = state.nodered.connections.filter(e => e.id != state.component_selected.component_props.customizables.connectionID)
          let injects = state.nodered.flows.filter(e => e.name == `Read_${state.component_selected.component_props.customizables.connectionID}`)
          await injects.forEach(element => {
              let omronRead = state.nodered.flows.find(node => node.id == element.wires[0][0])
              let connectionNode = state.nodered.flows.find(node => node.id == omronRead.connection)
              let functionNode = state.nodered.flows.find(node => node.id == omronRead.wires[0][0])
              let mqttNode = state.nodered.flows.find(node => node.id == functionNode.wires[0][0])
              let flows = state.nodered.flows.filter(e => e != omronRead && e != functionNode && e != mqttNode && e != element && e != connectionNode)
              this.dispatch('nodered/modifyFlows',flows)
          })
        }
        await this.dispatch('api/deleteDraggable',state.component_selected.id)    
        state.list_components = state.list_components.filter(e => e.id != state.component_selected.id)
        state.component_selected = {}
        
      }
    },

    //Modificar valores del componente
    updateAnyComponent({commit,state},payload){
      console.log(payload);
      let element = state.list_components.find(e => e.id = payload.id_draggable)
      console.log(element);
      element.component_props = payload
      if(!state.modified_draggables.find(e => e.id == payload.id_draggable))
        state.modified_draggables.push(element)
      console.log(state.modified_draggables.length);
    },
    updateComponent({commit,state},payload){
      console.log(payload)
      console.log("UPDATE COMPONENT")
      if(!payload.hasOwnProperty("id_draggable")){
        return;
      }

      //Si el objeto no ha cambiado no actualizar lista de elementos
      // if(!_.isEqual(state.component_selected.component_props, payload)){
        state.component_selected.component_props = payload
        state.list_components.splice();
      // }
      
      this.dispatch('addModifiedDraggable')
    },
    clearDraggablesData({commit}){
      commit('unselectedPage')
      commit('clearArray')
    },
    goToBackPage({commit,state}){
      //Quitar último elemento del array de páginas visitadas
      if(state.visited_pages.length>0)
      {
        state.visited_pages.pop()
        let lastElement = state.visited_pages[state.visited_pages.length - 1];
        this.dispatch('setSelectedPage',lastElement)  
        
      }
      //Coger último elemento (tras haber eliminado el anterior) y hacer set de esa página
    },
    callDynamicFunction({commit,state}, payload){
      this.dispatch(payload.hover_function, payload.hover_function_payload)
    },
    editCanvasBorder({commit,state},payload){ // pasa un objeto con el valor que va a asignar a cada elemento que se corresponda con el id dell array de ids
      console.log(payload);
      payload.id_array.forEach(element => {
        let canvas = state.list_components.find(e =>e.id == parseInt(element))
        canvas.component_props.customizables.border = payload.value
        // canvas.component_props.customizables.border = !canvas.component_props.customizables.border
      });
    },
    async startApp(){
      this.dispatch('clearDraggablesData')
      this.dispatch('nodered/clearConnections')
      let res = await this.dispatch('api/getPages')
      if(res.data){
        let pages = res.data
        //Para cada página cargamos su imagen de fondo
        pages.forEach(async element => {
          if(element.image != null){
            const response = await this.dispatch('api/getPageImage',element.id)
            const file = new Blob([response.data], { type: "image/png" })
            element.image = URL.createObjectURL(file);
            await this.dispatch('addPageDraggables',element.id)
          }
        });
        //Guardamos en state las páginas
        this.dispatch('setPages',pages)
        // await this.dispatch('getAllDraggables')
      }else{
        console.log("no hay paginas");
      }
    },
    //Modificación de ancho, alto o posición de un componente
    updatePositionOrSize({commit,state},payload){
      let a = state.list_components.find(e=> e.id==payload.id_draggable)
      a.angle=payload.current_rect.angle
      a.x=payload.current_rect.x
      a.y=payload.current_rect.y
      a.h=payload.current_rect.h
      a.w=payload.current_rect.w
    },
    
    setIsPlayMode({commit},payload){
      commit('setIsPlayMode',payload)
    },
    setRol({commit},payload){
      commit('setRol',payload)
    },
    setScreenUpdated({commit},payload){
      commit('setScreenUpdated',payload)
    },
    setIsAdmin({commit},payload){
      commit('setIsAdmin',payload)
    },
    setSelectedComponentsNewPosition({commit,state},payload){
      state.component_selected[payload.propiedad]= parseFloat(payload.valor);
    },
    setModalActivo({commit},a){
      commit('setModalActivo',a)
    }
  },
  modules: {
    nodered, api
  }
})

import KoioteGraficoZonas from './KoioteGraficoZonas.vue'
import GraficaZonas from './GraficaZonas.vue'
import KoioteGraficoZonasProperties from './KoioteGraficoZonasProperties.vue'
import KoioteGraficoZonas1Property from './default.json'

import { Bar } from 'vue-chartjs'

export default {
    install(Vue,options) {
      Vue.component(GraficaZonas.name, GraficaZonas)
      Vue.component(KoioteGraficoZonas.name, KoioteGraficoZonas)
      Vue.component(KoioteGraficoZonasProperties.name, KoioteGraficoZonasProperties)
      Vue.prototype.$GraficoZonasProperties = KoioteGraficoZonas1Property

      Vue.mixin({
          mounted() {
            // console.log('Montadito!');
          }
        });
      }
  };
  

import KoioteValueLabelWrite from './KoioteValueLabelWrite.vue'
// import OmronVariableSelection from './OmronVariableSelection.vue'
import KoioteValueLabelWriteProperties from './KoioteValueLabelWriteProperties.vue'
import KoioteValueLabelWrite1Property from './default.json'
// import omronWriting from '../connection omron/omronWriting.js/index.js'


/// componente lectura excel
export default {
	install(Vue, options) {
		Vue.component(KoioteValueLabelWrite.name, KoioteValueLabelWrite)
		Vue.component(KoioteValueLabelWriteProperties.name, KoioteValueLabelWriteProperties)
		Vue.prototype.$KoioteValueLabelWrite1Property = KoioteValueLabelWrite1Property
		// para interpretar las variabes que llegan de nodered

		Vue.mixin({
			mounted() {
				// console.log('Montadito!');
			}
		});
	}
};


import KoioteOmronConnection from './KoioteOmronConnection.vue'
import OmronVariableSelection from './OmronVariableSelection.vue'
import KoioteOmronConnectionProperties from './KoioteOmronConnectionProperties.vue'
import KoioteOmronConnection1Property from './default.json'
import omronReading from './omronReading.js'
import omronWriting from './omronWriting.js'




/// componente lectura excel
import KoioteExcelReader from '@/components/custom/KoioteExcelReader.vue'
export default {
    install(Vue,options) {
        Vue.component(KoioteOmronConnection.name, KoioteOmronConnection)
        Vue.component(OmronVariableSelection.name, OmronVariableSelection)
        Vue.component(KoioteOmronConnectionProperties.name, KoioteOmronConnectionProperties)
        Vue.component('KoioteExcelRead', KoioteExcelReader)
        Vue.prototype.$OmronConnectionProperties = KoioteOmronConnection1Property
        // para interpretar las variabes que llegan de nodered
        Vue.mixin(omronReading)
        Vue.mixin(omronWriting)

        Vue.mixin({
            mounted() {
             // console.log('Montadito!');
            }
          });
       }
  };
  

import KoioteImageSwitchCanvas from './KoioteImageSwitchCanvas.vue'
import KoioteImageSwitchCanvasProperties from './KoioteImageSwitchCanvasProperties.vue'
import KoioteImageSwitchCanvasProperty from './default.json'

export default {
    install(Vue,options) {
        Vue.component('koiote-image-switch-canvas', KoioteImageSwitchCanvas);
        Vue.component('koiote-image-switch-canvas-properties', KoioteImageSwitchCanvasProperties);
        Vue.prototype.$koioteImageSwitchCanvasProperty = KoioteImageSwitchCanvasProperty;

        Vue.mixin({
            mounted() {
             // console.log('Montadito!');
            }
          });
       }
  };
  

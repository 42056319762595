import KoioteNavigation from './KoioteNavigation.vue'
import KoioteNavigationProperties from './KoioteNavigationProperties.vue'
import KoioteNavigation1Property from './default.json'

export default {
    install(Vue,options) {
        Vue.component(KoioteNavigation.name, KoioteNavigation)
        Vue.component(KoioteNavigationProperties.name, KoioteNavigationProperties)
        Vue.prototype.$NavigationProperties = KoioteNavigation1Property


        Vue.mixin({
            mounted() {
             // console.log('Montadito!');
            }
          });
       }
  };
  

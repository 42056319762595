<template lang="es">
  <v-card text>
    <v-list>
      <v-list-item-group>
        <v-list-item>
          <v-list-item-content>
            <koiote-color-picker-vue titulo="Border color" 
            :pcolor="component_selected.component_props.customizables.border_color"
            @event="cambio_border_color"
          
            ></koiote-color-picker-vue>
          </v-list-item-content>
        </v-list-item>
        <v-checkbox
        color="info"
        hide-details
        class='ml-2'
        v-model="component_selected.component_props.customizables.round"
        label="Formato circular"
        >
        </v-checkbox>
        <koiote-hover-function></koiote-hover-function>
        <v-list-item>
          <v-list-item-content>
            Valor actual: {{component_selected.component_props.customizables.value}}
          </v-list-item-content>
        </v-list-item>
        
      <!-- imagen sin variable de plc-->
      <v-list-item>
        <v-list-item-content>
          <v-row v-show="component_selected.component_props.customizables.static_image != null">
            <v-col>
              Imagen estática:
            </v-col>
            <v-col>
              <v-img
              class="ml-2 mr-2 imagen_pequeña"
              :src="component_selected.component_props.customizables.static_image"
              height="32px"
              width="32px"
              @click="cambiar_imagen_estatica()"
              
              ></v-img>
            </v-col>
            <v-col>
              <v-icon  class="mt-1" @click="borrarImagenEstatica">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
            <v-row v-show="component_selected.component_props.customizables.static_image == null">
              <v-col cols="4">
              Imagen estática
            </v-col>
            <v-col cols="8">
              <v-file-input v-show="component_selected.component_props.customizables.static_image == null"
              accept="image/*"
              label="Seleccionar"
              dense
              background-color="white"
              ref="ref_file_input_without_plc"
              @change="fileChangedInItemWithoutPLC($event)"
              ></v-file-input> 
            </v-col>
            </v-row>

        </v-list-item-content>
      </v-list-item>
      <v-list-item>
      <!-- select a int value -->

      <v-list-item-content>
        <!-- <v-list-item-title> -->
          <v-btn block @click="shuffle_mostrar_valores">Imágenes Dinámicas</v-btn>
        <!-- </v-list-item-title> -->
        <v-simple-table v-if="mostrar_valores">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Valor</th>
                <th>Imagen</th>
              </tr>
            </thead>
            <tbody>
              <!-- v-for="(item,index) in component_selected.component_props.customizables.values.sort((a,b)=>a-b)" -->

              <tr
              v-for="(item,index) in component_selected.component_props.customizables.values"
                :key="index"
              >
              <td>{{item}}</td>
              <td>
                <span>
                  <v-img 
                  :src="getSelectedImage(item)"
                  height="32px"
                  width="32px"
                  @click="cambiar_imagen(item)"

                ></v-img>

                </span>
                
                <v-file-input
              
                  accept="image/*"
                  label="Imagen"
                  dense
                  background-color="white"
                  :id="refFileInput(item)"
                  ref="refFileInput"
                  :class="clase_valor(item)"
                  @change="fileChangedInItem(item,$event)"
                ></v-file-input>
              </td>
              </tr>
              <tr>
                <td>
                  <v-text-field @keyup.enter="guardar_valor" v-model="new_value"></v-text-field>
                </td>
                <td>
                  <span>{{pendiente}}</span>
                </td>
              </tr>
            </tbody>
          </template>

        </v-simple-table>
          
      </v-list-item-content>
          </v-list-item>
          <!-- display the image from above -->
          <v-list-item v-if="url">
              <v-list-item-content>
                  <v-img
                      :src="url"
                      :alt="url"
                      width="100%"
                      height="100%"
                      contain
                  ></v-img>
              </v-list-item-content>
          </v-list-item>

      </v-list-item-group>
  </v-list>
</v-card>

</template>
<script>
import KoioteHoverFunction from "@/components/custom/KoioteHoverFunction.vue";
import KoioteColorPickerVue from '@/components/custom/KoioteColorPicker.vue';
import { mapActions, mapState } from "vuex";
export default {
  name: "KoioteImageSwitchCanvasProperties",
  computed: {
    ...mapState(["component_selected"]),
    pendiente() {
      return `<<Primero valor>>`
    },
  },
  components:{
    KoioteHoverFunction,KoioteColorPickerVue
  },
  methods: {
    ...mapActions(["updateComponent"]),
    cambio_border_color(nc){
      this.component_selected.component_props.customizables.border_color = nc
    },
    borrarImagenEstatica(){
      console.log("Borro la imagen");
      // this.$refs.ref_file_input_without_plc.value = null
      this.component_selected.component_props.customizables.static_image = null;
      this.component_selected.component_props.customizables.ignore_plc = false;
    },
     fileChangedInItemWithoutPLC(e) {
      if (e != null) {
        // console.log(typeof this.component_selected.component_props.customizables.static_image)
        const reader = new FileReader();
         reader.onload = async (event) =>  {
          console.log("CARGADA IMAGEN ESTÁTICA")
          this.component_selected.component_props.customizables.ignore_plc = true;
          this.component_selected.component_props.customizables.static_image = event.target.result;
          // console.log(this.component_selected.component_props.customizables.static_image)
        }
        reader.readAsDataURL(e);
      } else {
        this.borrarImagenEstatica();
      }

    },
    fileChangedInItem(it, e) {
      if (e != null) {

        const reader = new FileReader();
        reader.onload = (event) => {
          //si ya existe imagen para ese valor, la reemplaza
          if (this.component_selected.component_props.customizables.setted_values.includes(it)) {
            this.component_selected.component_props.customizables.images[
              this.component_selected.component_props.customizables.setted_values.indexOf(it)
            ] = event.target.result;
          } else {
            //si no existe, la agrega
            this.component_selected.component_props.customizables.images.push(event.target.result);
            this.component_selected.component_props.customizables.setted_values.push(it);
          }
          //this.url = event.target.result;
          this.component_selected.component_props.customizables.images.splice();
          this.component_selected.component_props.customizables.setted_values.splice();
        };

        reader.readAsDataURL(e);
      } else {   // si se borra la imagen
        if (this.component_selected.component_props.customizables.setted_values.includes(this.value)) {
          this.component_selected.component_props.customizables.images.splice(this.component_selected.component_props.customizables.setted_values.indexOf(this.value), 1)
          this.component_selected.component_props.customizables.setted_values.splice(this.component_selected.component_props.customizables.setted_values.indexOf(this.value), 1)

        }

      }
    },



    updateSelectedImage(value) {
      //updates the image that is being displayed in the toolbar
      if (this.component_selected.component_props.customizables.setted_values.includes(value)) {
        this.url =
          this.component_selected.component_props.customizables.images[
          this.component_selected.component_props.customizables.setted_values.indexOf(value)
          ];
      } else {
        this.url = null;
      }
    },
    clase_valor(it) {
      return this.isImageSeted(it) ? "d-none" : "";
    },
    refFileInput(it) {
      return `file_input_${it}`
    },
    cambiar_imagen_estatica(){
      this.$refs.ref_file_input_without_plc.$refs.input.click();
    },
    cambiar_imagen(it) {
      const ref = this.refFileInput(it)
      let file_selected = document.getElementById(ref)

      file_selected.click()



      // this.$refs[`file_input_${it}`].click();

    },


    isImageSeted(value) {
      return (this.component_selected.component_props.customizables.setted_values.includes(value))
    },

    getSelectedImage(value) {
      if (this.component_selected.component_props.customizables.setted_values.includes(value)) {
        return this.component_selected.component_props.customizables.images[
          this.component_selected.component_props.customizables.setted_values.indexOf(value)
        ];
      }

    },
    shuffle_mostrar_valores() {
      this.mostrar_valores = !this.mostrar_valores;
    },

    addValue(value) {
      //adds a value to the possible values array
      if (!this.component_selected.component_props.customizables.values.includes(value)) {
        this.component_selected.component_props.customizables.values.push(value);
        this.updateComponent(this.component_selected.component_props)

      }
      this.addvaluemenu = false;
    },

    guardar_valor() {
      this.addValue(this.new_value)
      this.new_value = null;
    }
  },
  data() {
    return {
      //background
      addvaluemenu: false,
      url: null,
      value: null,
      mostrar_valores: false,
      new_value: null,

    };
  },
};
</script>
<style >
.imagen_pequeña{
  position:absolute;
  width:100%;
  height: 100%;
}
</style>
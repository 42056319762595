<template lang="">
  <div>
     <!-- <h4 class="text-center mt-3">Variable:</h4> -->
     <v-select
      v-model="variable"
      class="ml-3 mr-3"
      label="Seleccionar"
      :items="getConnections.find(e => e.id == component_selected.component_props.customizables.plc).variables"
      item-text='name'
      return-object
    ></v-select>
      <koiote-decimal-select></koiote-decimal-select>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: "IbhLinkVariableSelection",
  data() {
    return {
      variable: null,
    };
  },
  components:{
    KoioteDecimalSelect: () => import('@/components/custom/KoioteDecimalSelect.vue'),
  },
  computed:{  
    ...mapState(['component_selected','component_selected_original','subscriptions']),
    ...mapState('nodered',['subscriptions']),
    ...mapGetters("nodered", ["getConnections", "existsInNodered", "getTopic"]),

  },
  methods: {
    ...mapActions("nodered", ["changeTopic"]),
    ...mapActions(['addModifiedDraggable']),
    readData() {
      // al cambiar la variable cambiara el topic del draggable
      //if variable and plc are not null read
      if (this.variable != null && this.component_selected.component_props.customizables.plc != undefined) {
        this.component_selected.component_props.customizables.variable = this.variable.name;
        this.component_selected.component_props.customizables.topic = this.variable.topic;
      }
      else {
        this.component_selected.component_props.customizables.variable = "";
        this.component_selected.component_props.customizables.topic = "";
        console.log("no se lee nada");
      }
    }
  },
  created(){
    if(this.component_selected.component_props.customizables.variable != "" && this.component_selected.component_props.customizables.plc != undefined){
      let variable = this.getConnections.find(e => e.id == this.component_selected.component_props.customizables.plc).variables
      if(variable.length > 0){
        variable = variable.find(e => e.name == this.component_selected.component_props.customizables.variable)
        this.variable = variable
      }
    }
  },
  watch: {
    component_selected() {
      if (this.component_selected.component_props.customizables.plc != undefined && this.component_selected.component_props.customizables.variable != undefined) {
        let variable = this.getConnections.find(e => e.id == this.component_selected.component_props.customizables.plc).variables
        if(variable.length > 0){
          variable = variable.find(e => e.name == this.component_selected.component_props.customizables.variable)
          this.variable = variable
        }
      }
      else {
        this.variable = null;
      }
    },
    'component_selected.component_props.customizables.plc':function(){
      if(this.component_selected.component_props.customizables.plc != this.component_selected_original.component_props.customizables.plc){
        let plcType = this.getConnections.find(e => e.id == this.component_selected.component_props.customizables.plc).type
        if(plcType != null && plcType == "IBHLink Connection"){
          this.readData()
          console.log("Ha cambiado el plcccccccccccccccccc");
        }
      }
    },
    variable() {
      if (this.variable != null && this.variable.name != this.component_selected.component_props.customizables.variable) {
        this.readData();
      }
    }
  }
}
</script>
<style lang="">
  
</style>
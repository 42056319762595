<template lang="es">
     <v-container>
        <v-dialog
            v-model="editar_variables"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="cerrar()"> <v-icon>mdi-close</v-icon> </v-btn>
                    <v-toolbar-title>Settings</v-toolbar-title>
                </v-toolbar>

                <grafica-zonas
                    :data="data"
                    :title="myProps.customizables.text"
                    :value="!loading"
                >
                </grafica-zonas>
                <v-overlay :value="loading">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-dialog>

        <div v-if="!editar_variables">
            <!-- <v-btn @click="mostrar_variables(true)" plain>{{ myProps.customizables.text }}</v-btn> -->
            <v-btn @click="mostrar_variables(true)" plain><v-icon>mdi-information</v-icon></v-btn>
        </div>
        
     </v-container>
</template>

<script>
import GraficaZonas from './GraficaZonas.vue';
import { mapGetters, mapActions, mapState } from "vuex";
import moment from 'moment';

export default {
    name: "KoioteGraficoZonas",
    props: {
        myProps: Object
    },
    components: {
        GraficaZonas,
    },
    data() {
        return {
            ciclo: [],
            labels: [],
            temp_datos: [],
            initial_time: '',
            id_programa: 6,
            programa: [],
            programa_initial_time: '',
            labels_programa: [],            

            editar_variables:false,
            loading: true,
            maquinas: [],
            id_maquina_grafico: -1,
            historicos: [],
            zona1: [],
            zona2: [],
            zona3: [],
            zona4: [],
            zona5: [],
        }
    },
    async mounted() {
        await this.getMachines()
        await this.getHistorico()
    },
    methods: {
        ...mapActions(['setModalActivo']),
        getMachines(){
            const data = this.getMaquinas
            try {
                data.data.results.forEach(element => {
                this.maquinas.push(element)
                //Guardamos el id de BBDD para historicos de la maquina
                if(this.myProps.customizables.variable === element.direccion){
                    this.id_maquina_grafico = element.id
                } //Guardamos ir de la maquina/variable
            });
            } catch (error) {
                console.log(error)
            }
          
        },
        getHistorico(){
            const data = this.getHistoricos8
            try {
                data.data.results.forEach(element => {
                if(element.id_estado == this.id_maquina_grafico) this.historicos.push(element)
            });
            } catch (error) {
                console.log(error)
                
            }
           
            this.PieDataProportionCalc()
            this.loading = false
        },
        PieDataProportionCalc(){
            let ant = 0
            this.historicos.forEach(e => {
                this.labels.push(e.created_at)
                switch (e.valor) {
                    case 1:
                        this.zona1.push(1)
                        this.zona2.push(null)
                        this.zona3.push(null)
                        this.zona4.push(null)
                        this.zona5.push(null)
                        break;
                    case 2:
                        this.zona1.push(null)
                        this.zona2.push(1)
                        this.zona3.push(null)
                        this.zona4.push(null)
                        this.zona5.push(null)
                        break;
                    case 3:
                        this.zona1.push(null)
                        this.zona2.push(null)
                        this.zona3.push(1)
                        this.zona4.push(null)
                        this.zona5.push(null)
                        break;
                    case 4:
                        this.zona1.push(null)
                        this.zona2.push(null)
                        this.zona3.push(null)
                        this.zona4.push(1)
                        this.zona5.push(null)
                        break;
                    case 5:
                        this.zona1.push(null)
                        this.zona2.push(null)
                        this.zona3.push(null)
                        this.zona4.push(null)
                        this.zona5.push(1)
                        break;
                    
                    default:
                        this.zona1.push(null)
                        this.zona2.push(null)
                        this.zona3.push(null)
                        this.zona4.push(null)
                        this.zona5.push(null)
                        break;
                }
                ant = e.valor
            });
        },
        mostrar_variables(v){
            console.log('boton selecciondo');
            this.editar_variables = !this.editar_variables
            this.setModalActivo(v)
        },
        cerrar(){
            this.editar_variables=false;
            this.setModalActivo(false);
        },
    },//Fin methods
    watch: {
        historicosBack(){
            this.getMachines()
            this.getHistorico()
        },
    },
    computed: {
        ...mapState('nodered',['message']),
        ...mapGetters('api', ['getMaquinas','getHistoricos8']),

        historicosBack(){
            return this.getHistoricos8
        },
        
        data() {

            let datos = {                
                labels: this.labels,
                datasets: [
                    {
                        label: 'Automático',
                        // borderColor: 'rgb(255, 0, 0)',
                        backgroundColor: '#00ff00',
                        data: this.zona1,
                        fill: {
                            //target: { value:50 },
                            target: 'origin',
                            above: '#00ff00',   // Area will be red above the origin
                        },
                        pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
                    },
                    {
                        label: 'Parada por operario',
                        backgroundColor: '#a0a0a0',
                        data: this.zona2,
                        fill: {
                            target: 'origin',
                            above: '#a0a0a0',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: 'Parada alarma',
                        backgroundColor: '#ff0000',
                        data: this.zona3,
                        fill: {
                            target: 'origin',
                            above: '#ff0000',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: 'Mantenimiento',
                        backgroundColor: '#00d3ff',
                        data: this.zona4,
                        fill: {
                            target: 'origin',
                            above: '#00d3ff',
                        },
                        pointRadius: 0,
                    },
                    {
                        label: 'Parada por falta de condiciones',
                        backgroundColor: '#ff9300',
                        data: this.zona5,
                        fill: {
                            target: 'origin',
                            above: '#ff9300',
                        },
                        pointRadius: 0,
                    }
                ]
            }
            return datos
        },
    },
}
</script>

<style>
.text {
    font-family: Garamond, serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.01);
    /* z-index:-1!important; */
}
</style>
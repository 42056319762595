import KoioteSetReset from './KoioteSetReset.vue'
import KoioteSetResetProperties from './KoioteSetResetProperties.vue'
import KoioteSetReset1Property from './default.json'


/// componente lectura excel
export default {
	install(Vue, options) {
		Vue.component(KoioteSetReset.name, KoioteSetReset)
		Vue.component(KoioteSetResetProperties.name, KoioteSetResetProperties)
		Vue.prototype.$KoioteSetReset1Property = KoioteSetReset1Property
		// para interpretar las variabes que llegan de nodered

		Vue.mixin({
			mounted() {
				// console.log('Montadito!');
			}
		});
	}
};

